/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { BsDashLg } from 'react-icons/bs';
import { shallowEqual, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Text from 'components/Text';
import { COLORS } from 'resources/colors';
import { selecUserWalletsState } from 'state/selectors/wallets';
import { USER_DATE_FORMAT } from 'utils/constant';
import { generalMessages } from 'utils/generalMessages';

import useErrorToast from './useErrorToast';

const useTransactionsColumns = () => {
  const { error, wallets } = useSelector(selecUserWalletsState, shallowEqual);

  useErrorToast(error);

  const columns = useMemo(
    () => [
      {
        Header: generalMessages.date.defaultMessage,
        accessor: 'date',
        Cell: ({
          row: {
            original: { date },
          },
        }) => <>{dayjs(date).format(USER_DATE_FORMAT)}</>,
      },
      {
        Header: generalMessages.amount.defaultMessage,
        accessor: 'amount',
        Cell: ({
          row: {
            original: { amount, isExpense },
          },
        }) => (
          <>
            {isExpense && <BsDashLg color={COLORS.RedButton} />}&nbsp;
            <Text color={isExpense ? COLORS.RedButton : COLORS.Black}>
              {amount}
            </Text>
          </>
        ),
      },
      {
        Header: generalMessages.wallet.defaultMessage,
        accessor: 'walletId',
        Cell: ({
          row: {
            original: { walletId },
          },
        }) => {
          const name =
            wallets?.find(({ uid }) => uid === walletId)?.name || '-';

          return <>{name}</>;
        },
      },
      {
        Header: generalMessages.category.defaultMessage,
        accessor: 'Category',
        Cell: ({
          row: {
            original: { Category },
          },
        }) => <>{Category?.name}</>,
      },
      {
        Header: generalMessages.description.defaultMessage,
        accessor: 'description',
        Cell: ({
          row: {
            original: { description },
          },
        }) => {
          const name = description || '-';

          return <>{name}</>;
        },
      },
    ],
    [wallets]
  );

  return {
    columns,
  };
};

export default useTransactionsColumns;
