import { useEffect } from 'react';
import { toast } from 'react-toastify';

const useSuccessToast = (condition, message) => {
  useEffect(() => {
    if (condition) {
      toast.success(message);
    }
  }, [condition, message]);
};

export default useSuccessToast;
