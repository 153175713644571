import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'state/reducers/auth';
import usersReducer from 'state/reducers/users';
import walletsReducer from 'state/reducers/wallets';
import expensesReducer from 'state/reducers/expenses';
import scheduledReducer from 'state/reducers/scheduled';
import bankStatementsReducer from 'state/reducers/bankStatements';
import personalInformationReducer from 'state/reducers/personalInformation';
import notificationsReducer from 'state/reducers/notifications';

const rootReducer = combineReducers({
  users: usersReducer,
  auth: authReducer,
  wallets: walletsReducer,
  expenses: expensesReducer,
  scheduled: scheduledReducer,
  bankStatements: bankStatementsReducer,
  personalInformation: personalInformationReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
