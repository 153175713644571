/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_INIT,
  AUTH_LOGIN_SUCCESS,
  AUTH_SIGNOUT_FAIL,
  AUTH_SIGNOUT_INIT,
  AUTH_SIGNOUT_SUCCESS,
  SIGN_UP_FAIL,
  SIGN_UP_INIT,
  SIGN_UP_SUCCESS,
  SAVE_TOKEN,
  CLEAN_STATE,
  CLEAN_STATE_DELETE,
  AUTH_PROVIDER_INIT,
  AUTH_PROVIDER_SUCCESS,
  AUTH_PROVIDER_FAIL,
  AUTH_CLEAN_STATE,
  AUTH_RESET_PASSWORD_INIT,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
} from 'state/actionCreators/auth';

export const initialState = {
  loadingSignIn: false,
  errorSignIn: null,
  uid: null,

  loadingSignUp: false,
  errorSignUp: null,
  user: null,

  loadingSignOut: false,
  errorSignOut: null,

  token: null,
};

const persistConfig = {
  key: 'auth',
  storage,
  blacklist: [
    'loadingSignIn',
    'errorSignIn',
    'loadingSignUp',
    'errorSignUp',
    'loadingSignOut',
    'errorSignOut',
    'loadingProviders',
    'errorProviders',
  ],
};

const authReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [AUTH_LOGIN_INIT]: (state) => {
      state.loadingSignIn = true;
      state.errorSignIn = null;
    },
    [AUTH_LOGIN_SUCCESS]: (state, { payload }) => {
      state.loadingSignIn = false;
      state.uid = payload.uid;
      state.errorSignIn = null;
    },
    [AUTH_LOGIN_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingSignIn = false;
      state.errorSignIn = error;
    },
    [AUTH_SIGNOUT_INIT]: (state) => {
      state.loadingSignOut = true;
    },
    [AUTH_SIGNOUT_SUCCESS]: (state) => {
      state.loadingSignOut = false;
      state.errorSignOut = null;
      state.uid = null;
      state.token = null;
    },
    [AUTH_SIGNOUT_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.errorSignOut = error;
      state.loadingSignOut = false;
    },
    [SIGN_UP_INIT]: (state) => {
      state.loadingSignUp = true;
      state.errorSignUp = null;
    },
    [SIGN_UP_SUCCESS]: (state, { payload }) => {
      state.loadingSignUp = false;
      const { uid, data } = payload;
      state.uid = uid;
      state.user = data;
      state.errorSignUp = null;
    },
    [SIGN_UP_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingSignUp = false;
      state.errorSignUp = error;
    },
    [AUTH_PROVIDER_INIT]: (state) => {
      state.loadingProviders = true;
      state.errorSignUp = null;
      state.errorSignIn = null;
      state.errorProviders = null;
    },
    [AUTH_PROVIDER_SUCCESS]: (state, { payload }) => {
      state.loadingSignUp = false;
      const { uid, data } = payload;
      state.uid = uid;
      state.user = data;
      state.errorProviders = null;
    },
    [AUTH_PROVIDER_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingSignUp = false;
      state.errorProviders = error;
    },
    [SAVE_TOKEN]: (state, { payload }) => {
      const { token } = payload;
      state.token = token;
    },
    [CLEAN_STATE]: () => initialState,
    [AUTH_CLEAN_STATE]: (state) => {
      state.loadingSignUp = false;
      state.loadingSignIn = false;
      state.loadingProviders = false;
      state.errorSignUp = null;
      state.errorSignIn = null;
      state.errorProviders = null;
    },
    [CLEAN_STATE_DELETE]: (state) => {
      state.loadingSignIn = false;
      state.errorSignIn = null;
      state.uid = null;
      state.loadingSignUp = false;
      state.errorSignUp = null;
      state.user = null;
      state.loadingSignOut = false;
      state.errorSignOut = null;
      state.token = null;
    },
    [AUTH_RESET_PASSWORD_INIT]: (state) => {
      state.loadingResetPassword = true;
      state.errorResetPassword = null;
    },
    [AUTH_RESET_PASSWORD_SUCCESS]: (state) => {
      state.loadingResetPassword = false;
      state.errorResetPassword = null;
      state.passwordRecovered = true;
    },
    [AUTH_RESET_PASSWORD_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingResetPassword = false;
      state.errorerrorResetPassword = error;
    },
  })
);

export default authReducer;
