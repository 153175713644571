import ApiPath from 'enums/apiPath.enum';
import { axiosInstance } from 'services/api/axios';

const getAllCategoriesTypes = async (token) => {
  const { data } = await axiosInstance.get(ApiPath.Categories, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

export default getAllCategoriesTypes;
