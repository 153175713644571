import { MEDIA } from 'resources/layout';
import styled from 'styled-components';

const TermsAndConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  ${MEDIA.xs`
    padding: 0 20px;
  `}

  ${MEDIA.tb`
    padding: 0 60px;
  `}
`;

export default TermsAndConditionsContainer;
