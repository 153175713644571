import { createAction } from '@reduxjs/toolkit';

export const UPLOAD_BANK_STATEMENT_INIT = createAction(
  'UPLOAD_BANK_STATEMENT_INIT'
);
export const UPLOAD_BANK_STATEMENT_SUCCESS = createAction(
  'UPLOAD_BANK_STATEMENT_SUCCESS'
);
export const UPLOAD_BANK_STATEMENT_FAIL = createAction(
  'UPLOAD_BANK_STATEMENT_FAIL'
);

export const REMOVE_TRANSACTION = createAction('REMOVE_TRANSACTION');

export const CHANGE_CATEGORY = createAction('CHANGE_CATEGORY');
export const RESET_BANK_STATE = createAction('RESET_BANK_STATE');

export const UPLOAD_BANK_TRANSACTIONS_INIT = createAction(
  'UPLOAD_BANK_TRANSACTIONS_INIT'
);
export const UPLOAD_BANK_TRANSACTIONS_SUCCESS = createAction(
  'UPLOAD_BANK_TRANSACTIONS_SUCCESS'
);
export const UPLOAD_BANK_TRANSACTIONS_FAIL = createAction(
  'UPLOAD_BANK_TRANSACTIONS_FAIL'
);
