import Button from 'components/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.disabled &&
    `
    background: #bbbdbf;
    border: 1px solid #d9d9d9;
 

    :hover{
      background: #9d9fa1;
    }

  `}
`;

export default StyledButton;
