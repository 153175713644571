import styled from 'styled-components';

const FooterWrapper = styled.div`
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export default FooterWrapper;
