import React from 'react';

import withNonAuthenticatedLayout from 'components/hocs/withNonAuthenticatedLayout';
import Title, { SizeTitle } from 'components/Title';
import Section from 'components/Section';

import Container from './styles/Container';
import TermsAndConditionsContainer from './styles/TermsAndConditionsContainer';

import { messages } from './messages';
import { termsAndConditions } from './sections';

const TermsAndConditions = () => (
  <Container>
    <Title size={SizeTitle.M}>{messages.termsAndContions.defaultMessage}</Title>
    <TermsAndConditionsContainer>
      {termsAndConditions.map((term) => (
        <Section
          title={term.title}
          info={term.info}
          list={term && term.list}
          key={term.title}
        />
      ))}
    </TermsAndConditionsContainer>
  </Container>
);

export default withNonAuthenticatedLayout(TermsAndConditions);
