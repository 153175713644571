/* eslint-disable react/forbid-prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';

import File from 'images/fileIcon.svg';
import { errorToast } from 'utils/toast';

import FileContainer from './styles/FileContainer';
import StyledInput from './styles/StyledInput';
import FileDescriptionContainer from './styles/FileDescriptionContainer';
import FileDescription from './styles/FileDescription';
import UploadContainer from './styles/UploadContainer';
import FileNameList from './styles/FileNameList';

const FileInput = ({
  name,
  placeholder,
  error,
  className,
  disabled,
  file,
  setFile,
  ...rest
}) => {
  const fileNames = useMemo(
    () =>
      file &&
      file.name && (
        <FileNameList>
          <li key={file.name}>{file.name}</li>
        </FileNameList>
      ),
    [file?.name]
  );

  const saveFile = (e) => {
    const fileSelected = e.target.files[0];

    if (
      fileSelected.name.match(/.xls$/i) ||
      fileSelected.type === 'application/vnd.ms-excel'
    ) {
      setFile(fileSelected);
    } else {
      errorToast('El tipo de archivo debe ser .xls');
    }
  };

  return (
    <FileContainer>
      <StyledInput
        name={name}
        placeholder={placeholder}
        onChange={(e) => saveFile(e)}
        aria-label={name}
        disabled={disabled}
        type="file"
        accept="xls"
        {...rest}
      />
      <img src={File} alt="Subir archivo" />
      <FileDescriptionContainer>
        <FileDescription>
          {fileNames || (
            <UploadContainer>
              <span>Arrastra archivos aquí el archivo (.xls) o&nbsp;</span>
              <u>busca uno</u>
            </UploadContainer>
          )}
        </FileDescription>
      </FileDescriptionContainer>
    </FileContainer>
  );
};

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  file: PropTypes.object,
  setFile: PropTypes.func,
};

FileInput.defaultProps = {
  error: false,
  placeholder: '',
  className: null,
  disabled: false,
  file: {},
  setFile: () => {},
};

export default FileInput;
