export const scope = 'app.pages.termsandconditions';

export const messages = {
  termsAndContions: {
    id: `${scope}.termsAndContions`,
    defaultMessage: 'Términos y condiciones',
  },
  generalTerms: {
    id: `${scope}.generalTerms`,
    defaultMessage: 'Términos generales',
  },
  license: {
    id: `${scope}.license`,
    defaultMessage: 'Licencia',
  },
  definitions: {
    id: `${scope}.definitions`,
    defaultMessage: 'Definiciones y términos claves',
  },
  restrictions: {
    id: `${scope}.restrictions`,
    defaultMessage: 'Restricciones',
  },
  suggestions: {
    id: `${scope}.suggestions`,
    defaultMessage: 'Tus sugerencias',
  },
  consent: {
    id: `${scope}.consent`,
    defaultMessage: 'Tu consentimiento',
  },
  externalLinks: {
    id: `${scope}.externalLinks`,
    defaultMessage: 'Enlaces externos',
  },
  cookies: {
    id: `${scope}.cookies`,
    defaultMessage: 'Cookies',
  },
  changesInTerms: {
    id: `${scope}.changesInTerms`,
    defaultMessage: 'Cambios en nuestros Términos y Condiciones',
  },
  modificationsInApp: {
    id: `${scope}.modificationsInApp`,
    defaultMessage: 'Modificaciones a nuestra plataforma',
  },
  updatesInApp: {
    id: `${scope}.updatesInApp`,
    defaultMessage: 'Actualizaciones a nuestra plataforma',
  },
  externalServices: {
    id: `${scope}.externalServices`,
    defaultMessage: 'Servicios de terceros',
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'Duración y Terminación',
  },
  advices: {
    id: `${scope}.advices`,
    defaultMessage: 'Aviso de infracción de Derechos de Autor',
  },
  compensation: {
    id: `${scope}.compensation`,
    defaultMessage: 'Indemnización',
  },
  warranty: {
    id: `${scope}.warranty`,
    defaultMessage: 'Sin garantías',
  },
  responsabilityLimited: {
    id: `${scope}.responsabilityLimited`,
    defaultMessage: 'Limitación de Responsabilidad',
  },
  division: {
    id: `${scope}.division`,
    defaultMessage: 'Divisibilidad',
  },
  resignation: {
    id: `${scope}.resignation`,
    defaultMessage: 'Renuncia',
  },
  amendments: {
    id: `${scope}.amendments`,
    defaultMessage: 'Enmiendas a este Acuerdo',
  },
  entireAgreement: {
    id: `${scope}.entireAgreement`,
    defaultMessage: 'Acuerdo completo',
  },
  updateTerms: {
    id: `${scope}.updateTerms`,
    defaultMessage: 'Actualizaciones de nuestros Términos',
  },
  intellectualProperty: {
    id: `${scope}.intellectualProperty`,
    defaultMessage: 'Propiedad Intelectual',
  },
  arbitrationAgreement: {
    id: `${scope}.arbitrationAgreement`,
    defaultMessage: 'Acuerdo de Arbitraje',
  },
  disputeNotice: {
    id: `${scope}.disputeNotice`,
    defaultMessage: 'Aviso de disputa',
  },
  mandatoryArbitration: {
    id: `${scope}.mandatoryArbitration`,
    defaultMessage: 'Arbitraje obligatorio',
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: 'Privacidad',
  },
  promotions: {
    id: `${scope}.promotions`,
    defaultMessage: 'Promociones',
  },
  diverse: {
    id: `${scope}.diverse`,
    defaultMessage: 'Diverso',
  },
  disclaimer: {
    id: `${scope}.disclaimer`,
    defaultMessage: 'Descargo de responsabilidad',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contáctenos',
  },
};
