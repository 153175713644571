/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  WALLETS_CLEAN_UP,
  WALLETS_CREATE_WALLET_FAIL,
  WALLETS_CREATE_WALLET_INIT,
  WALLETS_CREATE_WALLET_SUCCESS,
  WALLETS_DELETE_WALLETS_FAIL,
  WALLETS_DELETE_WALLETS_INIT,
  WALLETS_DELETE_WALLETS_SUCCESS,
  WALLETS_GET_USER_WALLETS_FAIL,
  WALLETS_GET_USER_WALLETS_INIT,
  WALLETS_GET_USER_WALLETS_SUCCESS,
  WALLETS_GET_WALLET_TYPES_FAIL,
  WALLETS_GET_WALLET_TYPES_INIT,
  WALLETS_GET_WALLET_TYPES_SUCCESS,
} from 'state/actionCreators/wallets';

export const initialState = {
  errorFetchingTypes: null,
  loadingTypes: false,
  createWalletSuccess: false,
  errorWalletCreate: null,
  creatingWallet: false,
  deletingWallet: false,
  isWalletDeleted: false,
  errorDeletingWallet: null,
  types: [],
  wallets: [],
};

const persistConfig = {
  key: 'wallets',
  storage,
  blacklist: [
    'errorFetchingTypes',
    'loadingTypes',
    'createWalletSuccess',
    'creatingWallet',
    'errorWalletCreate',
    'fetchingUserWallet',
    'errorFetchingUserWallet',
    'deletingWallet',
    'errorDeletingWallet',
    'isWalletDeleted',
  ],
};

const walletsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [WALLETS_GET_WALLET_TYPES_INIT]: (state) => {
      state.loadingTypes = true;
      state.errorFetchingTypes = null;
    },
    [WALLETS_GET_WALLET_TYPES_SUCCESS]: (state, { payload }) => {
      const { types } = payload;
      state.loadingTypes = false;
      state.errorFetchingTypes = null;
      state.types = types;
    },
    [WALLETS_GET_WALLET_TYPES_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingTypes = false;
      state.errorFetchingTypes = error;
    },
    [WALLETS_CREATE_WALLET_INIT]: (state) => {
      state.creatingWallet = true;
      state.errorFetchingTypes = null;
    },
    [WALLETS_CREATE_WALLET_SUCCESS]: (state) => {
      state.creatingWallet = false;
      state.errorWalletCreate = null;
      state.createWalletSuccess = true;
    },
    [WALLETS_CREATE_WALLET_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.creatingWallet = false;
      state.errorWalletCreate = error;
    },
    [WALLETS_GET_USER_WALLETS_INIT]: (state) => {
      state.fetchingUserWallet = true;
      state.errorFetchingUserWallet = null;
    },
    [WALLETS_GET_USER_WALLETS_SUCCESS]: (state, { payload }) => {
      const { wallets } = payload;
      state.fetchingUserWallet = false;
      state.errorFetchingUserWallet = null;
      state.wallets = wallets;
    },
    [WALLETS_GET_USER_WALLETS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.fetchingUserWallet = false;
      state.errorFetchingUserWallet = error;
    },
    [WALLETS_DELETE_WALLETS_INIT]: (state) => {
      state.deletingWallet = true;
      state.errorDeletingWallet = null;
      state.isWalletDeleted = false;
    },
    [WALLETS_DELETE_WALLETS_SUCCESS]: (state, { payload }) => {
      state.deletingWallet = false;
      state.errorDeletingWallet = null;
      state.isWalletDeleted = false;
      state.wallets = state.wallets.filter(
        ({ uid }) => uid !== payload.walletId
      );
    },
    [WALLETS_DELETE_WALLETS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.deletingWallet = false;
      state.errorDeletingWallet = error;
    },
    [WALLETS_CLEAN_UP]: (state) => {
      state.errorFetchingTypes = null;
      state.loadingTypes = false;
      state.createWalletSuccess = false;
      state.errorWalletCreate = null;
      state.errorDeletingWallet = null;
      state.creatingWallet = false;
      state.deletingWallet = false;
      state.isWalletDeleted = false;
    },
  })
);

export default walletsReducer;
