import React from 'react';

import PropTypes from 'prop-types';

import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import { Size } from 'components/Text';

import Container from './styles/Container';
import ListItem from './styles/ListItem';

const Section = ({ title, info, list }) => (
  <Container>
    <Title color={ColorsTitle.Dark} size={SizeTitle.S} noCenter>
      {title}
    </Title>
    <p size={Size.M}>{info}</p>
    <ul>
      {list &&
        list.map((element) => (
          <ListItem size={Size.M} key={element}>
            {element}
          </ListItem>
        ))}
    </ul>
  </Container>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string),
};

Section.defaultProps = {
  list: [],
};

export default Section;
