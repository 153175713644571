import ApiPath from 'enums/apiPath.enum';
import { axiosInstance } from 'services/api/axios';

const generateReports = async (filters, token) => {
  const { data } = await axiosInstance.get(
    `${ApiPath.Reports}/?${filters ?? ''}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export default generateReports;
