import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = (state) => state.auth;

export const selectStatusSignIn = createSelector(
  selectAuthState,
  ({ loadingSignIn, errorSignIn, loadingProviders, errorProviders }) => ({
    loading: loadingSignIn || loadingProviders,
    error: errorSignIn ?? errorProviders,
  })
);

export const selectStatusSignUp = createSelector(
  selectAuthState,
  ({ loadingSignUp, errorSignUp, loadingProviders, errorProviders }) => ({
    loading: loadingSignUp || loadingProviders,
    error: errorSignUp ?? errorProviders,
  })
);

export const selectAuthData = createSelector(selectAuthState, ({ uid }) => ({
  isLogged: !!uid,
  userId: uid,
}));

export const selectTokenUser = createSelector(
  selectAuthState,
  ({ token }) => token
);

export const selectResetPassword = createSelector(
  selectAuthState,
  ({ passwordRecovered, loadingResetPassword, errorResetPassword }) => ({
    success: passwordRecovered,
    loading: loadingResetPassword,
    errorResetPassword,
  })
);
