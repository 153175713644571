import { FaMoneyBillWaveAlt, FaRegStickyNote } from 'react-icons/fa';

import { SIZES } from 'utils/constant';
import lapseOptions from 'utils/common/lapseOptions';
import InputWithLabel from 'components/InputWithLabel';
import { generalMessages } from 'utils/generalMessages';
import SelectFormControl from 'components/SelectFormControl';

const getAddExpenseInputs = ({
  errors,
  register,
  categories,
  wallets,
  control,
}) => [
  {
    options: lapseOptions,
    name: generalMessages.lapse.fieldName,
    control,
    label: generalMessages.lapse.defaultMessage,
    placeholder: generalMessages.lapse.defaultMessage,
    component: SelectFormControl,
    errors,
  },
  {
    options: categories,
    name: 'categoryId',
    control,
    label: generalMessages.category.defaultMessage,
    placeholder: generalMessages.category.defaultMessage,
    component: SelectFormControl,
    errors,
  },
  {
    options: wallets,
    name: 'walletId',
    control,
    label: generalMessages.wallet.defaultMessage,
    placeholder: generalMessages.wallet.defaultMessage,
    component: SelectFormControl,
    errors,
  },
  {
    type: 'date',
    placeholder: generalMessages.date.defaultMessage,
    label: generalMessages.date.defaultMessage,
    size: SIZES.MEDIUM,
    fieldName: generalMessages.date.fieldName,
    component: InputWithLabel,
    errors,
    register,
  },
  {
    type: 'number',
    placeholder: generalMessages.amount.defaultMessage,
    label: generalMessages.amount.defaultMessage,
    size: SIZES.MEDIUM,
    icon: <FaMoneyBillWaveAlt />,
    fieldName: generalMessages.amount.fieldName,
    min: 0,
    component: InputWithLabel,
    errors,
    register,
  },
  {
    type: 'text',
    placeholder: generalMessages.description.defaultMessage,
    label: generalMessages.description.defaultMessage,
    size: SIZES.MEDIUM,
    icon: <FaRegStickyNote />,
    fieldName: generalMessages.description.fieldName,
    component: InputWithLabel,
    errors,
    register,
  },
];

export default getAddExpenseInputs;
