import styled from 'styled-components';

const Amount = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.expense ? '#FF5E57' : '#00C58E')};
  width: 180px;
`;

export default Amount;
