/* eslint-disable react/prop-types */

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import dayjs from 'dayjs';

import FlexContainer from 'components/FlexContainer';
import Title, { SizeTitle } from 'components/Title';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectStatusUpload } from 'state/selectors/bankStatements';
import Text from 'components/Text';
import Table from 'components/Table';
import SelectInput from 'components/SelectInput';
import Loading from 'components/Loading';
import Button from 'components/Button';

import { getAllCategories } from 'state/actions/expenses';
import {
  selectAddExpenseState,
  selectCategoriesData,
} from 'state/selectors/expenses';
import {
  changeCategory,
  removeTransaction,
  resetBankState,
  uploadBankTransactions,
} from 'state/actions/bankStatements';
import { getUserWallets } from 'state/actions/wallets';
import { selecUserWalletsState } from 'state/selectors/wallets';

import useErrorToast from 'hooks/useErrorToast';
import { COLORS } from 'resources/colors';
import { generalMessages } from 'utils/generalMessages';

import { errorToast } from 'utils/toast';
import { useHistory } from 'react-router-dom';
import Path from 'enums/path.enum';
import useSuccessToast from 'hooks/useSuccessToast';
import { USER_DATE_FORMAT } from 'utils/constant';
import Description from './styles/Description';
import Amount from './styles/Amount';
import Date from './styles/Date';
import DeleteContainer from './styles/DeleteContainer';
import DropdownContainer from './styles/DropdownContainer';

const ConfirmTransactions = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [wallet, setWallet] = useState();

  useEffect(() => {
    dispatch(getUserWallets());
    dispatch(getAllCategories());

    return () => {
      dispatch(resetBankState());
    };
  }, []);

  const { transactions, success } = useSelector(
    selectStatusUpload,
    shallowEqual
  );
  const { loading, error, categories } = useSelector(
    selectCategoriesData,
    shallowEqual
  );

  useSuccessToast(success, 'Transacciones agregadas exitosamente');

  useEffect(() => {
    if (success) {
      push(Path.Home);
    }
  }, [success]);

  const {
    loading: loadingWallets,
    optionWallets,
    error: walletsError,
  } = useSelector(selecUserWalletsState, shallowEqual);

  const { categories: categoriesOptions } = useSelector(
    selectAddExpenseState,
    shallowEqual
  );

  useErrorToast(error);
  useErrorToast(walletsError);

  const onDeleteTransaction = (uid) => dispatch(removeTransaction(uid));

  const onChangeCategory = (transactionId, categoryId) => {
    const newCategory = categories.find((cat) => cat.uid === categoryId);
    dispatch(changeCategory(transactionId, newCategory));
  };

  const onConfirmTransactionsHandler = useCallback(() => {
    if (wallet) {
      dispatch(uploadBankTransactions(wallet));
    } else {
      errorToast('Debe seleccionar una billetera');
    }
  }, [wallet]);

  const columns = useMemo(
    () => [
      {
        Header: generalMessages.date.defaultMessage,
        accessor: 'date',
        Cell: ({
          row: {
            original: { date },
          },
        }) => <Date>{dayjs(date).format(USER_DATE_FORMAT)}</Date>,
      },
      {
        Header: generalMessages.description.defaultMessage,
        accessor: 'transformedConcept',
        Cell: ({
          row: {
            original: { transformedConcept },
          },
        }) => <Description>{transformedConcept}</Description>,
      },
      {
        Header: generalMessages.amount.defaultMessage,
        accessor: 'amount',
        Cell: ({
          row: {
            original: { amount, isExpense },
          },
        }) => <Amount expense={isExpense}>$ {amount}</Amount>,
      },
      {
        Header: generalMessages.category.defaultMessage,
        accessor: 'category',
        Cell: ({
          row: {
            original: { uid, category },
          },
        }) => (
          <DropdownContainer>
            <SelectInput
              options={categoriesOptions}
              isClearable
              isSearchable
              defaultValue={{ label: category.name, value: category.uid }}
              onChange={(cat) => onChangeCategory(uid, cat.value)}
            />
          </DropdownContainer>
        ),
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: ({
          row: {
            original: { uid },
          },
        }) => (
          <DeleteContainer>
            <BsTrashFill
              onClick={() => onDeleteTransaction(uid)}
              color={COLORS.RedButton}
              style={{ cursor: 'pointer' }}
            />
          </DeleteContainer>
        ),
      },
    ],
    [transactions]
  );

  const isLoading = loading || loadingWallets;

  return (
    <>
      {isLoading && <Loading />}
      <FlexContainer middle="xs" between="xs" padding="0 2rem">
        <Title size={SizeTitle.M}>Transacciones bancarias</Title>
      </FlexContainer>
      <FlexContainer between="xs" padding="1rem 2rem">
        <SelectInput
          options={optionWallets}
          onChange={(option) => {
            setWallet(option ? option.value : null);
          }}
          placeholder="Seleccionar billetera"
          isClearable
          isSearchable
        />
        <Button onClick={onConfirmTransactionsHandler}>
          Confirmar transacciones
        </Button>
      </FlexContainer>
      <FlexContainer padding="0 2rem">
        <Text>Transacciones obtenidas:</Text>
      </FlexContainer>
      <FlexContainer padding="2rem" column gap={32} middle="xs">
        {transactions.length > 0 ? (
          <Table columns={columns} data={transactions} />
        ) : (
          <>
            <Text>No hay transacciones para confirmar categorías</Text>
          </>
        )}
      </FlexContainer>
    </>
  );
};

export default ConfirmTransactions;
