const Gender = Object.freeze({
  Male: 'Male',
  Female: 'Female',
});

export const GenderOptions = [
  { value: Gender.Male, label: 'Masculino' },
  { value: Gender.Female, label: 'Femenino' },
];

const MaritalStatus = Object.freeze({
  Single: 'Single',
  Married: 'Married',
  Divorced: 'Divorced/Separated',
  Widowed: 'Widowed',
});

export const MaritalStatusOptions = [
  { value: MaritalStatus.Single, label: 'Soltero' },
  { value: MaritalStatus.Married, label: 'Casado' },
  { value: MaritalStatus.Divorced, label: 'Divorciado' },
  { value: MaritalStatus.Widowed, label: 'Viudo' },
];

const TenureStatus = Object.freeze({
  Owner: 'OWN',
  Rent: 'RENT',
  RentFree: 'RENT_FREE',
});

export const TenureStatusOptions = [
  { value: TenureStatus.Owner, label: 'Propietario' },
  { value: TenureStatus.Rent, label: 'Alquilado' },
  { value: TenureStatus.RentFree, label: 'Ocupación gratuita' },
];

const BudgetTarget = Object.freeze({
  '0-10': '0-10',
  '10-20': '10-20',
  '>20': '>20',
});

export const BudgetTargetOptions = [
  { value: BudgetTarget['0-10'], label: '0-10%' },
  { value: BudgetTarget['10-20'], label: '10-20%' },
  { value: BudgetTarget['>20'], label: '>20%' },
];
