/* eslint-disable react/prop-types */
import { BsTrashFill } from 'react-icons/bs';

import FlexContainer from 'components/FlexContainer';
import { MdEdit } from 'react-icons/md';
import { COLORS } from 'resources/colors';

const useLimitsColumns = ({ onEdit, onDelete }) => {
  const columns = [
    {
      Header: 'Categoría',
      accessor: 'category.name',
    },
    {
      Header: 'Porcentaje',
      accessor: 'percentage',
      Cell: ({ value }) => `${value}%`,
    },
    {
      Header: 'Estado',
      accessor: 'amount',
      Cell: ({
        row: {
          original: { amount, spent },
        },
      }) => (
        <FlexContainer gap={8}>
          <progress value={spent} max={amount} />
          <strong>
            {spent}/{amount}
          </strong>
        </FlexContainer>
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: ({
        row: {
          index,
          original: {
            category: { uid },
          },
        },
      }) => (
        <FlexContainer gap={16}>
          <MdEdit
            onClick={() => onEdit(index)}
            color={COLORS.AhorranzaBlue}
            style={{ cursor: 'pointer' }}
          />
          <BsTrashFill
            onClick={() => onDelete(uid)}
            color={COLORS.RedButton}
            style={{ cursor: 'pointer' }}
          />
        </FlexContainer>
      ),
    },
  ];

  return {
    columns,
  };
};

export default useLimitsColumns;
