import { createAction } from '@reduxjs/toolkit';

export const NOTIFICATIONS_GET_NOTIFICATIONS_INIT = createAction(
  'NOTIFICATIONS_GET_NOTIFICATIONS_INIT'
);
export const NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS = createAction(
  'NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS'
);
export const NOTIFICATIONS_GET_NOTIFICATIONS_FAIL = createAction(
  'NOTIFICATIONS_GET_NOTIFICATIONS_FAIL'
);

export const NOTIFICATIONS_UPDATE_NOTIFICATIONS_INIT = createAction(
  'NOTIFICATIONS_UPDATE_NOTIFICATIONS_INIT'
);
export const NOTIFICATIONS_UPDATE_NOTIFICATIONS_SUCCESS = createAction(
  'NOTIFICATIONS_UPDATE_NOTIFICATIONS_SUCCESS'
);
export const NOTIFICATIONS_UPDATE_NOTIFICATIONS_FAIL = createAction(
  'NOTIFICATIONS_UPDATE_NOTIFICATIONS_FAIL'
);

export const NOTIFICATIONS_CLEAN_UP = createAction('NOTIFICATIONS_CLEAN_UP');
