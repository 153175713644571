/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  UPLOAD_BANK_STATEMENT_INIT,
  UPLOAD_BANK_STATEMENT_SUCCESS,
  UPLOAD_BANK_STATEMENT_FAIL,
  REMOVE_TRANSACTION,
  CHANGE_CATEGORY,
  UPLOAD_BANK_TRANSACTIONS_INIT,
  UPLOAD_BANK_TRANSACTIONS_SUCCESS,
  UPLOAD_BANK_TRANSACTIONS_FAIL,
  RESET_BANK_STATE,
} from 'state/actionCreators/bankStatements';

export const initialState = {
  loadingUpload: false,
  errorUpload: null,
  uploadSuccess: false,
  transactions: [],
};

const persistConfig = {
  key: 'bankStatements',
  storage,
  blacklist: ['loadingUpload', 'errorUpload', 'uploadSuccess'],
};

const bankStatementsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [UPLOAD_BANK_STATEMENT_INIT]: (state) => {
      state.loadingUpload = true;
      state.errorUpload = null;
      state.uploadSuccess = false;
    },
    [UPLOAD_BANK_STATEMENT_SUCCESS]: (state, { payload }) => {
      state.loadingUpload = false;
      state.transactions = payload.transactions;
      state.errorUpload = null;
    },
    [UPLOAD_BANK_STATEMENT_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingUpload = false;
      state.errorUpload = error;
    },
    [REMOVE_TRANSACTION]: (state, { payload }) => {
      const { uid } = payload;
      state.transactions = state.transactions.filter(
        (transaction) => transaction.uid !== uid
      );
    },
    [CHANGE_CATEGORY]: (state, { payload }) => {
      const { transactionId, newCategory } = payload;
      const transaction = state.transactions.find(
        (t) => t.uid === transactionId
      );
      transaction.category = newCategory;
    },
    [UPLOAD_BANK_TRANSACTIONS_INIT]: (state) => {
      state.loadingUpload = true;
      state.errorUpload = null;
    },
    [UPLOAD_BANK_TRANSACTIONS_SUCCESS]: (state) => {
      state.loadingUpload = false;
      state.uploadSuccess = true;
      state.transactions = [];
      state.errorUpload = null;
    },
    [UPLOAD_BANK_TRANSACTIONS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingUpload = false;
      state.errorUpload = error;
    },
    [RESET_BANK_STATE]: () => initialState,
  })
);

export default bankStatementsReducer;
