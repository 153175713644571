/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectBankStatementsState = (state) => state.bankStatements;

export const selectStatusUpload = createSelector(
  selectBankStatementsState,
  ({ loadingUpload, errorUpload, transactions, uploadSuccess }) => ({
    loading: loadingUpload,
    error: errorUpload,
    success: uploadSuccess,
    transactions,
  })
);
