import { COLORS } from 'resources/colors';
import styled, { css } from 'styled-components';

export const ColorsButton = Object.freeze({
  Default: 'default',
  Delete: 'delete',
});

const ColorStyles = {
  [ColorsButton.Default]: css`
    background: ${COLORS.SkyBlue};
    color: ${COLORS.White};
    :hover {
      background: ${COLORS.PrimaryBlue};
    }
  `,
  [ColorsButton.Delete]: css`
    background: ${COLORS.RedButton};
    color: ${COLORS.White};
    :hover {
      background: ${COLORS.DarkRedButton};
    }
  `,
};

const Button = styled.button`
  ${(props) => props.fullWidth && `width: 100%;`}

  display: flex;

  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;

  ${(props) =>
    props.color ? ColorStyles[props.color] : ColorStyles[ColorsButton.Default]};

  ${(props) =>
    props.disabled &&
    `
    background: ${COLORS.GreyButton};
    color: ${COLORS.GreyText};
    cursor: not-allowed;
    :hover{
      background: ${COLORS.GreyButton};
    }
  `}
`;

export default Button;
