export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const MIN_BALANCE = 0;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const USER_DATE_FORMAT = 'DD/MM/YYYY';

export const chartResponsive = [
  {
    breakpoint: 480,
    options: {
      chart: {
        width: 200,
      },
      legend: {
        position: 'bottom',
      },
    },
  },
  {
    breakpoint: 768,
    options: {
      chart: {
        width: 300,
      },
      legend: {
        position: 'bottom',
      },
    },
  },
  {
    breakpoint: 1024,
    options: {
      chart: {
        width: 400,
      },
      legend: {
        position: 'right',
      },
    },
  },
  {
    breakpoint: 1440,
    options: {
      chart: {
        width: 500,
      },
      legend: {
        position: 'right',
      },
    },
  },
];

export const chartProps = {
  type: 'donut',
  height: 600,
  width: 600,
};
