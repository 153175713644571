import { FaBirthdayCake, FaChild } from 'react-icons/fa';
import { MdChildFriendly, MdOutlineAttachMoney } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';

import InputWithLabel from 'components/InputWithLabel';
import SelectFormControl from 'components/SelectFormControl';
import {
  BudgetTargetOptions,
  GenderOptions,
  MaritalStatusOptions,
  TenureStatusOptions,
} from 'utils/common/personalData';
import { SIZES } from 'utils/constant';

const personalDataFields = ({ errors, control, register }) => [
  {
    type: 'number',
    register,
    component: InputWithLabel,
    size: SIZES.MEDIUM,
    icon: <MdOutlineAttachMoney />,
    placeholder: 'Ingresos',
    min: 0,
    label: 'Ingresos',
    fieldName: 'monthly_income',
    errors,
  },
  {
    type: 'number',
    register,
    component: InputWithLabel,
    size: SIZES.MEDIUM,
    icon: <FaBirthdayCake />,
    min: 0,
    placeholder: 'Edad',
    label: 'Edad',
    fieldName: 'age',
    errors,
  },
  {
    placeholder: 'Ahorro objetivo',
    label: 'Ahorro objetivo',
    name: 'target',
    options: BudgetTargetOptions,
    component: SelectFormControl,
    control,
    errors,
  },
  {
    placeholder: 'Sexo',
    label: 'Sexo',
    name: 'sex',
    options: GenderOptions,
    component: SelectFormControl,
    control,
    errors,
  },
  {
    placeholder: 'Estado Civil',
    label: 'Estado Civil',
    name: 'marital_status',
    options: MaritalStatusOptions,
    component: SelectFormControl,
    control,
    errors,
  },

  {
    placeholder: 'Vivienda',
    label: 'Vivienda',
    name: 'tenure_status',
    options: TenureStatusOptions,
    component: SelectFormControl,
    control,
    errors,
  },
  {
    type: 'number',
    register,
    min: 0,
    component: InputWithLabel,
    size: SIZES.MEDIUM,
    icon: <MdChildFriendly />,
    placeholder: 'Familiares menores de 5 años',
    label: 'Familiares menores de 5 años',
    fieldName: 'family_members_less_5_years_old',
    errors,
  },
  {
    type: 'number',
    register,
    min: 0,
    component: InputWithLabel,
    size: SIZES.MEDIUM,
    icon: <FaChild />,
    placeholder: 'Familiares entre 5 y 17 años',
    label: 'Familiares entre 5 y 17 años',
    fieldName: 'family_members_between_5_17_years_old',
    errors,
  },
  {
    type: 'number',
    register,
    min: 0,
    component: InputWithLabel,
    size: SIZES.MEDIUM,
    icon: <GiReceiveMoney />,
    placeholder: 'Familiares con ingresos',
    label: 'Familiares con ingresos',
    fieldName: 'family_members_employed',
    errors,
  },
];

export default personalDataFields;
