/* eslint-disable import/prefer-default-export */
import uploadBankStatement from 'services/api/bankStatement/uploadBankStatement';
import uploadTransactions from 'services/api/bankStatement/uploadTransactions';

import {
  REMOVE_TRANSACTION,
  UPLOAD_BANK_STATEMENT_FAIL,
  UPLOAD_BANK_STATEMENT_INIT,
  UPLOAD_BANK_STATEMENT_SUCCESS,
  CHANGE_CATEGORY,
  UPLOAD_BANK_TRANSACTIONS_INIT,
  UPLOAD_BANK_TRANSACTIONS_SUCCESS,
  UPLOAD_BANK_TRANSACTIONS_FAIL,
  RESET_BANK_STATE,
} from 'state/actionCreators/bankStatements';

export const uploadBankState = (bankStatement) => async (
  dispatch,
  getState
) => {
  dispatch(UPLOAD_BANK_STATEMENT_INIT());

  const { token } = getState().auth;

  try {
    const { data } = await uploadBankStatement(bankStatement, token);
    return dispatch(UPLOAD_BANK_STATEMENT_SUCCESS({ transactions: data }));
  } catch (error) {
    return dispatch(
      UPLOAD_BANK_STATEMENT_FAIL({ error: error.response.data.message })
    );
  }
};

export const removeTransaction = (uid) => REMOVE_TRANSACTION({ uid });

export const changeCategory = (transactionId, category) =>
  CHANGE_CATEGORY({ transactionId, newCategory: category });

export const resetBankState = () => RESET_BANK_STATE();

export const uploadBankTransactions = (walletId) => async (
  dispatch,
  getState
) => {
  dispatch(UPLOAD_BANK_TRANSACTIONS_INIT());

  const { token } = getState().auth;
  const allTransactions = getState().bankStatements.transactions;

  const transactions = allTransactions.map(
    ({ date, amount, transformedConcept, category }) => ({
      date,
      amount,
      description: transformedConcept,
      categoryId: category.uid,
    })
  );

  try {
    await uploadTransactions({ transactions, walletId }, token);
    return dispatch(UPLOAD_BANK_TRANSACTIONS_SUCCESS());
  } catch (error) {
    return dispatch(
      UPLOAD_BANK_TRANSACTIONS_FAIL({ error: error.response.data.message })
    );
  }
};
