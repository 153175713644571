/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  EXPENSES_ADD_EXPENSE_FAIL,
  EXPENSES_ADD_EXPENSE_INIT,
  EXPENSES_ADD_EXPENSE_SUCCESS,
  EXPENSES_CLEAN_UP,
  EXPENSES_GET_ALL_CATEGORIES_FAIL,
  EXPENSES_GET_ALL_CATEGORIES_INIT,
  EXPENSES_GET_ALL_CATEGORIES_SUCCESS,
  EXPENSES_GET_ALL_TRANSACTIONS_FAIL,
  EXPENSES_GET_ALL_TRANSACTIONS_INIT,
  EXPENSES_GET_ALL_TRANSACTIONS_SUCCESS,
  EXPENSES_GET_USER_REPORTS_FAIL,
  EXPENSES_GET_USER_REPORTS_INIT,
  EXPENSES_GET_USER_REPORTS_SUCCESS,
  EXPENSES_DELETE_MONTHLY_LIMITS_FAIL,
  EXPENSES_DELETE_MONTHLY_LIMITS_INIT,
  EXPENSES_DELETE_MONTHLY_LIMITS_SUCCESS,
  EXPENSES_GET_MONTHLY_LIMITS_FAIL,
  EXPENSES_GET_MONTHLY_LIMITS_INIT,
  EXPENSES_GET_MONTHLY_LIMITS_SUCCESS,
  EXPENSES_UPDATE_MONTHLY_LIMITS_FAIL,
  EXPENSES_UPDATE_MONTHLY_LIMITS_INIT,
  EXPENSES_UPDATE_MONTHLY_LIMITS_SUCCESS,
  EXPENSES_CREATE_MONTHLY_LIMITS_FAIL,
  EXPENSES_CREATE_MONTHLY_LIMITS_INIT,
  EXPENSES_CREATE_MONTHLY_LIMITS_SUCCESS,
} from 'state/actionCreators/expenses';

export const initialState = {
  categories: [],
  reports: [],
  transactions: [],
  fetchingAllCategories: false,
  errorFetchingCategories: null,
  addingExpense: false,
  expenseAddedSuccessfully: false,
  errorAddingUserExpense: null,
  fetchingAllTransactions: false,
  errorFetchingTransactions: null,
  fetchingReports: false,
  errorFetchingReports: null,
  fetchingMonthlyLimits: false,
  errorFetchingMonthlyLimits: null,
  updatingMonthlyLimits: false,
  errorUpdatingMonthlyLimits: null,
  deletingMonthlyLimits: false,
  errorDeletingMonthlyLimits: null,
  isMonthlyLimitsUpdated: null,
  monthlyLimits: [],
};

const persistConfig = {
  key: 'expenses',
  storage,
  blacklist: [
    'fetchingReports',
    'errorFetchingReports',
    'fetchingAllCategories',
    'errorFetchingCategories',
    'addingExpense',
    'errorAddingUserExpense',
    'expenseAddedSuccessfully',
    'errorAddingUserExpense',
    'expenseAddedSuccessfully',
    'fetchingAllTransactions',
    'errorFetchingTransactions',
    'fetchingMonthlyLimits',
    'errorFetchingMonthlyLimits',
    'updatingMonthlyLimits',
    'errorUpdatingMonthlyLimits',
    'deletingMonthlyLimits',
    'errorDeletingMonthlyLimits',
    'isMonthlyLimitsUpdated',
  ],
};

const expensesReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [EXPENSES_GET_ALL_CATEGORIES_INIT]: (state) => {
      state.fetchingAllCategories = true;
      state.errorFetchingCategories = null;
    },
    [EXPENSES_GET_ALL_CATEGORIES_SUCCESS]: (state, { payload }) => {
      const { categories } = payload;
      state.fetchingAllCategories = false;
      state.errorFetchingCategories = null;
      state.categories = categories;
    },
    [EXPENSES_GET_ALL_CATEGORIES_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.fetchingAllCategories = false;
      state.errorFetchingCategories = error;
    },
    [EXPENSES_ADD_EXPENSE_INIT]: (state) => {
      state.addingExpense = true;
      state.errorAddingUserExpense = null;
      state.expenseAddedSuccessfully = false;
    },
    [EXPENSES_ADD_EXPENSE_SUCCESS]: (state) => {
      state.addingExpense = false;
      state.errorAddingUserExpense = null;
      state.expenseAddedSuccessfully = true;
    },
    [EXPENSES_ADD_EXPENSE_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.addingExpense = false;
      state.errorAddingUserExpense = error;
    },
    [EXPENSES_GET_ALL_TRANSACTIONS_INIT]: (state) => {
      state.fetchingAllTransactions = true;
      state.errorFetchingTransactions = null;
    },
    [EXPENSES_GET_ALL_TRANSACTIONS_SUCCESS]: (state, { payload }) => {
      const { transactions } = payload;
      state.fetchingAllTransactions = false;
      state.errorFetchingTransactions = null;
      state.transactions = transactions;
    },
    [EXPENSES_GET_ALL_TRANSACTIONS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.fetchingAllTransactions = false;
      state.errorFetchingTransactions = error;
    },
    [EXPENSES_GET_USER_REPORTS_INIT]: (state) => {
      state.fetchingReports = true;
      state.errorFetchingReports = null;
    },
    [EXPENSES_GET_USER_REPORTS_SUCCESS]: (state, { payload }) => {
      const { reports } = payload;
      state.fetchingReports = false;
      state.errorFetchingReports = null;
      state.reports = reports;
    },
    [EXPENSES_GET_USER_REPORTS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.fetchingReports = false;
      state.errorFetchingReports = error;
    },
    [EXPENSES_CLEAN_UP]: (state) => {
      state.fetchingAllCategories = false;
      state.errorFetchingCategories = null;
      state.addingExpense = false;
      state.expenseAddedSuccessfully = false;
      state.errorAddingUserExpense = null;
      state.fetchingAllTransactions = false;
      state.errorFetchingTransactions = null;
      state.fetchingReports = false;
      state.errorFetchingReports = null;
      state.fetchingMonthlyLimits = false;
      state.errorFetchingMonthlyLimits = null;
      state.updatingMonthlyLimits = false;
      state.errorUpdatingMonthlyLimits = null;
      state.deletingMonthlyLimits = false;
      state.errorDeletingMonthlyLimits = null;
      state.isMonthlyLimitsUpdated = false;
      state.isMonthlyLimitCreated = false;
      state.creatingMonthlyLimits = false;
      state.errorCreatingMonthlyLimits = false;
    },
    [EXPENSES_GET_MONTHLY_LIMITS_INIT]: (state) => {
      state.fetchingMonthlyLimits = true;
      state.errorFetchingMonthlyLimits = null;
    },
    [EXPENSES_GET_MONTHLY_LIMITS_SUCCESS]: (state, { payload }) => {
      const { monthlyLimits } = payload;
      state.fetchingMonthlyLimits = false;
      state.errorFetchingMonthlyLimits = null;
      state.monthlyLimits = monthlyLimits;
    },
    [EXPENSES_GET_MONTHLY_LIMITS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.fetchingMonthlyLimits = false;
      state.errorFetchingMonthlyLimits = error;
    },
    [EXPENSES_UPDATE_MONTHLY_LIMITS_INIT]: (state) => {
      state.updatingMonthlyLimits = true;
      state.errorUpdatingMonthlyLimits = null;
      state.isMonthlyLimitsUpdated = false;
    },
    [EXPENSES_UPDATE_MONTHLY_LIMITS_SUCCESS]: (state) => {
      state.updatingMonthlyLimits = false;
      state.errorUpdatingMonthlyLimits = null;
      state.isMonthlyLimitsUpdated = true;
    },
    [EXPENSES_UPDATE_MONTHLY_LIMITS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.updatingMonthlyLimits = false;
      state.errorUpdatingMonthlyLimits = error;
    },
    [EXPENSES_DELETE_MONTHLY_LIMITS_INIT]: (state) => {
      state.deletingMonthlyLimits = true;
      state.errorDeletingMonthlyLimits = null;
    },
    [EXPENSES_DELETE_MONTHLY_LIMITS_SUCCESS]: (state, { payload }) => {
      const { uid } = payload;
      state.deletingMonthlyLimits = false;
      state.errorDeletingMonthlyLimits = null;
      state.monthlyLimits = state.monthlyLimits.filter(
        (limit) => limit.category.uid !== uid
      );
    },
    [EXPENSES_DELETE_MONTHLY_LIMITS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.deletingMonthlyLimits = false;
      state.errorDeletingMonthlyLimits = error;
    },
    [EXPENSES_CREATE_MONTHLY_LIMITS_INIT]: (state) => {
      state.creatingMonthlyLimits = true;
      state.errorCreatingMonthlyLimits = null;
      state.isMonthlyLimitCreated = false;
    },
    [EXPENSES_CREATE_MONTHLY_LIMITS_SUCCESS]: (state) => {
      state.creatingMonthlyLimits = false;
      state.errorCreatingMonthlyLimits = null;
      state.isMonthlyLimitCreated = true;
    },
    [EXPENSES_CREATE_MONTHLY_LIMITS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.errorCreatingMonthlyLimits = error;
      state.creatingMonthlyLimits = false;
    },
  })
);

export default expensesReducer;
