import createPersonalData from 'services/api/personalInformation/createPersonalData';
import deletePersonalData from 'services/api/personalInformation/deletePersonalData';
import getPersonalData from 'services/api/personalInformation/getPersonalData';
import updatePersonalData from 'services/api/personalInformation/updatePersonalData';
import {
  PERSONAL_INFORMATION_CLEAN_UP,
  PERSONAL_INFORMATION_CREATE_FAIL,
  PERSONAL_INFORMATION_CREATE_INIT,
  PERSONAL_INFORMATION_CREATE_SUCCESS,
  PERSONAL_INFORMATION_DELETE_FAIL,
  PERSONAL_INFORMATION_DELETE_INIT,
  PERSONAL_INFORMATION_DELETE_SUCCESS,
  PERSONAL_INFORMATION_GET_FAIL,
  PERSONAL_INFORMATION_GET_INIT,
  PERSONAL_INFORMATION_GET_SUCCESS,
  PERSONAL_INFORMATION_UPDATE_FAIL,
  PERSONAL_INFORMATION_UPDATE_INIT,
  PERSONAL_INFORMATION_UPDATE_SUCCESS,
} from 'state/actionCreators/personalInformation';

export const getPersonalInformation = () => async (dispatch, getState) => {
  dispatch(PERSONAL_INFORMATION_GET_INIT());

  const { token } = getState().auth;

  try {
    const data = await getPersonalData(token);

    return dispatch(PERSONAL_INFORMATION_GET_SUCCESS({ data }));
  } catch (error) {
    return dispatch(
      PERSONAL_INFORMATION_GET_FAIL({ error: error.response.data.message })
    );
  }
};

export const updatePersonalInformation = (data) => async (
  dispatch,
  getState
) => {
  dispatch(PERSONAL_INFORMATION_UPDATE_INIT());

  const { token } = getState().auth;

  try {
    await updatePersonalData(data, token);

    return dispatch(PERSONAL_INFORMATION_UPDATE_SUCCESS({ data }));
  } catch (error) {
    return dispatch(
      PERSONAL_INFORMATION_UPDATE_FAIL({ error: error.response.data.message })
    );
  }
};

export const deletePersonalInformation = () => async (dispatch, getState) => {
  dispatch(PERSONAL_INFORMATION_DELETE_INIT());

  const { token } = getState().auth;

  try {
    await deletePersonalData(token);

    return dispatch(PERSONAL_INFORMATION_DELETE_SUCCESS());
  } catch (error) {
    return dispatch(
      PERSONAL_INFORMATION_DELETE_FAIL({ error: error.response.data.message })
    );
  }
};

export const createPersonalInformation = (data) => async (
  dispatch,
  getState
) => {
  dispatch(PERSONAL_INFORMATION_CREATE_INIT());

  const { token } = getState().auth;

  try {
    await createPersonalData(data, token);

    return dispatch(PERSONAL_INFORMATION_CREATE_SUCCESS({ data }));
  } catch (error) {
    return dispatch(
      PERSONAL_INFORMATION_CREATE_FAIL({ error: error.response.data.message })
    );
  }
};

export const cleanUp = () => PERSONAL_INFORMATION_CLEAN_UP();
