import ApiPath from 'enums/apiPath.enum';
import { axiosInstance } from 'services/api/axios';

const getUserWallets = async (token) => {
  const { data } = await axiosInstance.get(ApiPath.Wallets, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

export default getUserWallets;
