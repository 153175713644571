import { COLORS } from 'resources/colors';
import { MEDIA } from 'resources/layout';
import styled, { css } from 'styled-components';
import { SIZES } from 'utils/constant';

const SizeStyles = {
  [SIZES.MEDIUM]: css`
    width: 243px;
    height: 48px;

    ${MEDIA.sm`
      width: 283px;
    `}

    ${MEDIA.tb`
      width: 362px;
    `}
  `,
};

const InputIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.size && SizeStyles[props.size]}
  border: 1px solid ${COLORS.GreyBorder};
  border-radius: 8px;

  svg {
    color: ${COLORS.GreyIcon};
    width: 15px;
    height: 15px;
    flex: 1;

    ${MEDIA.sm`
      width: 18px;
      height: 18px;
    `}
  }

  :focus-within {
    border: 1px solid ${COLORS.SkyBlue};
  }

  :hover {
    border: 1px solid ${COLORS.SkyBlue};
  }

  .react-tel-input {
    width: 200px;

    ${MEDIA.sm`
      width: 220px;
    `}

    ${MEDIA.tb`
      width: 320px;
    `}
  }

  .react-tel-input .form-control {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 1.38rem;

    outline: none;
    border: none;

    padding-left: 52px;
    padding-right: 16px;
    padding-bottom: 13px;
    padding-top: 11px;

    color: ${COLORS.Black};

    width: 200px;
  }

  ${MEDIA.sm`
    .react-tel-input .form-control {
      width: 240px;
    }
  `}

  ${MEDIA.tb`
    .react-tel-input .form-control {
      width: 320px;
    }
  `}

  

  .react-tel-input .form-control:focus {
    outline: none;
    border: transparent;
    background: transparent;
    box-shadow: none;
  }

  .react-tel-input .selected-flag .arrow {
    border-top: 4px solid ${COLORS.DarkLight};
    border-bottom: none;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 4px solid ${COLORS.DarkLight};
  }

  .react-tel-input .selected-flag:focus:before,
  .react-tel-input .selected-flag.open:before {
    border-color: transparent;
    box-shadow: none;
  }

  .react-tel-input .country-list {
    border-radius: 4px;

    width: 246px;

    ${MEDIA.sm`
      width: 286px;
    `}

    ${MEDIA.tb`
      width: 386px;
    `}
  }
`;

export default InputIcon;
