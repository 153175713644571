import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectUserById } from 'state/selectors/users';
import { fetchUserById } from 'state/actions/users';

const User = () => {
  const dispatch = useDispatch();
  const { id: userId } = useParams();

  const { user, loading, error } = useSelector((state) =>
    selectUserById(state, userId)
  );

  useEffect(() => {
    dispatch(fetchUserById(userId));
  }, [userId]);

  if (error) {
    return <h1>{error}</h1>;
  }

  return (
    <>
      {loading && <h1>Loading...</h1>}
      {!loading && <h1>{user?.name}</h1>}
    </>
  );
};

export default User;
