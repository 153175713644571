import { useMemo } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PrivateRoute from 'components/Navigation/PrivateRoute';
import RestrictedRoute from 'components/Navigation/RestrictedRoute';
import { shallowEqual, useSelector } from 'react-redux';
import { selectAuthData } from 'state/selectors/auth';
import NotFound from 'pages/404';
import TermsAndConditions from 'pages/TermsAndConditions';
import Path from 'enums/path.enum';

import privateRoutes from './privateRoutes';
import getRestrictedRoutes from './getRestrictedRoutes';

const Router = () => {
  const { isLogged } = useSelector(selectAuthData, shallowEqual);

  const restrictedRoutes = useMemo(() => getRestrictedRoutes({ isLogged }), [
    isLogged,
    getRestrictedRoutes,
  ]);

  return (
    <BrowserRouter>
      <Switch>
        {restrictedRoutes.map((values) => (
          <RestrictedRoute exact {...values} key={values.path} />
        ))}
        {privateRoutes.map((values) => (
          <PrivateRoute exact {...values} key={values.path} />
        ))}
        <Route
          exact
          component={TermsAndConditions}
          path={Path.TermsAndConditions}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
