import { createSelector } from '@reduxjs/toolkit';

const selectWalletsState = (state) => state.wallets;

export const selectWalletTypes = createSelector(
  selectWalletsState,
  ({ types, loadingTypes, errorFetchingTypes }) => ({
    types: types?.map(({ name, uid }) => ({ label: name, value: uid })) || [],
    loading: loadingTypes,
    error: errorFetchingTypes,
  })
);

export const selecCreateWalletState = createSelector(
  selectWalletsState,
  ({ creatingWallet, errorWalletCreate, createWalletSuccess }) => ({
    loading: creatingWallet,
    error: errorWalletCreate,
    success: createWalletSuccess,
  })
);

export const selecUserWalletsState = createSelector(
  selectWalletsState,
  ({
    fetchingUserWallet,
    loadingTypes,
    deletingWallet,
    errorFetchingUserWallet,
    errorFetchingTypes,
    errorDeletingWallet,
    wallets,
    types,
    isWalletDeleted,
  }) => ({
    loading: fetchingUserWallet || loadingTypes || deletingWallet,
    error: errorFetchingUserWallet ?? errorFetchingTypes ?? errorDeletingWallet,
    wallets,
    types,
    isWalletDeleted,
    optionWallets:
      wallets?.map(({ name, uid, balance, currency }) => ({
        label: `${name} | ${balance} ${currency}`,
        value: uid,
      })) ?? [],
  })
);
