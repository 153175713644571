export const scope = 'app.pages.addExpense';

export const messages = {
  expenseData: {
    id: `${scope}.expenseData`,
    defaultMessage: 'Ingrese los datos de la transacción',
  },
  expenseTitle: {
    id: `${scope}.expenseTitle`,
    defaultMessage: 'Agregar transacción',
  },
  addExpense: {
    id: `${scope}.addExpense`,
    defaultMessage: 'Agregar',
  },
};
