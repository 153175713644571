import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { FiPhone } from 'react-icons/fi';
import * as yup from 'yup';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth } from 'firebase/auth';

import useErrorToast from 'hooks/useErrorToast';

import Form from 'components/Form';
import PhoneInputWithLabel from 'components/PhoneInputWithLabel';
import Link from 'components/Link';
import Image, { SizeImage } from 'components/Image';

import { signUp, authCleanState } from 'state/actions/auth';
import { selectStatusSignUp } from 'state/selectors/auth';

import dispatchAction from 'datadog/utils';
import { REGISTER_ACTION } from 'datadog/actions/Auth';

import { lowerCaseRegex, upperCaseRegex } from 'utils/regex';
import { SIZES } from 'utils/constant';
import RegisterImage from 'images/Register.svg';
import { inputProps } from 'utils/pages/register/fields';
import { isOnlyCountryCode } from 'utils/validations';

import InputWithLabel from 'components/InputWithLabel';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import FlexContainer from 'components/FlexContainer';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import Text, { Size } from 'components/Text';
import useProvidersConfig from 'hooks/useProvidersConfig';
import withNonAuthenticatedLayout from 'components/hocs/withNonAuthenticatedLayout';
import ErrorMessage from 'components/ErrorMessage.js';
import Loading from 'components/Loading';
import Card from 'components/Card';

import { generalMessages } from 'utils/generalMessages';
import Path from 'enums/path.enum';
import { messages } from './messages';

import TermsAndConditions from './styles/TermsAndConditions';

const registerSchema = yup.object({
  name: yup.string().required(generalMessages.required.defaultMessage),
  email: yup
    .string()
    .email(generalMessages.invalidFormat.defaultMessage)
    .required(generalMessages.required.defaultMessage),
  password: yup
    .string()
    .required(generalMessages.required.defaultMessage)
    .min(8, generalMessages.minLength.defaultMessage)
    .matches(lowerCaseRegex, generalMessages.oneLowerCase.defaultMessage)
    .matches(upperCaseRegex, generalMessages.oneUpperCase.defaultMessage),
  confirmPassword: yup
    .string()
    .required(generalMessages.required.defaultMessage)
    .oneOf([yup.ref('password')], generalMessages.passwordMatch.defaultMessage),
  phone: yup.string(),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], generalMessages.termsRequired.defaultMessage),
});

const resolver = yupResolver(registerSchema);

const handleRegisterData = ({ phone, name, email, password }) => {
  const phoneWithoutEmptySpaces = phone.replaceAll(/\s/g, '');
  const isCountryCode = isOnlyCountryCode(phone, phoneWithoutEmptySpaces);

  const registerData = {
    name,
    email,
    password,
    ...(!isCountryCode ? { phoneNumber: phoneWithoutEmptySpaces } : {}),
  };

  return registerData;
};

const Register = () => {
  const providersUIConfig = useProvidersConfig();

  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectStatusSignUp, shallowEqual);
  useErrorToast(error);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: 'all',
    resolver,
  });

  const onSubmit = async (data) => {
    const registerData = handleRegisterData(data);
    dispatch(signUp(registerData));
    dispatchAction(REGISTER_ACTION, registerData);
  };

  useEffect(
    () => () => {
      dispatch(authCleanState());
    },
    []
  );

  return (
    <>
      {loading && <Loading />}
      <Card>
        <Form size={SIZES.MEDIUM} onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer column gap={15} middle="xs">
            <Image src={RegisterImage} size={SizeImage.L} />
            <FlexContainer column gap={5} middle="xs">
              <Title color={ColorsTitle.Dark} size={SizeTitle.S}>
                {messages.createAccount.defaultMessage}
              </Title>
              <Text size={Size.M}>{messages.welcome.defaultMessage}</Text>
            </FlexContainer>
          </FlexContainer>
          {inputProps.map((values) => (
            <InputWithLabel
              {...values}
              register={register}
              errors={errors}
              key={values.fieldName}
            />
          ))}
          <PhoneInputWithLabel
            label={generalMessages.phone.defaultMessage}
            regions="america"
            icon={<FiPhone />}
            size={SIZES.MEDIUM}
            register={register}
            fieldName={generalMessages.phone.fieldName}
            getValues={getValues}
          />
          <Button fullWidth disabled={!isValid}>
            {messages.signUp.defaultMessage}
          </Button>
          <FlexContainer column gap={5} fitContent middle="xs">
            <TermsAndConditions>
              <Checkbox
                type="checkbox"
                name={messages.accept.fieldName}
                ref={register}
                error={errors[messages.accept.fieldName]}
              />

              <Text dark size={Size.S}>
                {messages.accept.defaultMessage}&nbsp;
                <Link
                  size={Size.S}
                  to={Path.TermsAndConditions}
                  target="_blank"
                >
                  {messages.termsAndConditions.defaultMessage}
                </Link>
              </Text>
            </TermsAndConditions>
            {errors[messages.accept.fieldName] && (
              <ErrorMessage>
                {errors[messages.accept.fieldName]?.message}
              </ErrorMessage>
            )}
          </FlexContainer>
          <FlexContainer column textAlign="center">
            <StyledFirebaseAuth
              uiConfig={providersUIConfig}
              firebaseAuth={getAuth()}
            />
            <Text dark size={Size.M}>
              {messages.haveAccount.defaultMessage}&nbsp;
              <Link size={Size.M} to={Path.Login}>
                {messages.signIn.defaultMessage}
              </Link>
            </Text>
          </FlexContainer>
        </Form>
      </Card>
    </>
  );
};

export default withNonAuthenticatedLayout(Register);
