/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import store from 'state/store';
import { SAVE_TOKEN } from 'state/actionCreators/auth';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.response.use(
  (response) => ({
    ...response,
  }),
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const auth = getAuth();
      const { currentUser } = auth;
      if (currentUser) {
        const token = await currentUser.getIdToken();
        try {
          store.dispatch(SAVE_TOKEN({ token }));
          originalRequest.headers.Authorization = `Bearer ${token}`;
          const retryResponse = await axiosInstance.request(originalRequest);
          return retryResponse;
        } catch (e) {
          if (e.response && e.response.status === 401) {
            window.location.replace('/login');
          }
          return Promise.reject(e);
        }
      }
    } else if (
      error.response &&
      error.response.status === 401 &&
      originalRequest._retry
    ) {
      window.location.replace('/login');
    }
    return Promise.reject(error);
  }
);
