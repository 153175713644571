import Path from 'enums/path.enum';
import Home from 'pages/Home';
import CreateWallet from 'pages/CreateWallet';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import User from 'pages/User';
import Users from 'pages/Users';
import AddExpense from 'pages/AddExpense';
import Wallets from 'pages/Wallets';
import Reports from 'pages/Reports';
import Scheduled from 'pages/Scheduled';
import BankStatement from 'pages/BankStatement';
import ConfirmTransactions from 'pages/ConfirmTransactions';
import PersonalInformation from 'pages/PersonalInformation';
import Notifications from 'pages/Notifications';
import MonthlyLimits from 'pages/MonthlyLimits';
import NewMonthlyLimit from 'pages/NewMonthlyLimit';
import UpdateMonthlyLimit from 'pages/UpdateMonthlyLimit';

const privateRoutes = [
  {
    component: NewMonthlyLimit,
    path: Path.NewMonthlyLimit,
  },
  {
    component: MonthlyLimits,
    path: Path.MonthlyLimits,
  },
  {
    component: UpdateMonthlyLimit,
    path: Path.UpdateMonthlyLimit,
  },
  {
    component: Notifications,
    path: Path.Notifications,
  },
  {
    component: PersonalInformation,
    path: Path.PersonalInformation,
  },
  {
    component: ConfirmTransactions,
    path: Path.ConfirmTransactions,
  },
  {
    component: BankStatement,
    path: Path.BankStatement,
  },
  {
    component: Wallets,
    path: Path.Wallets,
  },
  {
    component: CreateWallet,
    path: Path.CreateWallet,
  },
  {
    component: Scheduled,
    path: Path.Scheduled,
  },
  {
    component: Reports,
    path: Path.Reports,
  },
  {
    component: AddExpense,
    path: Path.AddExpense,
  },
  {
    component: ResetPassword,
    path: Path.ResetPassword,
  },
  {
    component: Users,
    path: Path.Users,
  },
  {
    component: User,
    path: Path.User,
  },
  {
    component: Profile,
    path: Path.Profile,
  },
  {
    component: Home,
    path: Path.Home,
  },
];

export default privateRoutes;
