export const scope = 'app.pages.forgot-password-form';

export const messages = {
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Ingrese su dirección de email y le llegará un email con instrucciones para reiniciar su contraseña',
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: 'Reiniciar Contraseña',
  },
  return: {
    id: `${scope}.return`,
    defaultMessage: '¿Te arrepentiste?',
  },
  success: {
    id: `${scope}.return`,
    defaultMessage: 'Se envió correctamente el email',
  },
};
