import FlexContainer from 'components/FlexContainer';
import withNonAuthenticatedLayout from 'components/hocs/withNonAuthenticatedLayout';
import Text from 'components/Text';
import Title from 'components/Title';
import Path from 'enums/path.enum';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const StyledImage = styled.img`
  height: 250px;
  width: 250px;
  cursor: pointer;
`;

const NotFound = () => {
  const { push } = useHistory();
  return (
    <FlexContainer column gap={24} middle="xs" textAlign="center">
      <Title>Error 404: página no encontrada</Title>
      <Text>
        El URL
        <strong>&nbsp;{window.location.pathname}&nbsp;</strong>
        no fue encontrado
      </Text>
      <Text>
        No dejes que esto afecte tus ahorros y hace click en la alcancía para
        seguir ahorrando
      </Text>
      <StyledImage
        src="/404.png"
        alt="Cerdo alcancía"
        onClick={() => push(Path.Home)}
      />
    </FlexContainer>
  );
};

export default withNonAuthenticatedLayout(NotFound);
