export const scope = 'app.pages.register';

export const messages = {
  yourData: {
    id: `${scope}.yourData`,
    defaultMessage: 'Aquí estan tus datos',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Perfil',
  },
  updateProfile: {
    id: `${scope}.updateProfile`,
    defaultMessage: 'Actualizar perfil',
  },
  deleteProfile: {
    id: `${scope}.deleteProfile`,
    defaultMessage: 'Eliminar cuenta',
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: '¿Queres reiniciar la contraseña?',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Actualizala',
  },
};
