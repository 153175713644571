import { createSelector } from '@reduxjs/toolkit';

const selectNotificationsState = (state) => state.notifications;

export const selectNotificationsData = createSelector(
  selectNotificationsState,
  ({ error, loading, notifications, success }) => {
    const defaultValues = {};

    notifications.forEach(({ notifications: nts }) => {
      nts.forEach(({ value, title }) => {
        defaultValues[title] = value;
      });
    });

    return {
      error,
      loading,
      success,
      notifications,
      defaultValues,
    };
  }
);

export default selectNotificationsState;
