import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
  text-align: justify;
`;

export default Container;
