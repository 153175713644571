import { createSelector } from '@reduxjs/toolkit';

const selectUsersState = (state) => state.users;

export const selectAllUsers = createSelector(selectUsersState, (state) => ({
  ...state,
  allUsers: state.data,
}));

export const selectAllAdminUsers = createSelector(
  selectUsersState,
  (state) => ({
    ...state,
    adminUsers: state.data.filter(({ isAdmin }) => isAdmin),
  })
);

export const selectUserById = createSelector(
  selectUsersState,
  (_, userId) => userId,
  (state, userId) => ({
    ...state,
    user: state.data.find((user) => user.id === userId),
  })
);

export const selectLoggedUser = createSelector(
  selectUsersState,
  ({
    loadingFetchUser,
    errorFetchUser,
    user,
    loadingUpdate,
    errorUpdate,
    loadingDelete,
    errorDelete,
    deleteUser,
  }) => ({
    loadingUser: loadingFetchUser || loadingUpdate || loadingDelete,
    errorUser: errorFetchUser || errorUpdate || errorDelete,
    user: {
      ...user,
      phone: user?.phoneNumber,
    },
    deleteUserAction: deleteUser,
  })
);
