/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BsTrashFill } from 'react-icons/bs';

import Table from 'components/Table';
import Loading from 'components/Loading';
import useErrorToast from 'hooks/useErrorToast';
import { generalMessages } from 'utils/generalMessages';

import {
  getUserWallets,
  getWalletTypes,
  removeWallet,
  walletsCleanUp,
} from 'state/actions/wallets';
import { selecUserWalletsState } from 'state/selectors/wallets';

import dispatchAction from 'datadog/utils';
import { DELETE_WALLET_ACTION } from 'datadog/actions/Wallets';

import FlexContainer from 'components/FlexContainer';
import { COLORS } from 'resources/colors';
import Title, { SizeTitle } from 'components/Title';
import Link from 'components/Link';
import Text from 'components/Text';
import Path from 'enums/path.enum';
import useSuccessToast from 'hooks/useSuccessToast';

const Wallets = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserWallets());
    dispatch(getWalletTypes());

    return () => dispatch(walletsCleanUp());
  }, []);

  const { loading, error, wallets, types, isWalletDeleted } = useSelector(
    selecUserWalletsState,
    shallowEqual
  );

  const onDeleteWallet = (uid) => {
    dispatch(removeWallet(uid));
    dispatchAction(DELETE_WALLET_ACTION, uid);
  };

  const columns = useMemo(
    () => [
      {
        Header: generalMessages.walletName.defaultMessage,
        accessor: 'name',
      },
      {
        Header: 'Divisa',
        accessor: 'currency',
      },
      {
        Header: generalMessages.balance.defaultMessage,
        accessor: 'balance',
      },
      {
        Header: 'Proveniencia de fondos',
        accessor: 'walletTypeId',
        Cell: ({
          row: {
            original: { walletTypeId },
          },
        }) => {
          const name = types?.find((wallet) => wallet.uid === walletTypeId)
            ?.name;

          return <>{name}</>;
        },
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: ({
          row: {
            original: { uid },
          },
        }) => (
          <>
            <BsTrashFill
              onClick={() => onDeleteWallet(uid)}
              color={COLORS.RedButton}
              style={{ cursor: 'pointer' }}
            />
          </>
        ),
      },
    ],
    [types]
  );

  useErrorToast(error);

  useSuccessToast(isWalletDeleted, 'Billetera eliminada correctamente');

  return (
    <>
      {loading && <Loading />}
      <FlexContainer middle="xs" between="xs" padding="0 2rem">
        <Title size={SizeTitle.M}>Billeteras</Title>
        <Link to={Path.CreateWallet}>Agregar Billetera</Link>
      </FlexContainer>
      <FlexContainer padding="2rem" column gap={32} middle="xs">
        {wallets.length > 0 ? (
          <Table columns={columns} data={wallets} />
        ) : (
          <>
            <Text>No hay billeteras disponibles</Text>
          </>
        )}
      </FlexContainer>
    </>
  );
};

export default Wallets;
