/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';

import Label from 'components/Label';

import Container from './styles/Container';
import InputIcon from './styles/InputIcon';

const PhoneInputWithLabel = ({
  label,
  placeholder,
  size,
  icon,
  register,
  fieldName,
  getValues,
  value,
}) => (
  <Container>
    <Label>{label}</Label>
    <InputIcon size={size}>
      <PhoneInput
        country="uy"
        placeholder={placeholder}
        regions="america"
        countryCodeEditable={false}
        localization={es}
        inputProps={{
          name: fieldName,
          ref: register,
        }}
        value={value || getValues(fieldName)}
      />
      {icon}
    </InputIcon>
  </Container>
);

PhoneInputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.element,
  register: PropTypes.func,
  fieldName: PropTypes.string,
  getValues: PropTypes.func,
  value: PropTypes.string,
};

PhoneInputWithLabel.defaultProps = {
  icon: null,
  placeholder: '',
  register: () => {},
  fieldName: '',
  getValues: () => {},
  value: '',
};

export default PhoneInputWithLabel;
