// eslint-disable-next-line import/prefer-default-export
export const COLORS = {
  // Grey
  GreyBackground: '#e8edf2',
  GreyLetter: '#C6CBD9',
  GreyIcon: '#9A9AAF',
  LightGrey: '#F5F5FA',
  GreyButton: '#E8EDF2',

  // White
  White: '#ffffff',

  // Black
  Black: '#000000',

  // Blue
  DarkBlue: '#07070c',
  SkyBlue: '#00B6E6',
  PrimaryBlue: '#0039A4',
  AhorranzaBlue: '#1C5FDC',

  // Red
  RedButton: '#E23738',
  DarkRedButton: '#990f10',

  // Border
  GreyBorder: '#e8edf2',

  // Shadow
  LightShadow: 'rgba(0, 0, 0, 0.1)',
  SubitemsShadow: 'rgba(0, 0, 0, 0.12)',

  // Green
  Green: '#50D1B2',
  Emeral: '#5EEA8D',

  // Text color
  VioletGrey: '#8083a3',
  GreyText: '#7E7E8F',
  RedError: '#bf1650',

  // File Input
  FileContainer: '#fafbfc',
  ContainerBorder: '#e9ecef',
};
