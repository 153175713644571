import { MEDIA } from 'resources/layout';
import styled from 'styled-components';

const FileDescription = styled.div`
  margin-bottom: 1rem;
  color: #667085;
  display: flex;
  flex-direction: column;

  ${MEDIA.md`
    margin: 0;
  `}
`;

export default FileDescription;
