import React from 'react';
import { useDispatch } from 'react-redux';

import { createMonthlyLimit } from 'state/actions/expenses';

import MonthLimitForm from 'components/Forms/MonthLimitForm';

const NewMonthlyLimit = () => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(createMonthlyLimit(values));
  };

  return (
    <>
      <MonthLimitForm onSubmit={onSubmit} />
    </>
  );
};

export default NewMonthlyLimit;
