import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Chart from 'react-apexcharts';

import FlexContainer from 'components/FlexContainer';
import Loading from 'components/Loading';
import SelectInput from 'components/SelectInput';
import Title, { SizeTitle } from 'components/Title';
import RangePicker from 'components/RangePicker';
import useErrorToast from 'hooks/useErrorToast';

import { getUserWallets } from 'state/actions/wallets';
import { selecUserWalletsState } from 'state/selectors/wallets';
import { expensesCleanUp, getUserReports } from 'state/actions/expenses';
import Text, { Size } from 'components/Text';
import getApiQueryParams from 'utils/common/getApiQueryParams';
import dayjs from 'dayjs';
import { chartProps, chartResponsive, DATE_FORMAT } from 'utils/constant';
import { selectReportsData } from 'state/selectors/expenses';

const transactionOptions = [
  { value: true, label: 'Gasto' },
  { value: false, label: 'Ingreso' },
];

const Reports = () => {
  const dispatch = useDispatch();

  const [{ startDate, endDate }, setDate] = useState({
    startDate: dayjs().startOf('month').toDate(),
    endDate: new Date(),
  });
  const [wallet, setWallet] = useState();
  const [isExpense, setIsExpense] = useState(transactionOptions[0].value);
  const [subcategory, setSubcategory] = useState(null);

  const onChangeDate = ([start, end]) => {
    setDate({
      startDate: start,
      endDate: end,
    });
  };

  useEffect(() => {
    dispatch(getUserWallets());
  }, []);

  useEffect(() => () => dispatch(expensesCleanUp()), []);

  useEffect(() => {
    if (startDate && endDate) {
      const params = getApiQueryParams({
        startDate: dayjs(startDate).format(DATE_FORMAT),
        endDate: dayjs(endDate).format(DATE_FORMAT),
        isExpense,
        ...(wallet && { walletId: wallet }),
      });

      setSubcategory(null);
      dispatch(getUserReports(params));
    }
  }, [startDate, endDate, wallet, isExpense]);

  const {
    loading: loadingWallets,
    optionWallets,
    error: walletsError,
  } = useSelector(selecUserWalletsState, shallowEqual);

  const { loading, error, labels, series, total, subcategories } = useSelector(
    selectReportsData,
    shallowEqual
  );

  const onSelectCategory = (categoryIndex) => {
    setSubcategory((prevState) => {
      if (prevState && prevState.index === categoryIndex) {
        return null;
      }

      const newSubcategory = {
        index: categoryIndex,
      };

      const subcategoryData = subcategories[categoryIndex];

      if (subcategoryData) {
        return { ...newSubcategory, ...subcategoryData };
      }

      const categoryData = {
        ...newSubcategory,
        labels: [labels[categoryIndex]],
        series: [series[categoryIndex]],
      };

      return categoryData;
    });
  };

  useErrorToast(walletsError);
  useErrorToast(error);

  return (
    <div>
      {(loading || loadingWallets) && <Loading />}
      <FlexContainer middle="xs" between="xs" padding="0 2rem">
        <Title size={SizeTitle.M}>Reportes</Title>
      </FlexContainer>
      <FlexContainer between="xs" padding="2rem" gap={32}>
        <RangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDate}
          selectsRange
          selectsDisabledDaysInRange
        />
        <SelectInput
          options={transactionOptions}
          onChange={({ value }) => {
            setIsExpense(value);
          }}
          value={transactionOptions.find(
            (option) => option.value === isExpense
          )}
          isSearchable
        />
        <SelectInput
          options={optionWallets}
          onChange={(option) => {
            setWallet(option ? option.value : null);
          }}
          placeholder="Seleccionar billetera"
          isClearable
          isSearchable
        />
      </FlexContainer>
      <FlexContainer padding="2rem" gap={32} middle="xs" background>
        <FlexContainer column gap={32} spread>
          {total === 0 ? (
            <Text size={Size.M}>No se encuentran datos</Text>
          ) : (
            <FlexContainer between="xs" gap={32} middle="xs" spread>
              <FlexContainer flex="1" column middle="xs" gap={32}>
                <Text>Gastos por categoría</Text>
                <Chart
                  {...chartProps}
                  options={{
                    chart: {
                      events: {
                        dataPointSelection(event, chartContext, config) {
                          const categoryIndex = config.dataPointIndex;
                          onSelectCategory(categoryIndex);
                        },
                      },
                    },
                    labels,
                    responsive: chartResponsive,
                  }}
                  series={series}
                />
              </FlexContainer>
              <FlexContainer flex="1" column middle="xs" gap={32}>
                {!subcategory ? (
                  <Text>
                    Seleccione una categoría para detallar las subcategorías
                  </Text>
                ) : (
                  <>
                    <Text>Detalle</Text>
                    <Chart
                      {...chartProps}
                      options={{
                        labels: subcategory.labels,
                        responsive: chartResponsive,
                      }}
                      series={subcategory.series}
                    />
                  </>
                )}
              </FlexContainer>
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

export default Reports;
