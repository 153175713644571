import { COLORS } from 'resources/colors';
import styled from 'styled-components';

const TermsAndConditions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;

  color: ${COLORS.VioletGrey};
`;

export default TermsAndConditions;
