import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { uploadBankState } from 'state/actions/bankStatements';

import FlexContainer from 'components/FlexContainer';
import Title, { SizeTitle } from 'components/Title';
import FileInput from 'components/FileInput';
import BankIcon from 'components/BankIcon';
import SubTitle, { ColorsSubTitle, SizeSubTitle } from 'components/SubTitle';

import { banks } from './Banks';
import StyledButton from './styles/StyledButton';

const BankStatement = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [selectedBank, setSelectedBank] = useState(null);
  const [file, setFile] = useState(null);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('bankStatement', file);
    formData.append('bank', selectedBank);
    dispatch(uploadBankState(formData));
    push('/confirm-transactions');
  };

  return (
    <>
      <FlexContainer middle="xs" between="xs" padding="0 2rem">
        <Title size={SizeTitle.M}>Estado de cuenta bancario</Title>
      </FlexContainer>

      <FlexContainer column middle="xs" gap="20">
        <FlexContainer middle="xs" spread column gap="10">
          <SubTitle color={ColorsSubTitle.Grey} size={SizeSubTitle.M}>
            Selecciona tu banco de preferencia
          </SubTitle>
          <FlexContainer column="xs" row="tb">
            {banks.map((bank) => (
              <BankIcon
                {...bank}
                onSelected={setSelectedBank}
                selected={selectedBank}
                key={bank.value}
              />
            ))}
          </FlexContainer>
        </FlexContainer>
        <FileInput
          name="bankStatementFile"
          accept=".xls"
          file={file}
          setFile={setFile}
        />
        <StyledButton disabled={!selectedBank || !file} onClick={handleSubmit}>
          Subir estado de cuenta
        </StyledButton>
      </FlexContainer>
    </>
  );
};

export default BankStatement;
