/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectPersonalInformationState = (state) => state.personalInformation;

export const selectPersonalInformation = createSelector(
  selectPersonalInformationState,
  ({
    errorFetchingPersonalInformation,
    loadingPersonalInformation,
    deletingPersonalInformation,
    errorDeletingPersonalInformation,
    isPersonalInformationDeleted,
    errorUpdatingPersonalInformation,
    updatingPersonalInformation,
    isPersonalInformationUpdated,
    errorPersonalInformationCreate,
    creatingPersonalInformation,
    isPersonalInformationCreated,
    data,
  }) => ({
    isLoading:
      loadingPersonalInformation ||
      deletingPersonalInformation ||
      updatingPersonalInformation ||
      creatingPersonalInformation,
    error:
      errorFetchingPersonalInformation ??
      errorDeletingPersonalInformation ??
      errorUpdatingPersonalInformation ??
      errorPersonalInformationCreate,
    isDeleted: isPersonalInformationDeleted,
    isUpdated: isPersonalInformationUpdated,
    isCreated: isPersonalInformationCreated,
    data,
  })
);
