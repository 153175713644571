const ApiPath = Object.freeze({
  SignUpEmailPassword: '/users',
  SignUpProviders: '/users',
  Profile: '/users/profile/',
  UpdateUser: '/users/',
  Wallets: '/wallets',
  WalletTypes: '/wallets/types',
  Categories: '/categories',
  Transactions: '/transactions',
  Scheduled: '/transactions/scheduled',
  TransactionsBulk: '/transactions/bulk',
  DeleteUser: '/users/',
  Reports: '/reports',
  Bank: '/bank-statements',
  PersonalInformation: '/personal-information',
  SendMonthlyRecommendations: '/send-monthly-recommendations',
  LimitsPerCategories: '/limits-per-categories',
  NotificationsManagement: '/notifications-management',
});

export default ApiPath;
