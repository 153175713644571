import Select, { components } from 'react-select';

import { COLORS } from 'resources/colors';

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: `1px solid ${COLORS.GreyBorder}`,
    padding: '0.125rem 0 0.125rem 0.875rem',
    borderRadius: '8px',
  }),
  container: (provided) => ({
    ...provided,
    paddingLeft: '0.75px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: COLORS.Black,
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    paddingLeft: '0.125rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: COLORS.GreyText,
  }),
};

const NoOptionsMessage = (props) => (
  <components.NoOptionsMessage {...props}>
    No hay opciones
  </components.NoOptionsMessage>
);

const SelectInput = (props) => (
  <Select
    components={{ NoOptionsMessage }}
    styles={customStyles}
    {...props}
    noOptionsMessage={() => 'No hay opciones'}
  />
);

export default SelectInput;
