import { MEDIA } from 'resources/layout';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  ${MEDIA.tb`
    width: 280px;
`}
`;

export default DropdownContainer;
