import { useForm } from 'react-hook-form';
import { MdOutlineEmail } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Button from 'components/Button';
import Form from 'components/Form';
import Link, { Size as LinkSize } from 'components/Link';
import Text, { Size as TextSize } from 'components/Text';
import InputWithLabel from 'components/InputWithLabel';
import { SIZES } from 'utils/constant';
import { generalMessages } from 'utils/generalMessages';
import { resetPassword } from 'state/actions/auth';
import { selectResetPassword } from 'state/selectors/auth';

import dispatchAction from 'datadog/utils';
import { RESET_FORGOT_PASSWORD_ACTION } from 'datadog/actions/Auth';

import FlexContainer from 'components/FlexContainer';
import Loading from 'components/Loading';
import useErrorToast from 'hooks/useErrorToast';
import { COLORS } from 'resources/colors';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import ForgotPasswordImage from 'images/ForgotPassword.svg';
import Image, { SizeImage } from 'components/Image';

import { messages } from './messages';

const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email(generalMessages.invalidFormat.defaultMessage)
    .required(generalMessages.required.defaultMessage),
});

const resolver = yupResolver(forgotPasswordSchema);

const ForgotPasswordForm = ({ title, returnPath }) => {
  const dispatch = useDispatch();

  const { loading, error, success } = useSelector(
    selectResetPassword,
    shallowEqual
  );

  useErrorToast(error);

  const onSubmit = ({ email }) => {
    dispatch(resetPassword(email));
    dispatchAction(RESET_FORGOT_PASSWORD_ACTION, email);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver,
  });

  return (
    <>
      {loading && <Loading />}
      <Form size={SIZES.SMALL} onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer column gap={15} middle="xs">
          <Image src={ForgotPasswordImage} size={SizeImage.L} />
          <FlexContainer column gap={5} middle="xs" textAlign="center">
            <Title color={ColorsTitle.Dark} size={SizeTitle.S}>
              {title}
            </Title>
            <Text size={TextSize.M} maxWidth="263px">
              {messages.description.defaultMessage}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <InputWithLabel
          type="email"
          placeholder={generalMessages.email.defaultMessage}
          label={generalMessages.email.defaultMessage}
          size={SIZES.MEDIUM}
          register={register}
          icon={<MdOutlineEmail />}
          fieldName="email"
          errors={errors}
        />
        <FlexContainer column middle="xs">
          <Button fullWidth disabled={!isValid}>
            {messages.resetPassword.defaultMessage}
          </Button>
          <Text dark size={TextSize.M}>
            <Link size={LinkSize.M} to={returnPath}>
              {messages.return.defaultMessage}
            </Link>
          </Text>
          {success && (
            <Text size={TextSize.S} color={COLORS.Green}>
              {messages.success.defaultMessage}
            </Text>
          )}
        </FlexContainer>
      </Form>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  title: PropTypes.string.isRequired,
  returnPath: PropTypes.string.isRequired,
};

export default ForgotPasswordForm;
