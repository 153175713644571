import React, { useEffect } from 'react';
import { Switch } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import IconButton from '@mui/material/IconButton';

import Card from 'components/Card';
import FlexContainer from 'components/FlexContainer';
import Form from 'components/Form';
import Image, { SizeImage } from 'components/Image';
import Text, { Size } from 'components/Text';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import { COLORS } from 'resources/colors';
import NotificationImage from 'images/Notification.svg';
import { selectNotificationsData } from 'state/selectors/notifications';
import Loading from 'components/Loading';
import {
  cleanUpNotifications,
  getUserNoficiations,
  updateUserNotifications,
} from 'state/actions/notifications';
import useErrorToast from 'hooks/useErrorToast';
import Button from 'components/Button';
import useSuccessToast from 'hooks/useSuccessToast';
import getNotificationsBody from './getNotificationsBody';

const Notifications = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserNoficiations());

    return () => {
      dispatch(cleanUpNotifications());
    };
  }, []);

  const { error, loading, notifications, defaultValues, success } = useSelector(
    selectNotificationsData,
    shallowEqual
  );

  useErrorToast(error);

  useSuccessToast(success, 'Notificaciones actualizadas');

  const [state, setState] = React.useState(defaultValues);

  const handleChange = (event, id) => {
    setState((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
  };

  const onSubmit = () => {
    const body = getNotificationsBody(state);

    dispatch(updateUserNotifications(body));
  };

  return (
    <>
      {loading && <Loading />}
      <Card>
        <Form>
          <FlexContainer column gap={15} middle="xs">
            <Image src={NotificationImage} size={SizeImage.XL} />
            <FlexContainer column gap={5} middle="xs">
              <Title color={ColorsTitle.Dark} size={SizeTitle.M}>
                Notificaciones
              </Title>
              <Text size={Size.M}>
                Elegí las notificaciones que se adapten a tus preferencias
              </Text>
            </FlexContainer>
          </FlexContainer>
          {notifications.map(({ section, notifications: items }) => (
            <FlexContainer column top="xs" spread gap={24} key={section}>
              <Text color={COLORS.Black}>{section}</Text>
              {items.map(({ title, value, inactiveReason }) => (
                <FlexContainer
                  spread
                  gap={16}
                  between="xs"
                  middle="xs"
                  key={title}
                >
                  <Text size={Size.M}>{title}</Text>
                  {inactiveReason ? (
                    <Tooltip title={inactiveReason}>
                      <IconButton>
                        <BsFillInfoCircleFill />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Switch
                      checked={state[title] ?? value}
                      onChange={(event) => handleChange(event, title)}
                    />
                  )}
                </FlexContainer>
              ))}
            </FlexContainer>
          ))}
          <Button type="button" onClick={onSubmit}>
            Guardar preferencias
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default Notifications;
