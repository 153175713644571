/* eslint-disable react/prop-types */
import Text from 'components/Text';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { BsDashLg, BsTrashFill } from 'react-icons/bs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'resources/colors';
import { getUserWallets } from 'state/actions/wallets';
import { selecUserWalletsState } from 'state/selectors/wallets';
import { USER_DATE_FORMAT } from 'utils/constant';
import { generalMessages } from 'utils/generalMessages';
import useErrorToast from './useErrorToast';

// eslint-disable-next-line no-unused-vars
const useScheduledColumns = ({ onEdit, onDelete }) => {
  const dispatch = useDispatch();

  const { error, wallets } = useSelector(selecUserWalletsState, shallowEqual);

  useErrorToast(error);

  useEffect(() => {
    dispatch(getUserWallets());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: generalMessages.title.defaultMessage,
        accessor: generalMessages.title.fieldName,
        Cell: ({
          row: {
            original: { title },
          },
        }) => {
          const name = title || '-';

          return <>{name}</>;
        },
      },
      {
        Header: generalMessages.date.defaultMessage,
        accessor: 'date',
        Cell: ({
          row: {
            original: { date },
          },
        }) => <>{dayjs(date).format(USER_DATE_FORMAT)}</>,
      },
      {
        Header: generalMessages.amount.defaultMessage,
        accessor: 'amount',
        Cell: ({
          row: {
            original: { amount, isExpense },
          },
        }) => (
          <>
            {isExpense && <BsDashLg color={COLORS.RedButton} />}&nbsp;
            <Text color={isExpense ? COLORS.RedButton : COLORS.Black}>
              {amount}
            </Text>
          </>
        ),
      },
      {
        Header: generalMessages.wallet.defaultMessage,
        accessor: 'walletId',
        Cell: ({
          row: {
            original: { walletId },
          },
        }) => {
          const name =
            wallets?.find(({ uid }) => uid === walletId)?.name || '-';

          return <>{name}</>;
        },
      },
      {
        Header: generalMessages.category.defaultMessage,
        accessor: 'Category',
        Cell: ({
          row: {
            original: { Category },
          },
        }) => <>{Category?.name}</>,
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: ({
          row: {
            original: { uid },
          },
        }) => (
          <>
            <BsTrashFill
              onClick={() => onDelete(uid)}
              color={COLORS.RedButton}
              style={{ cursor: 'pointer' }}
            />
          </>
        ),
      },
    ],
    [wallets]
  );

  return {
    columns,
  };
};

export default useScheduledColumns;
