import React from 'react';
import PropTypes from 'prop-types';

import Icon from './styles/Icon';

const BankIcon = ({ icon, alt, onSelected, selected, value }) => (
  <Icon
    src={icon}
    alt={alt}
    onClick={() => onSelected(value)}
    selected={value === selected}
  />
);

BankIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.string,
};

BankIcon.defaultProps = {
  selected: false,
};

export default BankIcon;
