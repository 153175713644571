import getAllCategoriesTypes from 'services/api/categories/getAllCategoriesTypes';
import addUserExpense from 'services/api/expenses/addUserExpense';
import getExpenses from 'services/api/expenses/getExpenses';
import createLimit from 'services/api/recommendations/categoryLimits/createLimit';
import deleteLimitDetail from 'services/api/recommendations/categoryLimits/deleteLimitDetail';
import getLimit from 'services/api/recommendations/categoryLimits/getLimit';
import updateLimit from 'services/api/recommendations/categoryLimits/updateLimit';
import generateReports from 'services/api/reports/generateReports';

import {
  EXPENSES_ADD_EXPENSE_INIT,
  EXPENSES_ADD_EXPENSE_SUCCESS,
  EXPENSES_ADD_EXPENSE_FAIL,
  EXPENSES_GET_ALL_CATEGORIES_INIT,
  EXPENSES_GET_ALL_CATEGORIES_SUCCESS,
  EXPENSES_GET_ALL_CATEGORIES_FAIL,
  EXPENSES_GET_ALL_TRANSACTIONS_INIT,
  EXPENSES_GET_ALL_TRANSACTIONS_SUCCESS,
  EXPENSES_GET_ALL_TRANSACTIONS_FAIL,
  EXPENSES_CLEAN_UP,
  EXPENSES_GET_USER_REPORTS_INIT,
  EXPENSES_GET_USER_REPORTS_SUCCESS,
  EXPENSES_GET_USER_REPORTS_FAIL,
  EXPENSES_GET_MONTHLY_LIMITS_FAIL,
  EXPENSES_GET_MONTHLY_LIMITS_SUCCESS,
  EXPENSES_GET_MONTHLY_LIMITS_INIT,
  EXPENSES_UPDATE_MONTHLY_LIMITS_SUCCESS,
  EXPENSES_UPDATE_MONTHLY_LIMITS_FAIL,
  EXPENSES_UPDATE_MONTHLY_LIMITS_INIT,
  EXPENSES_DELETE_MONTHLY_LIMITS_INIT,
  EXPENSES_DELETE_MONTHLY_LIMITS_SUCCESS,
  EXPENSES_DELETE_MONTHLY_LIMITS_FAIL,
  EXPENSES_CREATE_MONTHLY_LIMITS_INIT,
  EXPENSES_CREATE_MONTHLY_LIMITS_SUCCESS,
  EXPENSES_CREATE_MONTHLY_LIMITS_FAIL,
} from 'state/actionCreators/expenses';

export const getAllCategories = () => async (dispatch, getState) => {
  dispatch(EXPENSES_GET_ALL_CATEGORIES_INIT());

  const { token } = getState().auth;

  try {
    const categories = await getAllCategoriesTypes(token);

    return dispatch(EXPENSES_GET_ALL_CATEGORIES_SUCCESS({ categories }));
  } catch (error) {
    return dispatch(
      EXPENSES_GET_ALL_CATEGORIES_FAIL({ error: error.response.data.message })
    );
  }
};

export const addExpense = (data) => async (dispatch, getState) => {
  dispatch(EXPENSES_ADD_EXPENSE_INIT());

  const { token } = getState().auth;

  try {
    const uid = await addUserExpense(data, token);
    return dispatch(EXPENSES_ADD_EXPENSE_SUCCESS({ uid }));
  } catch (error) {
    return dispatch(
      EXPENSES_ADD_EXPENSE_FAIL({ error: error.response.data.message })
    );
  }
};

export const getAllExpenses = (params) => async (dispatch, getState) => {
  dispatch(EXPENSES_GET_ALL_TRANSACTIONS_INIT());

  const { token } = getState().auth;

  try {
    const transactions = await getExpenses(params, token);

    return dispatch(EXPENSES_GET_ALL_TRANSACTIONS_SUCCESS({ transactions }));
  } catch (error) {
    return dispatch(
      EXPENSES_GET_ALL_TRANSACTIONS_FAIL({ error: error.response.data.message })
    );
  }
};

export const getUserReports = (params) => async (dispatch, getState) => {
  dispatch(EXPENSES_GET_USER_REPORTS_INIT());

  const { token } = getState().auth;

  try {
    const reports = await generateReports(params, token);

    return dispatch(EXPENSES_GET_USER_REPORTS_SUCCESS({ reports }));
  } catch (error) {
    return dispatch(
      EXPENSES_GET_USER_REPORTS_FAIL({ error: error.response.data.message })
    );
  }
};

export const expensesCleanUp = () => EXPENSES_CLEAN_UP();

export const getMonhlyLimits = () => async (dispatch, getState) => {
  dispatch(EXPENSES_GET_MONTHLY_LIMITS_INIT());

  const { token } = getState().auth;

  try {
    const monthlyLimits = await getLimit(token);

    return dispatch(EXPENSES_GET_MONTHLY_LIMITS_SUCCESS({ monthlyLimits }));
  } catch (error) {
    return dispatch(
      EXPENSES_GET_MONTHLY_LIMITS_FAIL({ error: error.response.data.message })
    );
  }
};

export const updateMonthlyLimit = (data) => async (dispatch, getState) => {
  dispatch(EXPENSES_UPDATE_MONTHLY_LIMITS_INIT());

  const { token } = getState().auth;

  try {
    await updateLimit(data, token);

    return dispatch(EXPENSES_UPDATE_MONTHLY_LIMITS_SUCCESS());
  } catch (error) {
    return dispatch(
      EXPENSES_UPDATE_MONTHLY_LIMITS_FAIL({
        error: error.response.data.message,
      })
    );
  }
};

export const deleteMonthlyLimit = (uid) => async (dispatch, getState) => {
  dispatch(EXPENSES_DELETE_MONTHLY_LIMITS_INIT());

  const { token } = getState().auth;

  try {
    await deleteLimitDetail(uid, token);

    return dispatch(EXPENSES_DELETE_MONTHLY_LIMITS_SUCCESS({ uid }));
  } catch (error) {
    return dispatch(
      EXPENSES_DELETE_MONTHLY_LIMITS_FAIL({
        error: error.response.data.message,
      })
    );
  }
};

export const createMonthlyLimit = (data) => async (dispatch, getState) => {
  dispatch(EXPENSES_CREATE_MONTHLY_LIMITS_INIT());

  const { token } = getState().auth;

  try {
    await createLimit(data, token);

    return dispatch(EXPENSES_CREATE_MONTHLY_LIMITS_SUCCESS());
  } catch (error) {
    return dispatch(
      EXPENSES_CREATE_MONTHLY_LIMITS_FAIL({
        error: error.response.data.message,
      })
    );
  }
};
