import styled, { css } from 'styled-components';
import { COLORS } from 'resources/colors';

export const Size = Object.freeze({
  Default: 'default',
  L: 'size-large',
  M: 'size-medium',
  S: 'size-small',
});

const SizeStyles = {
  [Size.Default]: css`
    font-size: 1.2rem;
    line-height: 1.23rem;
  `,
  [Size.S]: css`
    font-size: 0.75rem;
    line-height: 1rem;
  `,
  [Size.M]: css`
    font-size: 1rem;
    line-height: 2rem;
  `,
  [Size.L]: css`
    font-size: 2rem;
    line-height: 3rem;
  `,
};

const Text = styled.span`
  font-family: 'Poppins';
  font-weight: 400;
  ${({ isClickable }) => isClickable && `cursor: pointer;`}
  ${({ size }) => (size ? SizeStyles[size] : SizeStyles[Size.Default])}};
  color: ${({ color }) => color ?? COLORS.GreyText};
  color: ${({ dark }) => dark && COLORS.DarkBlue};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => textAlign};
`;

export default Text;
