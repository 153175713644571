/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { COLORS } from 'resources/colors';

import Label from 'components/Label';
import ErrorMessage from 'components/ErrorMessage.js';
import Select from '../SelectInput';

import Container from './styles/Container';
import GroupForm from './styles/GroupForm';

export const LabelPosition = {
  Top: 'top',
  Bottom: 'bottom',
  Left: 'left',
  Right: 'right',
};

export const LabelColor = {
  Grey: 'grey',
  DarkBlue: 'darkBlue',
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: `1px solid ${COLORS.GreyBorder}`,
    padding: '0.125rem 0 0.125rem 0.875rem',
    borderRadius: '0.5rem',
  }),

  container: (provided) => ({
    ...provided,
    paddingLeft: '0.75px',
    width: '100%',
  }),

  singleValue: (provided) => ({
    ...provided,
    color: COLORS.DarkBlue,
  }),

  valueContainer: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    padding: '0',
  }),

  placeholder: (provided) => ({
    ...provided,
    color: COLORS.GreyText,
  }),
};

const SelectFormControl = ({
  name,
  label,
  options,
  placeholder,
  indicatorSeparator,
  controlClassName,
  control,
  errors,
  ...rest
}) => (
  <GroupForm>
    <Container>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Controller
        name={name}
        defaultValue=""
        control={control}
        render={({ field, onChange, value, ref }) => (
          <Select
            name={name}
            {...field}
            aria-label={name}
            inputRef={ref}
            options={options}
            placeholder={placeholder}
            value={options.find((c) => c.value === value)}
            onChange={(val) => onChange(val.value)}
            styles={customStyles}
            components={{
              ...(!indicatorSeparator && { IndicatorSeparator: () => null }),
            }}
            className={controlClassName}
            {...rest}
          />
        )}
      />
      {errors[name] && <ErrorMessage>{errors[name]?.message}</ErrorMessage>}
    </Container>
  </GroupForm>
);

SelectFormControl.defaultProps = {
  errors: {},
  validations: {},
  label: null,
  className: '',
  labelColor: LabelColor.Grey,
  labelPosition: LabelPosition.Top,
  placeholder: 'Select...',
  indicatorSeparator: false,
  controlClassName: '',
};

SelectFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validations: PropTypes.shape({}),
  className: PropTypes.string,
  labelColor: PropTypes.oneOf(Object.values(LabelColor)),
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  indicatorSeparator: PropTypes.bool,
  errors: PropTypes.object,
  controlClassName: PropTypes.string,
};

export default SelectFormControl;
