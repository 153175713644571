import React, { forwardRef } from 'react';
import Button from 'components/Button';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './DatePicker.module.scss';

registerLocale('es', es);

const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
  <Button onClick={onClick} ref={ref}>
    Seleccionar fecha: {value}
  </Button>
));

const DatePicker = (props) => (
  <ReactDatePicker
    {...props}
    customInput={<CustomDatePicker />}
    wrapperClassName={classes.wrapper}
    locale="es"
  />
);

CustomDatePicker.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  value: '',
  onClick: () => {},
};

export default DatePicker;
