import React, { forwardRef } from 'react';
import Button from 'components/Button';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

import classes from './DatePicker.module.scss';

registerLocale('es', es);

const CustomRangePicker = forwardRef(({ value, onClick }, ref) => (
  <Button onClick={onClick} ref={ref}>
    Seleccionar rango: {value}
  </Button>
));

const RangePicker = ({ startDate, endDate, ...otherProps }) => (
  <ReactDatePicker
    startDate={startDate}
    endDate={endDate}
    customInput={<CustomRangePicker />}
    wrapperClassName={classes.wrapper}
    dateFormat="dd/MM/yyyy"
    locale="es"
    {...otherProps}
  />
);

CustomRangePicker.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

CustomRangePicker.defaultProps = {
  value: '',
  onClick: () => {},
};

RangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

RangePicker.defaultProps = {
  startDate: new Date(),
  endDate: new Date(),
};

export default RangePicker;
