/* eslint-disable import/prefer-default-export */

import { generalMessages } from 'utils/generalMessages';
import { SIZES } from 'utils/constant';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';

export const profileInputs = [
  {
    type: 'text',
    placeholder: generalMessages.name.defaultMessage,
    label: generalMessages.name.defaultMessage,
    size: SIZES.MEDIUM,
    icon: <BsPerson />,
    fieldName: generalMessages.name.fieldName,
  },
  {
    type: 'email',
    placeholder: generalMessages.email.defaultMessage,
    label: generalMessages.email.defaultMessage,
    size: SIZES.MEDIUM,
    icon: <MdOutlineEmail />,
    fieldName: generalMessages.email.fieldName,
    disabled: true,
  },
];
