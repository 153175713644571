import { COLORS } from 'resources/colors';
import { MEDIA } from 'resources/layout';
import styled from 'styled-components';

const UploadContainer = styled.div`
  font-size: 0.875rem;
  line-height: 0.875rem;

  u {
    color: ${COLORS.SkyBlue};
  }

  ${MEDIA.md`
    display: inline;

    span {
      display: inline-block;
    }
  `}
`;

export default UploadContainer;
