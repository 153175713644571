import ForgotPasswordForm from 'components/Common/Forms/ForgotPasswordForm';
import Container from 'pages/Login/styles/Container';
import withNonAuthenticatedLayout from 'components/hocs/withNonAuthenticatedLayout';
import Path from 'enums/path.enum';
import { messages } from './messages';

const ForgotPassword = () => (
  <Container>
    <ForgotPasswordForm
      title={messages.forgotPassword.defaultMessage}
      returnPath={Path.Login}
    />
  </Container>
);

export default withNonAuthenticatedLayout(ForgotPassword);
