const users = [
  {
    id: '1',
    name: 'Frank',
    isAdmin: true,
  },
];

const fetchUserById = (userId) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const user = users.find(({ id }) => id === userId);

      if (user) {
        return resolve(user);
      }

      return reject(new Error('User not found!'));
    }, 1500);
  });

export default fetchUserById;
