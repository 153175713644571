import { COLORS } from 'resources/colors';
import styled from 'styled-components';

const AppsLink = styled.div`
  position: absolute;
  bottom: 0;
  left: ${(props) => (props.open ? '30%' : '16%')};

  svg {
    cursor: pointer;
    color: ${COLORS.GreyText};
    width: 40px;
    height: 40px;
    :hover {
      color: ${COLORS.SkyBlue};
    }
  }
`;

export default AppsLink;
