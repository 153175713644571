export const scope = 'app.pages.createWallet';

export const messages = {
  yourData: {
    id: `${scope}.yourData`,
    defaultMessage: 'Ingrese los datos de la billetera',
  },
  wallet: {
    id: `${scope}.wallet`,
    defaultMessage: 'Crear Billetera',
  },
  createWallet: {
    id: `${scope}.createWallet`,
    defaultMessage: 'Crear billetera',
  },
};
