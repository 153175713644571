const lapseOptions = [
  {
    value: 'NEVER',
    label: 'Nunca',
  },
  {
    value: 'DAILY',
    label: 'Diario',
  },
  {
    value: 'WEEKLY',
    label: 'Semanal',
  },
  {
    value: 'MONTHLY',
    label: 'Mensual',
  },
];

export default lapseOptions;
