import { createSelector } from '@reduxjs/toolkit';

const selectTransactionsState = (state) => state.scheduled;

export const selecCreateScheduledTransaction = createSelector(
  selectTransactionsState,
  ({ creatingTransaction, errorTransactionCreate, isTransactionCreated }) => ({
    loading: creatingTransaction,
    error: errorTransactionCreate,
    success: isTransactionCreated,
  })
);

export const selecUserTransactionsState = createSelector(
  selectTransactionsState,
  ({
    fetchingUserTransaction,
    deletingTransaction,
    errorFetchingUserTransaction,
    errorDeletingTransaction,
    transactions,
    isTransactionDeleted,
  }) => ({
    loading: fetchingUserTransaction || deletingTransaction,
    error: errorFetchingUserTransaction ?? errorDeletingTransaction,
    transactions,
    isTransactionDeleted,
  })
);
