import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Form from 'components/Form';
import PhoneInputWithLabel from 'components/PhoneInputWithLabel';
import Image, { SizeImage } from 'components/Image';

import useErrorToast from 'hooks/useErrorToast';

import { deleteUser, fetchUserLogged, updateUser } from 'state/actions/users';
import { selectTokenUser } from 'state/selectors/auth';
import { selectLoggedUser } from 'state/selectors/users';
import { CLEAN_STATE_DELETE } from 'state/actionCreators/auth';
import { CLEAN_STATE_DELETE_USER } from 'state/actionCreators/users';

import dispatchAction from 'datadog/utils';
import {
  DELETE_PROFILE_ACTION,
  UPDATE_PROFILE_ACTION,
} from 'datadog/actions/Profile';

import { SIZES } from 'utils/constant';
import ProfileImage from 'images/Profile.svg';
import { profileInputs } from 'utils/pages/profile/fields';
import { isOnlyCountryCode } from 'utils/validations';

import InputWithLabel from 'components/InputWithLabel';
import Button, { ColorsButton } from 'components/Button';
import FlexContainer from 'components/FlexContainer';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import Text, { Size } from 'components/Text';
import Loading from 'components/Loading';
import Card from 'components/Card';
import Link from 'components/Link';
import Path from 'enums/path.enum';

import { FiPhone } from 'react-icons/fi';

import { generalMessages } from 'utils/generalMessages';
import { messages } from './messages';

const updateProfileSchema = yup.object({
  name: yup.string().required(generalMessages.required.defaultMessage),
  phone: yup.string(),
});

const resolver = yupResolver(updateProfileSchema);

const handleUpdateProfile = ({ phone, name }) => {
  const phoneModified = phone.replaceAll(/\s/g, '');
  const isCountryCode = isOnlyCountryCode(phone, phoneModified);

  const profileData = {
    name,
    ...(!isCountryCode ? { phoneNumber: phoneModified } : {}),
  };

  return profileData;
};

const Profile = () => {
  const dispatch = useDispatch();

  const token = useSelector(selectTokenUser, shallowEqual);
  const { loadingUser, errorUser, user, deleteUserAction } = useSelector(
    selectLoggedUser,
    shallowEqual
  );
  useErrorToast(errorUser);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm({
    mode: 'all',
    resolver,
    defaultValues: useMemo(
      () => ({
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
      }),
      [user]
    ),
  });

  useEffect(() => {
    if (token) dispatch(fetchUserLogged(token));
  }, [token]);

  const onSubmit = (data) => {
    const profileData = handleUpdateProfile(data);
    dispatch(updateUser(profileData, token));
    dispatchAction(UPDATE_PROFILE_ACTION, profileData);
  };

  const handleDeleteUser = () => {
    dispatch(deleteUser(token));
    dispatchAction(DELETE_PROFILE_ACTION, token);
  };

  useEffect(() => {
    reset(user);
  }, [user]);

  useEffect(() => {
    if (deleteUserAction) {
      dispatch(CLEAN_STATE_DELETE());
      dispatch(CLEAN_STATE_DELETE_USER());
    }
  }, [deleteUserAction]);

  return (
    <>
      {loadingUser && <Loading />}
      <Card>
        <Form size={SIZES.MEDIUM} onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer column gap={15} middle="xs">
            <Image src={ProfileImage} size={SizeImage.L} />
            <FlexContainer column gap={5} middle="xs">
              <Title color={ColorsTitle.Dark} size={SizeTitle.S}>
                {messages.profile.defaultMessage}
              </Title>
              <Text size={Size.M}>{messages.yourData.defaultMessage}</Text>
            </FlexContainer>
          </FlexContainer>
          {profileInputs.map((values) => (
            <InputWithLabel
              {...values}
              register={register}
              errors={errors}
              key={values.fieldName}
            />
          ))}
          <PhoneInputWithLabel
            label={generalMessages.phone.defaultMessage}
            regions="america"
            icon={<FiPhone />}
            size={SIZES.MEDIUM}
            register={register}
            fieldName={generalMessages.phone.fieldName}
            getValues={getValues}
            value={user?.phoneNumber}
          />
          <Button fullWidth disabled={!isValid}>
            {messages.updateProfile.defaultMessage}
          </Button>
          <Button
            fullWidth
            color={ColorsButton.Delete}
            onClick={handleDeleteUser}
            type="button"
          >
            {messages.deleteProfile.defaultMessage}
          </Button>
          <FlexContainer column textAlign="center">
            <Text dark size={Size.M}>
              {messages.resetPassword.defaultMessage}&nbsp;
              <Link size={Size.M} to={Path.ResetPassword}>
                {messages.reset.defaultMessage}
              </Link>
            </Text>
          </FlexContainer>
        </Form>
      </Card>
    </>
  );
};

export default Profile;
