import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const signInWithEmail = async ({ email, password }) => {
  const auth = getAuth();

  const {
    user: { uid },
  } = await signInWithEmailAndPassword(auth, email, password);

  return uid;
};

export default signInWithEmail;
