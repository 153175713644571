const getApiQueryParams = (params) => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    queryParams.append(key, value);
  });

  return queryParams;
};

export default getApiQueryParams;
