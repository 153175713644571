import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { selecUserWalletsState } from 'state/selectors/wallets';
import { expensesCleanUp, getAllExpenses } from 'state/actions/expenses';
import { getUserWallets, walletsCleanUp } from 'state/actions/wallets';

import FlexContainer from 'components/FlexContainer';
import Text from 'components/Text';
import Table from 'components/Table';
import Link from 'components/Link';

import useErrorToast from 'hooks/useErrorToast';
import Loading from 'components/Loading';
import Title, { SizeTitle } from 'components/Title';
import Path from 'enums/path.enum';
import { selectTransactionData } from 'state/selectors/expenses';
import SelectInput from 'components/SelectInput';
import useTransactionsColumns from 'hooks/useTransactionsColumns';

import DatePicker from 'components/DatePicker';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserWallets());
  }, []);

  const [date, setDate] = useState(new Date());
  const [wallet, setWallet] = useState();

  useEffect(() => {
    dispatch(getAllExpenses({ date: dayjs(date).format('MM-YYYY'), wallet }));
  }, [date, wallet]);

  useEffect(() => () => dispatch(expensesCleanUp()), []);
  useEffect(() => () => dispatch(walletsCleanUp()), []);

  const {
    loading: loadingWallets,
    optionWallets,
    error: walletsError,
  } = useSelector(selecUserWalletsState, shallowEqual);

  const { loading, error, transactions } = useSelector(
    selectTransactionData,
    shallowEqual
  );

  const { columns } = useTransactionsColumns();

  useErrorToast(error);

  useErrorToast(walletsError);

  return (
    <>
      {(loading || loadingWallets) && <Loading />}
      <FlexContainer middle="xs" between="xs" padding="0 2rem">
        <Title size={SizeTitle.M}>Transacciones</Title>
        <Link to={Path.AddExpense}>Nueva transacción</Link>
      </FlexContainer>
      <FlexContainer between="xs" padding="2rem" gap={32}>
        <DatePicker
          selected={date}
          onChange={(value) => setDate(value)}
          dateFormat="MM-yyyy"
          showMonthYearPicker
        />
        <SelectInput
          options={optionWallets}
          onChange={(option) => {
            setWallet(option ? option.value : null);
          }}
          placeholder="Seleccionar billetera"
          isClearable
          isSearchable
        />
      </FlexContainer>
      <FlexContainer padding="2rem" column gap={32} middle="xs">
        {transactions?.length > 0 ? (
          <Table columns={columns} data={transactions} />
        ) : (
          <>
            <Text>No hay transacciones disponibles</Text>
          </>
        )}
      </FlexContainer>
    </>
  );
};

export default Home;
