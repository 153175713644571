import { MEDIA } from 'resources/layout';
import styled, { css } from 'styled-components';

export const SizeImage = Object.freeze({
  Default: 'default',
  XL: 'extra-large',
  L: 'size-large',
  M: 'size-medium',
  S: 'size-small',
});

const SizeStyles = {
  [SizeImage.Default]: css`
    width: 32px;
    height: 32px;
  `,
  [SizeImage.S]: css`
    width: 16px;
    height: 16px;
  `,
  [SizeImage.M]: css`
    width: 64px;
    height: 64px;
  `,
  [SizeImage.L]: css`
    width: 128px;
    height: 128px;
  `,
  [SizeImage.XL]: css`
    width: 64px;
    height: 64px;

    ${MEDIA.sm`
    width: 128px;
    height: 128px;
    `}

    ${MEDIA.md`
    width: 256px;
    height: 256px;
    `}
  `,
};

const Image = styled.img`
  ${({ size }) => (size ? SizeStyles[size] : SizeStyles[SizeImage.Default])}
  ${({ isClickable }) => isClickable && `cursor: pointer;`}
  c${({ isRounded }) => isRounded && `border-radius: 50%`}
`;

export default Image;
