import { createAction } from '@reduxjs/toolkit';

export const WALLETS_GET_WALLET_TYPES_INIT = createAction(
  'WALLETS_GET_WALLET_TYPES_INIT'
);
export const WALLETS_GET_WALLET_TYPES_SUCCESS = createAction(
  'WALLETS_GET_WALLET_TYPES_SUCCESS'
);
export const WALLETS_GET_WALLET_TYPES_FAIL = createAction(
  'WALLETS_GET_WALLET_TYPES_FAIL'
);

export const WALLETS_CREATE_WALLET_INIT = createAction(
  'WALLETS_CREATE_WALLET_INIT'
);
export const WALLETS_CREATE_WALLET_SUCCESS = createAction(
  'WALLETS_CREATE_WALLET_SUCCESS'
);
export const WALLETS_CREATE_WALLET_FAIL = createAction(
  'WALLETS_CREATE_WALLET_FAIL'
);

export const WALLETS_GET_USER_WALLETS_INIT = createAction(
  'WALLETS_GET_USER_WALLETS_INIT'
);
export const WALLETS_GET_USER_WALLETS_SUCCESS = createAction(
  'WALLETS_GET_USER_WALLETS_SUCCESS'
);
export const WALLETS_GET_USER_WALLETS_FAIL = createAction(
  'WALLETS_GET_USER_WALLETS_FAIL'
);

export const WALLETS_DELETE_WALLETS_INIT = createAction(
  'WALLETS_DELETE_WALLETS_INIT'
);
export const WALLETS_DELETE_WALLETS_SUCCESS = createAction(
  'WALLETS_DELETE_WALLETS_SUCCESS'
);
export const WALLETS_DELETE_WALLETS_FAIL = createAction(
  'WALLETS_GET_USER_WALLETS_FAIL'
);

export const WALLETS_CLEAN_UP = createAction('WALLETS_CLEAN_UP');
