import { createAction } from '@reduxjs/toolkit';

export const SCHEDULED_GET_TRANSACTION_INIT = createAction(
  'SCHEDULED_GET_TRANSACTION_INIT'
);
export const SCHEDULED_GET_TRANSACTION_SUCCESS = createAction(
  'SCHEDULED_GET_TRANSACTION_SUCCESS'
);
export const SCHEDULED_GET_TRANSACTION_FAIL = createAction(
  'SCHEDULED_GET_TRANSACTION_FAIL'
);

export const SCHEDULED_GET_ALL_TRANSACTIONS_INIT = createAction(
  'SCHEDULED_GET_ALL_TRANSACTIONS_INIT'
);
export const SCHEDULED_GET_ALL_TRANSACTIONS_SUCCESS = createAction(
  'SCHEDULED_GET_ALL_TRANSACTIONS_SUCCESS'
);
export const SCHEDULED_GET_ALL_TRANSACTIONS_FAIL = createAction(
  'SCHEDULED_GET_ALL_TRANSACTIONS_FAIL'
);

export const SCHEDULED_CREATE_TRANSACTION_INIT = createAction(
  'SCHEDULED_CREATE_TRANSACTION_INIT'
);
export const SCHEDULED_CREATE_TRANSACTION_SUCCESS = createAction(
  'SCHEDULED_CREATE_TRANSACTION_SUCCESS'
);
export const SCHEDULED_CREATE_TRANSACTION_FAIL = createAction(
  'SCHEDULED_CREATE_TRANSACTION_FAIL'
);

export const SCHEDULED_DELETE_TRANSACTION_INIT = createAction(
  'SCHEDULED_DELETE_TRANSACTION_INIT'
);
export const SCHEDULED_DELETE_TRANSACTION_SUCCESS = createAction(
  'SCHEDULED_DELETE_TRANSACTION_SUCCESS'
);
export const SCHEDULED_DELETE_TRANSACTION_FAIL = createAction(
  'SCHEDULED_DELETE_TRANSACTION_FAIL'
);

export const SCHEDULED_UPDATE_TRANSACTION_INIT = createAction(
  'SCHEDULED_UPDATE_TRANSACTION_INIT'
);
export const SCHEDULED_UPDATE_TRANSACTION_SUCCESS = createAction(
  'SCHEDULED_UPDATE_TRANSACTION_SUCCESS'
);
export const SCHEDULED_UPDATE_TRANSACTION_FAIL = createAction(
  'SCHEDULED_UPDATE_TRANSACTION_FAIL'
);

export const SCHEDULED_CLEANUP = createAction('SCHEDULED_CLEANUP');
