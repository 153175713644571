import getNotifications from 'services/api/recommendations/notifications/getNotifications';
import updateNotifications from 'services/api/recommendations/notifications/updateNotifications';
import {
  NOTIFICATIONS_CLEAN_UP,
  NOTIFICATIONS_GET_NOTIFICATIONS_FAIL,
  NOTIFICATIONS_GET_NOTIFICATIONS_INIT,
  NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_UPDATE_NOTIFICATIONS_FAIL,
  NOTIFICATIONS_UPDATE_NOTIFICATIONS_INIT,
  NOTIFICATIONS_UPDATE_NOTIFICATIONS_SUCCESS,
} from 'state/actionCreators/notifications';

export const getUserNoficiations = () => async (dispatch, getState) => {
  dispatch(NOTIFICATIONS_GET_NOTIFICATIONS_INIT());

  const { token } = getState().auth;

  try {
    const notifications = await getNotifications(token);
    return dispatch(NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS({ notifications }));
  } catch (error) {
    return dispatch(
      NOTIFICATIONS_GET_NOTIFICATIONS_FAIL({
        error: error.response.data.message,
      })
    );
  }
};

export const updateUserNotifications = (notifications) => async (
  dispatch,
  getState
) => {
  dispatch(NOTIFICATIONS_UPDATE_NOTIFICATIONS_INIT());

  const { token } = getState().auth;

  try {
    await updateNotifications(notifications, token);
    return dispatch(NOTIFICATIONS_UPDATE_NOTIFICATIONS_SUCCESS());
  } catch (error) {
    return dispatch(
      NOTIFICATIONS_UPDATE_NOTIFICATIONS_FAIL({
        error: error.response.data.message,
      })
    );
  }
};

export const cleanUpNotifications = () => NOTIFICATIONS_CLEAN_UP();
