import styled from 'styled-components';

const StyledInput = styled.input`
  margin: 0;
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
`;

export default StyledInput;
