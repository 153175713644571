import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  cleanUp,
  createPersonalInformation,
  deletePersonalInformation,
  getPersonalInformation,
  updatePersonalInformation,
} from 'state/actions/personalInformation';
import useErrorToast from 'hooks/useErrorToast';

import FlexContainer from 'components/FlexContainer';
import Form from 'components/Form';
import Loading from 'components/Loading';
import { SIZES } from 'utils/constant';
import Button, { ColorsButton } from 'components/Button';
import Image, { SizeImage } from 'components/Image';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import ProfileImage from 'images/PersonalData.svg';
import { generalMessages } from 'utils/generalMessages';
import Card from 'components/Card';
import { selectPersonalInformation } from 'state/selectors/personalInformation';

import useSuccessToast from 'hooks/useSuccessToast';
import Text, { Size } from 'components/Text';
import personalDataFields from './inputFields';

const dataSchema = yup.object({
  age: yup
    .number()
    .min(1, `${generalMessages.greaterThan.defaultMessage} 1`)
    .typeError(generalMessages.required.defaultMessage)
    .required(generalMessages.required.defaultMessage),
  sex: yup.string().required(generalMessages.required.defaultMessage),
  target: yup.string().required(generalMessages.required.defaultMessage),
  marital_status: yup
    .string()
    .required(generalMessages.required.defaultMessage),
  tenure_status: yup.string().required(generalMessages.required.defaultMessage),
  monthly_income: yup
    .number()
    .min(1, `${generalMessages.greaterThan.defaultMessage} 1`)
    .typeError(generalMessages.required.defaultMessage)
    .required(generalMessages.required.defaultMessage),
  family_members_less_5_years_old: yup
    .number()
    .min(0, `${generalMessages.greaterThan.defaultMessage} 0`)
    .typeError(generalMessages.required.defaultMessage)
    .required(generalMessages.required.defaultMessage),
  family_members_between_5_17_years_old: yup
    .number()
    .min(0, `${generalMessages.greaterThan.defaultMessage} 0`)
    .typeError(generalMessages.required.defaultMessage)
    .required(generalMessages.required.defaultMessage),
  family_members_employed: yup
    .number()
    .min(0, `${generalMessages.greaterThan.defaultMessage} 0`)
    .typeError(generalMessages.required.defaultMessage)
    .required(generalMessages.required.defaultMessage),
});

const resolver = yupResolver(dataSchema);

const PersonalInformation = () => {
  const dispatch = useDispatch();

  const {
    isCreated,
    isDeleted,
    data,
    error,
    isLoading,
    isUpdated,
  } = useSelector(selectPersonalInformation, shallowEqual);

  useEffect(() => {
    dispatch(getPersonalInformation());

    return () => {
      dispatch(cleanUp());
    };
  }, []);

  useErrorToast(error);

  useSuccessToast(isCreated, 'Datos personales creados correctamente');
  useSuccessToast(isUpdated, 'Datos personales actualizados correctamente');
  useSuccessToast(isDeleted, 'Datos personales borrados correctamente');

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    resolver,
    defaultValues: useMemo(() => data ?? {}, []),
  });

  useEffect(() => {
    if (isDeleted) {
      reset({});
    }
  }, [isDeleted]);

  const fields = personalDataFields({ register, control, errors });

  const onSubmit = (personalData) => {
    if (!data) {
      dispatch(createPersonalInformation(personalData));
    } else {
      dispatch(updatePersonalInformation(personalData));
    }
  };

  const onDeleteInformationHandler = () => {
    dispatch(deletePersonalInformation());
  };

  return (
    <>
      {isLoading && <Loading />}
      <Card>
        <Form size={SIZES.MEDIUM} onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer column gap={15} middle="xs">
            <Image src={ProfileImage} size={SizeImage.L} />
            <FlexContainer column gap={14} middle="xs">
              <Title color={ColorsTitle.Dark} size={SizeTitle.M}>
                Datos Personales
              </Title>
              <Text size={Size.M} maxWidth="360px">
                A fin de mes te enviaremos un mail con recomendaciones de ahorro
                comparandote con usuarios similares. Para usar esta
                funcionalidad necesitamos que nos cuentes un poco más de ti.
              </Text>
              <Text size={Size.M} maxWidth="360px">
                Tus datos solo se usarán para este propósito y puede borrarlos
                cuando quieras.
              </Text>
            </FlexContainer>
            {fields.map(({ component: Component, ...values }) => (
              <Component {...values} key={values.fieldName} />
            ))}
          </FlexContainer>
          <Button type="submit" fullWidth>
            Guardar
          </Button>
          <Button
            type="button"
            fullWidth
            color={ColorsButton.Delete}
            onClick={onDeleteInformationHandler}
          >
            Borrar
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default PersonalInformation;
