import { GoogleAuthProvider } from 'firebase/auth';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authWithSocialMedia } from 'state/actions/auth';

import firebaseError from 'utils/common/firebaseError';
import useErrorToast from './useErrorToast';

const useProvidersConfig = () => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useErrorToast(error);

  return {
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        dispatch(authWithSocialMedia(authResult));
      },
      signInFailure: (signInEror) => {
        const signInErrorMessage = firebaseError(signInEror.code);
        setError(signInErrorMessage);
      },
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/home',
    signInOptions: [
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        fullLabel: 'Continuar con Google',
        scopes: ['https://www.googleapis.com/auth/userinfo.email'],
      },
    ],
  };
};

export default useProvidersConfig;
