import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiFillQuestionCircle,
  AiFillApple,
  AiFillAndroid,
} from 'react-icons/ai';

import FlexContainer from 'components/FlexContainer';
import Image from 'components/Image';
import Text from 'components/Text';
import { COLORS } from 'resources/colors';
import Logo from 'images/Logo.svg';
import Path from 'enums/path.enum';
import ChildrenContainer from '../NavItem/styles/ChildrenContainer';

import NavItem from '../NavItem';
import navItems from './navItems';

import Arrow from './styles/Arrow';
import Aside from './styles/Aside';
import AppsLink from './styles/AppsLink';

const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { push } = useHistory();

  const onToggleSideBar = () => setIsOpen((prevState) => !prevState);

  return (
    <Aside open={isOpen}>
      <Arrow active={isOpen} onClick={onToggleSideBar} />
      <FlexContainer
        middle="xs"
        gap={8}
        noWrap
        margin="0 0 32px 0"
        padding="0 0 0 17px"
        style={{ overflow: 'hidden' }}
      >
        <Image
          src={Logo}
          alt="logo"
          isClickable
          onClick={() => push(Path.Home)}
        />
        <Text
          color={COLORS.PrimaryBlue}
          isClickable={isOpen}
          onClick={() => {
            if (isOpen) {
              push(Path.Home);
            }
          }}
        >
          <ChildrenContainer isSmall={!isOpen}> Ahorranza</ChildrenContainer>
        </Text>
      </FlexContainer>
      <NavItem.Container>
        {navItems.map(({ icon, text, path }) => (
          <NavItem
            key={text}
            isSmall={!isOpen}
            icon={icon}
            isActive={window.location.pathname === path}
            onClick={() => push(path)}
          >
            {text}
          </NavItem>
        ))}
      </NavItem.Container>
      <AppsLink open={isOpen}>
        <FlexContainer column gap={16} middle="xs">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfcxl5ZYVi7FlP33e-Wz8mnJGpTVu6M9s4r8bqXcDQVbw83wg/viewform"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillQuestionCircle />
          </a>
          <div>
            <a
              href="https://testflight.apple.com/join/jZRx0qcO"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillApple />
            </a>
            <a
              href="https://appdistribution.firebase.dev/i/651daa3efa5dc461"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillAndroid />
            </a>
          </div>
        </FlexContainer>
      </AppsLink>
    </Aside>
  );
};

export default SideBar;
