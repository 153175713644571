import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  label {
    margin-bottom: 0.75rem;
  }
`;

export default Container;
