import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import Card from 'components/Card';
import Form from 'components/Form';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Text, { Size } from 'components/Text';
import useErrorToast from 'hooks/useErrorToast';
import useSuccessToast from 'hooks/useSuccessToast';
import Image, { SizeImage } from 'components/Image';
import FlexContainer from 'components/FlexContainer';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import { generalMessages } from 'utils/generalMessages';
import getAddExpenseInputs from 'utils/pages/addExpense';
import { DATE_FORMAT, SIZES } from 'utils/constant';
import ExpenseImage from 'images/Expense.svg';
import Path from 'enums/path.enum';
import lapseOptions from 'utils/common/lapseOptions';

import {
  addExpense,
  expensesCleanUp,
  getAllCategories,
} from 'state/actions/expenses';
import { selectAddExpenseState } from 'state/selectors/expenses';
import { selecUserWalletsState } from 'state/selectors/wallets';
import { getUserWallets } from 'state/actions/wallets';
import { addScheduledTransaction } from 'state/actions/scheduled';

import dispatchAction from 'datadog/utils';
import ADD_TRANSACTION_ACTION from 'datadog/actions/Transaction';

import { selecCreateScheduledTransaction } from 'state/selectors/scheduled';
import { messages } from './messages';

const addExpenseSchema = yupResolver(
  yup.object({
    [generalMessages.date.fieldName]: yup
      .date()
      .typeError(generalMessages.invalidFormat.defaultMessage)
      .required(generalMessages.required.defaultMessage),
    walletId: yup.string().required(generalMessages.required.defaultMessage),
    categoryId: yup.string().required(generalMessages.required.defaultMessage),
    [generalMessages.amount.fieldName]: yup
      .number()
      .min(1, `${generalMessages.greaterThan.defaultMessage} 1`)
      .typeError(generalMessages.required.defaultMessage)
      .required(generalMessages.required.defaultMessage),
    [generalMessages.description.fieldName]: yup.string(),
    [generalMessages.lapse.fieldName]: yup.string(),
  })
);

const AddExpense = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const { register, control, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      [generalMessages.lapse.fieldName]: lapseOptions[0].value,
    },
    resolver: addExpenseSchema,
  });

  const { loading, success, error, categories } = useSelector(
    selectAddExpenseState,
    shallowEqual
  );

  const { success: createTransactionSuccess } = useSelector(
    selecCreateScheduledTransaction,
    shallowEqual
  );

  const {
    loading: fetchingWallets,
    error: errorFetchingWallets,
    optionWallets,
  } = useSelector(selecUserWalletsState, shallowEqual);

  useErrorToast(error);

  useErrorToast(errorFetchingWallets);

  useSuccessToast(success, `Transacción ingresada correctamente`);
  useSuccessToast(
    createTransactionSuccess,
    `Transacción programada correctamente`
  );

  useEffect(() => {
    if (success) {
      push(Path.Home);
    }
  }, [success]);

  useEffect(() => {
    if (createTransactionSuccess) {
      push(Path.Scheduled);
    }
  }, [createTransactionSuccess]);

  useEffect(() => {
    dispatch(getUserWallets());
    dispatch(getAllCategories());
  }, []);

  useEffect(
    () => () => {
      dispatch(expensesCleanUp());
    },
    []
  );

  const onSubmitHandler = ({
    [generalMessages.lapse.fieldName]: repeat,
    [generalMessages.description.fieldName]: description,
    ...data
  }) => {
    const date = dayjs(data.date).format(DATE_FORMAT);

    if (repeat !== lapseOptions[0].value) {
      dispatch(
        addScheduledTransaction({ ...data, date, repeat, title: description })
      );
    } else {
      dispatch(addExpense({ ...data, description, date }));
    }
    dispatchAction(ADD_TRANSACTION_ACTION, data);
  };

  const inputFields = getAddExpenseInputs({
    errors,
    register,
    control,
    categories,
    wallets: optionWallets,
  });

  return (
    <>
      {(loading || fetchingWallets) && <Loading />}
      <Card>
        <Form size={SIZES.MEDIUM} onSubmit={handleSubmit(onSubmitHandler)}>
          <FlexContainer column gap={15} middle="xs">
            <Image src={ExpenseImage} size={SizeImage.L} />
            <FlexContainer column gap={5} middle="xs">
              <Title color={ColorsTitle.Dark} size={SizeTitle.S}>
                {messages.expenseTitle.defaultMessage}
              </Title>
              <Text size={Size.M}>{messages.expenseData.defaultMessage}</Text>
            </FlexContainer>
          </FlexContainer>
          {inputFields.map(({ component: Component, ...values }) => (
            <Component {...values} key={values.fieldName} />
          ))}
          <Button fullWidth>{messages.addExpense.defaultMessage}</Button>
        </Form>
      </Card>
    </>
  );
};

export default AddExpense;
