import { getAuth } from 'firebase/auth';

import { cleanUserSignOut } from 'state/actions/users';
import logout from 'services/auth/logout';
import signInWithEmail from 'services/auth/signInWithEmail';
import signUpEmailPassword from 'services/api/signup/email-password';
import recoverPassword from 'services/auth/recoverPassword';
import signUpProviders from 'services/api/signup/providers';
import getUserLogged from 'services/api/getUser';
import firebaseError from 'utils/common/firebaseError';

import {
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_INIT,
  AUTH_LOGIN_SUCCESS,
  AUTH_PROVIDER_FAIL,
  AUTH_PROVIDER_INIT,
  AUTH_PROVIDER_SUCCESS,
  AUTH_SIGNOUT_FAIL,
  AUTH_SIGNOUT_INIT,
  AUTH_SIGNOUT_SUCCESS,
  SIGN_UP_INIT,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  CLEAN_STATE,
  AUTH_CLEAN_STATE,
  AUTH_RESET_PASSWORD_INIT,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
} from 'state/actionCreators/auth';

export const signIn = (userData) => async (dispatch) => {
  dispatch(AUTH_LOGIN_INIT());
  try {
    const uid = await signInWithEmail(userData);
    return dispatch(AUTH_LOGIN_SUCCESS({ uid }));
  } catch (error) {
    const errorMessage = firebaseError(error);
    return dispatch(AUTH_LOGIN_FAIL({ error: errorMessage }));
  }
};

export const signOut = () => async (dispatch) => {
  dispatch(AUTH_SIGNOUT_INIT());

  try {
    await logout();

    dispatch(cleanUserSignOut());

    return dispatch(AUTH_SIGNOUT_SUCCESS());
  } catch (error) {
    const errorMessage = firebaseError(error);
    return dispatch(AUTH_SIGNOUT_FAIL({ error: errorMessage }));
  }
};

export const authWithSocialMedia = ({ user, additionalUserInfo }) => async (
  dispatch
) => {
  dispatch(AUTH_PROVIDER_INIT());
  const { isNewUser } = additionalUserInfo;
  const { uid, email, displayName } = user;

  const signUpData = {
    email,
    name: displayName,
  };

  try {
    let userData;
    if (isNewUser) {
      const { data } = await signUpProviders(uid, signUpData);

      userData = data;
    } else {
      const token = await getAuth().currentUser.getIdToken();

      const { data } = await getUserLogged(token);

      userData = data;
    }

    return dispatch(AUTH_PROVIDER_SUCCESS({ uid, data: { ...userData } }));
  } catch (error) {
    const errorMessage = firebaseError(error.code);
    return dispatch(AUTH_PROVIDER_FAIL({ error: errorMessage }));
  }
};

export const signUp = (registerData) => async (dispatch) => {
  dispatch(SIGN_UP_INIT());
  try {
    const { data } = await signUpEmailPassword(registerData);
    dispatch(SIGN_UP_SUCCESS({ data }));
    return dispatch(
      signIn({ email: registerData.email, password: registerData.password })
    );
  } catch (error) {
    return dispatch(SIGN_UP_FAIL({ error: error.response.data.message }));
  }
};

export const resetPassword = (userEmail) => async (dispatch) => {
  dispatch(AUTH_RESET_PASSWORD_INIT());

  try {
    await recoverPassword(userEmail);

    return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
  } catch (error) {
    const errorMessage = firebaseError(error);
    return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
  }
};

export const cleanResetPassword = () => AUTH_CLEAN_STATE();

export const cleanAllState = () => CLEAN_STATE();

export const authCleanState = () => AUTH_CLEAN_STATE();
