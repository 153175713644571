/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import classNames from 'classnames';

import classes from './Table.module.scss';

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()} className={classes.table} data-testid="table">
      <thead className={classes.thead}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className={classes.header}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={column.style}
                className={classes.column}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={classes.tbody}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={classes.dataRow}>
              {row.cells.map((cell, index) => (
                <td
                  {...cell.getCellProps()}
                  className={classNames(classes.column, classes.columnData)}
                >
                  {headerGroups[0].headers[index].Header.length !== 0 && (
                    <strong className={classes.dataHeader}>
                      {headerGroups[0].headers[index].render('Header')}
                    </strong>
                  )}
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      Cell: PropTypes.func,
      sortType: PropTypes.string,
      id: PropTypes.string,
      disableSortBy: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Table;
