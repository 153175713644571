import ApiPath from 'enums/apiPath.enum';
import { axiosInstance } from 'services/api/axios';

const getExpenses = async ({ date, wallet }, token) => {
  const walletParam = wallet ? `&wallet=${wallet}` : '';

  const params = `?date=${date}${walletParam}`;

  const { data } = await axiosInstance.get(
    `${ApiPath.Transactions}/${params}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  let response = [];

  data.forEach(({ transactions }) => {
    response = [...response, ...transactions];
  });

  return response;
};

export default getExpenses;
