import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from 'resources/colors';

const SocialLogo = styled(Link)`
  color: ${COLORS.Black};
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export default SocialLogo;
