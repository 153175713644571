import { MEDIA } from 'resources/layout';
import styled from 'styled-components';

const DeleteContainer = styled.div`
  display: flex;
  justify-content: end;

  ${MEDIA.lg`
    justify-content: center;
  `}
`;
export default DeleteContainer;
