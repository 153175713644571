import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import dispatchAction from 'datadog/utils';
import { CREATE_WALLET_ACTION } from 'datadog/actions/Wallets';

import Card from 'components/Card';
import Form from 'components/Form';
import FlexContainer from 'components/FlexContainer';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import SelectFormControl from 'components/SelectFormControl';
import Image, { SizeImage } from 'components/Image';
import Text, { Size } from 'components/Text';
import { generalMessages } from 'utils/generalMessages';
import InputWithLabel from 'components/InputWithLabel';
import walletInputs from 'utils/pages/createWallet';
import Button from 'components/Button';
import WalletImage from 'images/Wallet.svg';
import { MIN_BALANCE, SIZES } from 'utils/constant';
import Loading from 'components/Loading';
import useErrorToast from 'hooks/useErrorToast';
import useSuccessToast from 'hooks/useSuccessToast';
import badges from 'utils/common/badges';
import Path from 'enums/path.enum';

import {
  createWallet,
  getWalletTypes,
  walletsCleanUp,
} from 'state/actions/wallets';
import {
  selecCreateWalletState,
  selectWalletTypes,
} from 'state/selectors/wallets';

import { messages } from './messages';

const createWalletSchema = yupResolver(
  yup.object({
    name: yup.string().required(generalMessages.required.defaultMessage),
    balance: yup
      .number()
      .min(MIN_BALANCE)
      .required(generalMessages.required.defaultMessage),
    currency: yup.string().required(generalMessages.required.defaultMessage),
    walletTypeId: yup
      .string()
      .required(generalMessages.required.defaultMessage),
  })
);

const CreateWallet = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: createWalletSchema,
  });

  const { types, loading: loadingTypes, error: errorTypes } = useSelector(
    selectWalletTypes,
    shallowEqual
  );
  const { loading, success, error } = useSelector(
    selecCreateWalletState,
    shallowEqual
  );

  useErrorToast(error);
  useErrorToast(errorTypes);

  useSuccessToast(success, `Billetera creada exitosamente`);

  useEffect(() => {
    if (success) {
      push(Path.Wallets);
    }
  }, [success]);

  useEffect(() => {
    dispatch(getWalletTypes());
  }, []);

  useEffect(
    () => () => {
      dispatch(walletsCleanUp());
    },
    []
  );

  const onSubmitHandler = (data) => {
    dispatch(createWallet(data));
    dispatchAction(CREATE_WALLET_ACTION, data);
  };

  return (
    <>
      {loadingTypes || (loading && <Loading />)}
      <Card>
        <Form size={SIZES.MEDIUM} onSubmit={handleSubmit(onSubmitHandler)}>
          <FlexContainer column gap={15} middle="xs">
            <Image src={WalletImage} size={SizeImage.M} />
            <FlexContainer column gap={5} middle="xs">
              <Title color={ColorsTitle.Dark} size={SizeTitle.S}>
                {messages.wallet.defaultMessage}
              </Title>
              <Text size={Size.M}>{messages.yourData.defaultMessage}</Text>
            </FlexContainer>
          </FlexContainer>
          {walletInputs.map((values) => (
            <InputWithLabel
              {...values}
              register={register}
              errors={errors}
              key={values.fieldName}
            />
          ))}
          <SelectFormControl
            options={badges}
            name={generalMessages.currency.fieldName}
            control={control}
            label={generalMessages.currency.defaultMessage}
            placeholder={generalMessages.currency.defaultMessage}
          />
          <SelectFormControl
            options={types}
            name={generalMessages.sourceOfFunds.fieldName}
            control={control}
            label={generalMessages.sourceOfFunds.defaultMessage}
            placeholder={generalMessages.sourceOfFunds.defaultMessage}
          />
          <Button fullWidth disabled={!isValid}>
            {messages.createWallet.defaultMessage}
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default CreateWallet;
