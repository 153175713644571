import createWalletService from 'services/api/wallets/createWallet';
import deleteWallet from 'services/api/wallets/deleteWallet';
import getUserWalletsService from 'services/api/wallets/getUserWallets';
import getWalletTypesService from 'services/api/wallets/getWalletTypes';

import {
  WALLETS_CLEAN_UP,
  WALLETS_CREATE_WALLET_FAIL,
  WALLETS_CREATE_WALLET_INIT,
  WALLETS_CREATE_WALLET_SUCCESS,
  WALLETS_DELETE_WALLETS_FAIL,
  WALLETS_DELETE_WALLETS_INIT,
  WALLETS_DELETE_WALLETS_SUCCESS,
  WALLETS_GET_USER_WALLETS_FAIL,
  WALLETS_GET_USER_WALLETS_INIT,
  WALLETS_GET_USER_WALLETS_SUCCESS,
  WALLETS_GET_WALLET_TYPES_FAIL,
  WALLETS_GET_WALLET_TYPES_INIT,
  WALLETS_GET_WALLET_TYPES_SUCCESS,
} from 'state/actionCreators/wallets';

export const getWalletTypes = () => async (dispatch, getState) => {
  dispatch(WALLETS_GET_WALLET_TYPES_INIT());

  const { token } = getState().auth;

  try {
    const types = await getWalletTypesService(token);

    return dispatch(WALLETS_GET_WALLET_TYPES_SUCCESS({ types }));
  } catch (error) {
    return dispatch(
      WALLETS_GET_WALLET_TYPES_FAIL({ error: error.response.data.message })
    );
  }
};

export const createWallet = (data) => async (dispatch, getState) => {
  dispatch(WALLETS_CREATE_WALLET_INIT());

  const { token } = getState().auth;

  try {
    await createWalletService(data, token);

    return dispatch(WALLETS_CREATE_WALLET_SUCCESS());
  } catch (error) {
    return dispatch(
      WALLETS_CREATE_WALLET_FAIL({ error: error.response.data.message })
    );
  }
};

export const removeWallet = (walletId) => async (dispatch, getState) => {
  dispatch(WALLETS_DELETE_WALLETS_INIT());

  const { token } = getState().auth;

  try {
    await deleteWallet(walletId, token);

    return dispatch(WALLETS_DELETE_WALLETS_SUCCESS({ walletId }));
  } catch (error) {
    return dispatch(
      WALLETS_DELETE_WALLETS_FAIL({ error: error.response.data.message })
    );
  }
};

export const getUserWallets = () => async (dispatch, getState) => {
  dispatch(WALLETS_GET_USER_WALLETS_INIT());

  const { token } = getState().auth;

  try {
    const wallets = await getUserWalletsService(token);

    return dispatch(WALLETS_GET_USER_WALLETS_SUCCESS({ wallets }));
  } catch (error) {
    return dispatch(
      WALLETS_GET_USER_WALLETS_FAIL({ error: error.response.data.message })
    );
  }
};

export const walletsCleanUp = () => WALLETS_CLEAN_UP();
