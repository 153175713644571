import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { updateMonthlyLimit } from 'state/actions/expenses';
import { selectMonthlyLimitData } from 'state/selectors/expenses';

import MonthLimitForm from 'components/Forms/MonthLimitForm';
import { generalMessages } from 'utils/generalMessages';

const UpdateMonthlyLimit = () => {
  const dispatch = useDispatch();
  const index = window.location.pathname.split('/').pop();

  const onSubmit = (values) => {
    dispatch(updateMonthlyLimit(values));
  };

  const { data } = useSelector(selectMonthlyLimitData, shallowEqual);

  const { amount, category } = data[index];

  const value = {
    [generalMessages.amount.fieldName]: amount,
    categoryId: category.uid,
  };

  return (
    <>
      <MonthLimitForm onSubmit={onSubmit} isEditing values={value} />
    </>
  );
};

export default UpdateMonthlyLimit;
