import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { deleteMonthlyLimit, getMonhlyLimits } from 'state/actions/expenses';

import FlexContainer from 'components/FlexContainer';
import Link from 'components/Link';
import Loading from 'components/Loading';
import Table from 'components/Table';
import Title, { SizeTitle } from 'components/Title';
import Path from 'enums/path.enum';

import { selectMonthlyLimitData } from 'state/selectors/expenses';
import useErrorToast from 'hooks/useErrorToast';
import { useHistory } from 'react-router-dom';
import { SIZES } from 'utils/constant';
import Text from 'components/Text';
import Button from 'components/Button';
import useLimitsColumns from './useLimitsColumns';

const MonthlyLimits = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    dispatch(getMonhlyLimits());
  }, []);

  const onEdit = (uid) => {
    push(`${Path.MonthlyLimits}/${uid}`);
  };

  const onDelete = (uid) => {
    dispatch(deleteMonthlyLimit(uid));
  };

  const { data, error, loading } = useSelector(
    selectMonthlyLimitData,
    shallowEqual
  );

  useErrorToast(error);

  const { columns } = useLimitsColumns({ onEdit, onDelete });

  return (
    <>
      {loading && <Loading />}
      <FlexContainer middle="xs" between="xs" padding="0 2rem">
        <Title size={SizeTitle.M}>Límites mensuales</Title>
        <Link to={Path.NewMonthlyLimit}>Nuevo límite</Link>
      </FlexContainer>
      {data?.length ? (
        <FlexContainer padding="2rem" gap={32} middle="xs">
          <Table data={data} columns={columns} />
        </FlexContainer>
      ) : (
        <FlexContainer padding="2rem" gap={32} middle="xs" column>
          <Text size={SIZES.M}>
            Configura límites mensuales para cada categoría para que sea más
            fácil controlar tus gastos
          </Text>
          <Button onClick={() => push(Path.NewMonthlyLimit)}>Agregar</Button>
        </FlexContainer>
      )}
    </>
  );
};

export default MonthlyLimits;
