const getCategoriesOptions = (categories) => {
  let options = [];

  categories?.forEach(({ name, uid, subCategories }) => {
    const fatherOption = {
      label: name,
      value: uid,
    };

    const childrenOptions = subCategories.map(
      ({ name: childName, uid: childId }) => ({
        label: `- ${childName}`,
        value: childId,
      })
    );

    const categoryOptions = [fatherOption, ...childrenOptions];

    options = [...options, ...categoryOptions];
  });

  return options;
};

export default getCategoriesOptions;
