import { COLORS } from 'resources/colors';
import { MEDIA } from 'resources/layout';
import styled from 'styled-components';

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.FileContainer};
  border: 1px dashed ${COLORS.ContainerBorder};
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  flex-direction: column;
  padding: 1rem 0;
  width: 100%;

  :hover {
    opacity: 0.75;
  }

  ${MEDIA.tb`
    width: 400px;
  `}

  ${MEDIA.md`
    padding: 2rem 8rem 1.5rem 8rem;
    width: 600px;
  `}

  ${(props) =>
    props.error &&
    `
    border-color: ${COLORS.RedError};

    div {
        color: s${COLORS.RedError};
    }
  `}

  ${(props) =>
    props.disabled &&
    `
     border-color: black;
     color: ${COLORS.GreyText};;
     background-color: ${COLORS.GreyButton};;
     cursor: not-allowed;
  `}

  .img {
    margin-bottom: 1rem;
    width: 3.75rem;
  }
`;

export default FileContainer;
