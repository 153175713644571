import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth } from 'firebase/auth';

import PropTypes from 'prop-types';

import { SAVE_TOKEN } from 'state/actionCreators/auth';
import { datadogRum } from '@datadog/browser-rum';

const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        datadogRum.setUser({
          id: user.uid,
          name: user.displayName,
          email: user.email,
        });
        const token = await user?.getIdToken();
        dispatch(SAVE_TOKEN({ token }));
      } else {
        dispatch(SAVE_TOKEN({ token: null }));
      }
    });
    return () => unsubscribe();
  }, []);

  return <>{children}</>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
