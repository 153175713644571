const Path = {
  Home: '/',
  Users: '/users',
  Reports: '/reports',
  Scheduled: '/scheduled',
  ScheduledDetail: '/scheduled/:id',
  User: '/users/:id',
  Register: '/register',
  Login: '/login',
  Wallets: '/wallets',
  AddExpense: '/expenses/new',
  CreateWallet: '/wallets/new',
  Profile: '/profile',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  TermsAndConditions: '/terms-and-conditions',
  BankStatement: '/bank-statement',
  ConfirmTransactions: '/confirm-transactions',
  PersonalInformation: '/recommendations/personal-information',
  MonthlyLimits: '/monthly-limit',
  NewMonthlyLimit: '/monthly-limit/new',
  UpdateMonthlyLimit: '/monthly-limit/:id',
  Notifications: '/notifications',
};

export default Path;
