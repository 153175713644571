import { createSelector } from '@reduxjs/toolkit';
import getAllCategories from 'utils/common/getAllCategories';

import getCategoriesOptions from 'utils/common/getCategoriesOptions';

const selectExpensesState = (state) => state.expenses;

export const selectCategoriesData = createSelector(
  selectExpensesState,
  ({
    fetchingAllCategories,
    errorFetchingCategories,
    categories: { expenses, earnings },
  }) => {
    const expensesOptions = getCategoriesOptions(expenses);
    const earningsOptions = getCategoriesOptions(earnings);

    const allExpenses = getAllCategories(expenses);
    const allEarnings = getAllCategories(earnings);

    return {
      loading: fetchingAllCategories,
      error: errorFetchingCategories,
      categoriesProcessed: [
        { label: 'Gasto', options: expensesOptions },
        { label: 'Ingreso', options: earningsOptions },
      ],
      categories: allExpenses.concat(allEarnings),
    };
  }
);

export const selectAddExpenseState = createSelector(
  selectExpensesState,
  ({
    fetchingAllCategories,
    errorFetchingCategories,
    addingExpense,
    errorAddingUserExpense,
    expenseAddedSuccessfully,
    categories: { expenses, earnings },
  }) => {
    const expensesOptions = getCategoriesOptions(expenses);
    const earningsOptions = getCategoriesOptions(earnings);

    return {
      loading: addingExpense || fetchingAllCategories,
      error: errorFetchingCategories ?? errorAddingUserExpense,
      success: expenseAddedSuccessfully,
      categories: [
        { label: 'Gasto', options: expensesOptions },
        { label: 'Ingreso', options: earningsOptions },
      ],
    };
  }
);

export const selectTransactionData = createSelector(
  selectExpensesState,
  ({ fetchingAllTransactions, errorFetchingTransactions, transactions }) => ({
    loading: fetchingAllTransactions,
    error: errorFetchingTransactions,
    transactions,
  })
);

export const selectReportsData = createSelector(
  selectExpensesState,
  ({
    fetchingReports,
    errorFetchingReports,
    reports: { total, categories },
  }) => {
    const labels = [];
    const series = [];
    const subcategories = [];

    categories?.forEach((category) => {
      labels.push(category.categoryName);
      series.push(category.total);

      const subcategoriesData = {
        labels: [],
        series: [],
      };

      const categorySubcategories = category?.subCategories;

      if (categorySubcategories) {
        categorySubcategories.forEach((subcategory) => {
          subcategoriesData.labels.push(subcategory.categoryName);
          subcategoriesData.series.push(subcategory.total);
        });

        subcategories.push(subcategoriesData);
      } else {
        subcategories.push(null);
      }
    });

    return {
      loading: fetchingReports,
      error: errorFetchingReports,
      labels,
      series,
      subcategories,
      total: total ?? 0,
    };
  }
);

export const selectMonthlyLimitData = createSelector(
  selectExpensesState,
  ({
    fetchingMonthlyLimit,
    errorDeletingMonthlyLimits,
    deletingMonthlyLimits,
    errorFetchingMonthlyLimit,
    monthlyLimits,
  }) => ({
    loading: fetchingMonthlyLimit || deletingMonthlyLimits,
    error: errorFetchingMonthlyLimit ?? errorDeletingMonthlyLimits,
    data: monthlyLimits,
  })
);

export const selectMonthLimitFormState = createSelector(
  selectExpensesState,
  ({
    fetchingAllCategories,
    updatingMonthlyLimits,
    errorUpdatingMonthlyLimits,
    errorFetchingCategories,
    isMonthlyLimitsUpdated,
    creatingMonthlyLimits,
    errorCreatingMonthlyLimits,
    isMonthlyLimitCreated,
    categories: { expenses },
  }) => {
    const expensesOptions = getCategoriesOptions(expenses);

    return {
      loading:
        fetchingAllCategories || updatingMonthlyLimits || creatingMonthlyLimits,
      error:
        errorFetchingCategories ??
        errorUpdatingMonthlyLimits ??
        errorCreatingMonthlyLimits,
      success: isMonthlyLimitsUpdated || isMonthlyLimitCreated,
      categories: expensesOptions,
    };
  }
);
