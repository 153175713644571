import { MEDIA } from 'resources/layout';
import styled from 'styled-components';

const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 20px auto 0 auto;
  flex-direction: column;

  ${MEDIA.lg`
    flex-direction: row;
  `}
`;

export default SocialMediaWrapper;
