import styled from 'styled-components';

const Icon = styled.img`
  width: 120px;
  padding: 10px;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    `
        box-shadow: inset 0px 0px 0px 3px #3498db;
        border-radius: 5px;
    `}
`;

export default Icon;
