import { COLORS } from 'resources/colors';
import { MEDIA } from 'resources/layout';
import styled, { css } from 'styled-components';

export const SizeSubTitle = Object.freeze({
  Default: 'default',
  L: 'size-large',
  M: 'size-medium',
  S: 'size-small',
});

const SizeStyles = {
  [SizeSubTitle.Default]: css`
    font-size: 1.2rem;
    line-height: 1.23rem;
  `,
  [SizeSubTitle.S]: css`
    font-size: 1.5rem;
    line-height: 2rem;
  `,
  [SizeSubTitle.M]: css`
    font-size: 0.7rem;
    line-height: 1.4rem;

    ${MEDIA.tb`
      font-size: 1rem;
      line-height: 2rem;
    `}
  `,
  [SizeSubTitle.L]: css`
    font-size: 1.4rem;
    line-height: 2.4rem;

    ${MEDIA.sm`
      font-size: 1.8rem;
      line-height: 2.8rem;
    `}

    ${MEDIA.tb`
      font-size: 2.5rem;
      line-height: 3.5rem;
    `}

    ${MEDIA.lg`
      font-size: 3.5rem;
      line-height: 4.5rem;
    `}
  `,
};

export const ColorsSubTitle = Object.freeze({
  Dark: 'dark',
  Grey: 'grey',
});

const ColorStyles = {
  [ColorsSubTitle.Dark]: css`
    color: ${COLORS.DarkBlue};
  `,
  [ColorsSubTitle.Grey]: css`
    color: ${COLORS.GreyText};
  `,
};

const SubTitle = styled.span`
  font-family: 'Poppins';
  font-weight: 400;
  ${(props) => props.size && SizeStyles[props.size]};
  ${(props) => props.color && ColorStyles[props.color]};
`;

export default SubTitle;
