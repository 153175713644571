import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import Table from 'components/Table';
import useScheduledColumns from 'hooks/useScheduledColumns';
import FlexContainer from 'components/FlexContainer';
import Text from 'components/Text';
import Path from 'enums/path.enum';
import Title, { SizeTitle } from 'components/Title';
import Link from 'components/Link';
import {
  cleanScheduledTransaction,
  getAllScheduledTransactions,
  removeScheduledTransaction,
} from 'state/actions/scheduled';
import useErrorToast from 'hooks/useErrorToast';
import { selecUserTransactionsState } from 'state/selectors/scheduled';
import useSuccessToast from 'hooks/useSuccessToast';

const Scheduled = () => {
  const dispatch = useDispatch();

  const onEditTransactionHandler = () => {
    dispatch();
  };

  const onDeleteTransactionHandler = (id) => {
    dispatch(removeScheduledTransaction(id));
  };

  const { columns } = useScheduledColumns({
    onEdit: onEditTransactionHandler,
    onDelete: onDeleteTransactionHandler,
  });

  useEffect(() => {
    dispatch(getAllScheduledTransactions());

    return () => {
      dispatch(cleanScheduledTransaction());
    };
  }, []);

  const { loading, error, transactions, isTransactionDeleted } = useSelector(
    selecUserTransactionsState,
    shallowEqual
  );

  useSuccessToast(isTransactionDeleted, 'Transacción eliminada con éxito');

  useErrorToast(error);

  const isLoading = loading;

  return (
    <>
      {isLoading && <Loading />}
      <FlexContainer middle="xs" between="xs" padding="0 2rem">
        <Title size={SizeTitle.M}>Transacciones programadas</Title>
        <Link to={Path.AddExpense}>Agregar Transacción</Link>
      </FlexContainer>
      <FlexContainer padding="2rem" column gap={32} middle="xs">
        {transactions.length > 0 ? (
          <Table columns={columns} data={transactions} />
        ) : (
          <>
            <Text>No hay transacciones programadas</Text>
          </>
        )}
      </FlexContainer>
    </>
  );
};

export default Scheduled;
