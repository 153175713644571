/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  SCHEDULED_CLEANUP,
  SCHEDULED_CREATE_TRANSACTION_FAIL,
  SCHEDULED_CREATE_TRANSACTION_INIT,
  SCHEDULED_CREATE_TRANSACTION_SUCCESS,
  SCHEDULED_DELETE_TRANSACTION_FAIL,
  SCHEDULED_DELETE_TRANSACTION_INIT,
  SCHEDULED_DELETE_TRANSACTION_SUCCESS,
  SCHEDULED_GET_ALL_TRANSACTIONS_FAIL,
  SCHEDULED_GET_ALL_TRANSACTIONS_INIT,
  SCHEDULED_GET_ALL_TRANSACTIONS_SUCCESS,
  SCHEDULED_GET_TRANSACTION_FAIL,
  SCHEDULED_GET_TRANSACTION_INIT,
  SCHEDULED_GET_TRANSACTION_SUCCESS,
  SCHEDULED_UPDATE_TRANSACTION_FAIL,
  SCHEDULED_UPDATE_TRANSACTION_INIT,
  SCHEDULED_UPDATE_TRANSACTION_SUCCESS,
} from 'state/actionCreators/scheduled';

export const initialState = {
  errorFetchingScheduled: null,
  loadingScheduled: false,
  transactions: [],
  transaction: null,
  deletingTransaction: false,
  errorDeletingTransaction: null,
  isTransactionDeleted: false,
  errorUpdatingTransaction: null,
  updatingTransaction: false,
  isTransactionUpdated: false,
  errorTransactionCreate: null,
  creatingTransaction: false,
  isTransactionCreated: false,
};

const persistConfig = {
  key: 'scheduled',
  storage,
  blacklist: [
    'errorFetchingScheduled',
    'loadingScheduled',
    'deletingTransaction',
    'errorDeletingTransaction',
    'isTransactionDeleted',
    'errorUpdatingTransaction',
    'updatingTransaction',
    'isTransactionUpdated',
    'errorTransactionCreate',
    'creatingTransaction',
    'isTransactionCreated',
  ],
};

const scheduledReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [SCHEDULED_GET_ALL_TRANSACTIONS_INIT]: (state) => {
      state.loadingScheduled = true;
      state.errorFetchingScheduled = null;
    },
    [SCHEDULED_GET_ALL_TRANSACTIONS_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      state.loadingScheduled = false;
      state.errorFetchingScheduled = null;
      state.transactions = data;
    },
    [SCHEDULED_GET_ALL_TRANSACTIONS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingScheduled = false;
      state.errorFetchingScheduled = error;
    },
    [SCHEDULED_GET_TRANSACTION_INIT]: (state) => {
      state.loadingScheduled = true;
      state.errorFetchingScheduled = null;
    },
    [SCHEDULED_GET_TRANSACTION_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      state.loadingScheduled = false;
      state.errorFetchingScheduled = null;
      state.transaction = data;
    },
    [SCHEDULED_GET_TRANSACTION_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingScheduled = false;
      state.errorFetchingScheduled = error;
    },
    [SCHEDULED_DELETE_TRANSACTION_INIT]: (state) => {
      state.deletingTransaction = true;
      state.errorDeletingTransaction = null;
      state.isTransactionDeleted = false;
    },
    [SCHEDULED_DELETE_TRANSACTION_SUCCESS]: (state, { payload }) => {
      state.deletingTransaction = false;
      state.errorDeletingTransaction = null;
      state.isTransactionDeleted = true;
      state.transactions = state.transactions.filter(
        ({ uid }) => uid !== payload.id
      );
    },
    [SCHEDULED_DELETE_TRANSACTION_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.deletingTransaction = false;
      state.errorDeletingTransaction = error;
    },
    [SCHEDULED_UPDATE_TRANSACTION_INIT]: (state) => {
      state.updatingTransaction = true;
      state.errorUpdatingTransaction = null;
      state.isTransactionUpdated = false;
    },
    [SCHEDULED_UPDATE_TRANSACTION_SUCCESS]: (state) => {
      state.updatingTransaction = false;
      state.errorUpdatingTransaction = null;
      state.isTransactionUpdated = true;
    },
    [SCHEDULED_UPDATE_TRANSACTION_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.updatingTransaction = false;
      state.errorUpdatingTransaction = error;
    },
    [SCHEDULED_CREATE_TRANSACTION_INIT]: (state) => {
      state.creatingTransaction = true;
      state.errorTransactionCreate = null;
      state.isTransactionCreated = false;
    },
    [SCHEDULED_CREATE_TRANSACTION_SUCCESS]: (state) => {
      state.creatingTransaction = false;
      state.errorTransactionCreate = null;
      state.isTransactionCreated = true;
    },
    [SCHEDULED_CREATE_TRANSACTION_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.creatingTransaction = false;
      state.errorTransactionCreate = error;
    },
    [SCHEDULED_CLEANUP]: (state) => {
      state.errorDeletingTransaction = null;
      state.isTransactionDeleted = false;
      state.errorUpdatingTransaction = null;
      state.isTransactionUpdated = false;
      state.errorTransactionCreate = null;
      state.isTransactionCreated = false;
      state.creatingTransaction = false;
      state.updatingTransaction = false;
      state.deletingTransaction = false;
    },
  })
);

export default scheduledReducer;
