import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FaMoneyBillWaveAlt } from 'react-icons/fa';

import Form from 'components/Form';
import Card from 'components/Card';
import { SIZES } from 'utils/constant';
import FlexContainer from 'components/FlexContainer';
import Image, { SizeImage } from 'components/Image';
import Title, { ColorsTitle, SizeTitle } from 'components/Title';
import InputWithLabel from 'components/InputWithLabel';
import SelectFormControl from 'components/SelectFormControl';
import Button from 'components/Button';
import LimitsImage from 'images/Limits.svg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectMonthLimitFormState } from 'state/selectors/expenses';
import useErrorToast from 'hooks/useErrorToast';
import Loading from 'components/Loading';
import { expensesCleanUp, getAllCategories } from 'state/actions/expenses';
import { useHistory } from 'react-router-dom';
import Path from 'enums/path.enum';
import { generalMessages } from 'utils/generalMessages';
import useSuccessToast from 'hooks/useSuccessToast';

const monthLimitSchema = yupResolver(
  yup.object({
    [generalMessages.amount.fieldName]: yup
      .number()
      .min(1, `${generalMessages.greaterThan.defaultMessage} 1`)
      .typeError(generalMessages.required.defaultMessage)
      .required(generalMessages.required.defaultMessage),
    categoryId: yup.string().required(generalMessages.required.defaultMessage),
  })
);

const MonthLimitForm = ({ onSubmit, values, isEditing }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const onSubmitHandler = (data) => {
    onSubmit(data);
  };

  const { register, control, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: values,
    resolver: monthLimitSchema,
  });

  const { categories, error, loading, success } = useSelector(
    selectMonthLimitFormState,
    shallowEqual
  );

  useErrorToast(error);

  useEffect(() => {
    dispatch(getAllCategories());

    return () => {
      dispatch(expensesCleanUp());
    };
  }, []);

  useSuccessToast(
    success,
    isEditing ? 'Límite editado correctamente' : 'Límite creado correctamente'
  );

  useEffect(() => {
    if (success) {
      push(Path.MonthlyLimits);
    }
  }, [success]);

  return (
    <>
      {loading && <Loading />}
      <Card>
        <Form size={SIZES.MEDIUM} onSubmit={handleSubmit(onSubmitHandler)}>
          <FlexContainer column gap={15} middle="xs">
            <Image src={LimitsImage} size={SizeImage.XL} />
            <FlexContainer column gap={5} middle="xs">
              <Title color={ColorsTitle.Dark} size={SizeTitle.S}>
                {isEditing ? 'Editar' : 'Nuevo'} límite mensual
              </Title>
            </FlexContainer>
          </FlexContainer>
          <SelectFormControl
            errors={errors}
            options={categories}
            name="categoryId"
            control={control}
            label="Categoría"
          />
          <InputWithLabel
            errors={errors}
            fieldName={generalMessages.amount.fieldName}
            type="number"
            register={register}
            label="Monto"
            placeholder="Monto"
            icon={<FaMoneyBillWaveAlt />}
          />
          <Button fullWidth>
            {isEditing ? 'Editar' : 'Crear'} límite mensual
          </Button>
        </Form>
      </Card>
    </>
  );
};

MonthLimitForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    [generalMessages.amount.fieldName]: PropTypes.number,
    categoryId: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
};

MonthLimitForm.defaultProps = {
  values: {
    [generalMessages.amount.fieldName]: null,
    categoryId: '',
  },
  isEditing: false,
};

export default MonthLimitForm;
