import ApiPath from 'enums/apiPath.enum';
import { axiosInstance } from 'services/api/axios';

const getWalletTypes = async (token) => {
  const { data } = await axiosInstance.get(ApiPath.WalletTypes, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

export default getWalletTypes;
