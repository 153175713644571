/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  PERSONAL_INFORMATION_CLEAN_UP,
  PERSONAL_INFORMATION_CREATE_FAIL,
  PERSONAL_INFORMATION_CREATE_INIT,
  PERSONAL_INFORMATION_CREATE_SUCCESS,
  PERSONAL_INFORMATION_DELETE_FAIL,
  PERSONAL_INFORMATION_DELETE_INIT,
  PERSONAL_INFORMATION_DELETE_SUCCESS,
  PERSONAL_INFORMATION_GET_FAIL,
  PERSONAL_INFORMATION_GET_INIT,
  PERSONAL_INFORMATION_GET_SUCCESS,
  PERSONAL_INFORMATION_UPDATE_FAIL,
  PERSONAL_INFORMATION_UPDATE_INIT,
  PERSONAL_INFORMATION_UPDATE_SUCCESS,
} from 'state/actionCreators/personalInformation';

export const initialState = {
  errorFetchingPersonalInformation: null,
  loadingPersonalInformation: false,
  deletingPersonalInformation: false,
  errorDeletingPersonalInformation: null,
  isPersonalInformationDeleted: false,
  errorUpdatingPersonalInformation: null,
  updatingPersonalInformation: false,
  isPersonalInformationUpdated: false,
  errorPersonalInformationCreate: null,
  creatingPersonalInformation: false,
  isPersonalInformationCreated: false,
  data: null,
};

const persistConfig = {
  key: 'personalInformation',
  storage,
  blacklist: [
    'errorFetchingPersonalInformation',
    'loadingPersonalInformation',
    'deletingPersonalInformation',
    'errorDeletingPersonalInformation',
    'isPersonalInformationDeleted',
    'errorUpdatingPersonalInformation',
    'updatingPersonalInformation',
    'isPersonalInformationUpdated',
    'errorPersonalInformationCreate',
    'creatingPersonalInformation',
    'isPersonalInformationCreated',
  ],
};

const personalInformationReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [PERSONAL_INFORMATION_GET_INIT]: (state) => {
      state.loadingPersonalInformation = true;
      state.errorFetchingPersonalInformation = null;
    },
    [PERSONAL_INFORMATION_GET_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      state.loadingPersonalInformation = false;
      state.data = data;
    },
    [PERSONAL_INFORMATION_GET_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingPersonalInformation = false;
      state.errorFetchingPersonalInformation = error;
    },
    [PERSONAL_INFORMATION_CREATE_INIT]: (state) => {
      state.creatingPersonalInformation = true;
      state.errorPersonalInformationCreate = null;
    },
    [PERSONAL_INFORMATION_CREATE_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      state.creatingPersonalInformation = false;
      state.isPersonalInformationCreated = true;
      state.data = data;
    },
    [PERSONAL_INFORMATION_CREATE_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.creatingPersonalInformation = false;
      state.errorPersonalInformationCreate = error;
    },
    [PERSONAL_INFORMATION_UPDATE_INIT]: (state) => {
      state.updatingPersonalInformation = true;
      state.errorUpdatingPersonalInformation = null;
    },
    [PERSONAL_INFORMATION_UPDATE_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      state.updatingPersonalInformation = false;
      state.isPersonalInformationUpdated = true;
      state.data = data;
    },
    [PERSONAL_INFORMATION_UPDATE_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.updatingPersonalInformation = false;
      state.errorUpdatingPersonalInformation = error;
    },
    [PERSONAL_INFORMATION_DELETE_INIT]: (state) => {
      state.deletingPersonalInformation = true;
      state.errorDeletingPersonalInformation = null;
    },
    [PERSONAL_INFORMATION_DELETE_SUCCESS]: (state) => {
      state.deletingPersonalInformation = false;
      state.isPersonalInformationDeleted = true;
      state.data = null;
    },
    [PERSONAL_INFORMATION_DELETE_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.deletingPersonalInformation = false;
      state.errorDeletingPersonalInformation = error;
    },
    [PERSONAL_INFORMATION_CLEAN_UP]: (state) => {
      state.errorFetchingPersonalInformation = null;
      state.loadingPersonalInformation = false;
      state.deletingPersonalInformation = false;
      state.errorDeletingPersonalInformation = null;
      state.isPersonalInformationDeleted = false;
      state.errorUpdatingPersonalInformation = null;
      state.updatingPersonalInformation = false;
      state.isPersonalInformationUpdated = false;
      state.errorPersonalInformationCreate = null;
      state.creatingPersonalInformation = false;
      state.isPersonalInformationCreated = false;
    },
  })
);

export default personalInformationReducer;
