/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  USERS_FETCH_USERS_INIT,
  USERS_FETCH_USERS_SUCCESS,
  USERS_FETCH_USERS_FAIL,
  USERS_FETCH_USER_BY_ID_INIT,
  USERS_FETCH_USER_BY_ID_SUCCESS,
  USERS_FETCH_USER_BY_ID_FAIL,
  USERS_CLEAN_SIGNOUT,
  FETCH_USER_LOGGED_INIT,
  FETCH_USER_LOGGED_SUCCESS,
  FETCH_USER_LOGGED_FAIL,
  UPDATE_USER_LOGGED_INIT,
  UPDATE_USER_LOGGED_SUCCESS,
  UPDATE_USER_LOGGED_FAIL,
  DELETE_USER_LOGGED_INIT,
  DELETE_USER_LOGGED_SUCCESS,
  DELETE_USER_LOGGED_FAIL,
  CLEAN_STATE_DELETE_USER,
} from 'state/actionCreators/users';

export const initialState = {
  loading: false,
  data: [],
  error: null,
  loadingFetchUser: false,
  errorFetchUser: null,
  loadingUpdate: false,
  errorUpdate: null,
  user: {},
};

const persistConfig = {
  key: 'users',
  storage,
  blacklist: [
    'loading',
    'error',
    'loadingFetchUser',
    'errorFetchUser',
    'errorUpdate',
    'loadingUpdate',
    'loadingUpdate',
    'errorUpdate',
    'loadingDelete',
    'errorDelete',
    'deleteUser',
  ],
};

const usersReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [USERS_FETCH_USERS_INIT]: (state) => {
      state.loading = true;
    },
    [USERS_FETCH_USERS_SUCCESS]: (state, { payload }) => {
      const { users } = payload;
      state.loading = false;
      state.error = null;
      state.data = users;
    },
    [USERS_FETCH_USERS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loading = false;
      state.error = error;
    },
    [USERS_FETCH_USER_BY_ID_INIT]: (state) => {
      state.loading = true;
    },
    [USERS_FETCH_USER_BY_ID_SUCCESS]: (state, { payload }) => {
      const { user } = payload;
      state.loading = false;
      state.error = null;
      state.data = [user];
    },
    [USERS_FETCH_USER_BY_ID_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loading = false;
      state.error = error;
    },
    [USERS_CLEAN_SIGNOUT]: (state) => {
      state.loading = false;
      state.error = null;
      state.data = [];
    },
    [FETCH_USER_LOGGED_INIT]: (state) => {
      state.loadingFetchUser = true;
      state.errorFetchUser = null;
    },
    [FETCH_USER_LOGGED_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      state.loadingFetchUser = false;
      state.errorFetchUser = null;
      state.user = data;
    },
    [FETCH_USER_LOGGED_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingFetchUser = false;
      state.errorFetchUser = error;
    },
    [UPDATE_USER_LOGGED_INIT]: (state) => {
      state.loadingUpdate = true;
      state.errorUpdate = null;
    },
    [UPDATE_USER_LOGGED_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      state.loadingUpdate = false;
      state.errorUpdate = null;
      state.user = data;
    },
    [UPDATE_USER_LOGGED_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingUpdate = false;
      state.errorUpdate = error;
    },
    [DELETE_USER_LOGGED_INIT]: (state) => {
      state.loadingDelete = true;
      state.errorDelete = null;
      state.deleteUser = null;
    },
    [DELETE_USER_LOGGED_SUCCESS]: (state) => {
      state.loadingDelete = false;
      state.errorDelete = null;
      state.deleteUser = true;
      state.user = null;
      state.data = null;
    },
    [DELETE_USER_LOGGED_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loadingDelete = false;
      state.errorDelete = error;
      state.deleteUser = null;
    },
    [CLEAN_STATE_DELETE_USER]: (state) => {
      state.loading = false;
      state.error = null;
      state.data = [];
      state.loadingDelete = false;
      state.errorDelete = null;
      state.deleteUser = false;
      state.user = null;
      state.loadingFetchUser = false;
      state.errorFetchUser = null;
      state.loadingUpdate = false;
      state.errorUpdate = null;
    },
  })
);

export default usersReducer;
