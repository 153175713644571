import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchUsers } from 'state/actions/users';
import { selectAllUsers } from 'state/selectors/users';

const Users = () => {
  const dispatch = useDispatch();
  const { allUsers, loading, error } = useSelector(selectAllUsers);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  if (error) {
    return <h1>{error}</h1>;
  }

  return (
    <ul>
      {loading && <h1>Loading...</h1>}
      {allUsers.map(({ name }) => (
        <li key={name}>{name}</li>
      ))}
    </ul>
  );
};

export default Users;
