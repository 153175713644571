import { createAction } from '@reduxjs/toolkit';

export const PERSONAL_INFORMATION_GET_INIT = createAction(
  'PERSONAL_INFORMATION_GET_INIT'
);
export const PERSONAL_INFORMATION_GET_SUCCESS = createAction(
  'PERSONAL_INFORMATION_GET_SUCCESS'
);
export const PERSONAL_INFORMATION_GET_FAIL = createAction(
  'PERSONAL_INFORMATION_GET_FAIL'
);

export const PERSONAL_INFORMATION_UPDATE_INIT = createAction(
  'PERSONAL_INFORMATION_UPDATE_INIT'
);
export const PERSONAL_INFORMATION_UPDATE_SUCCESS = createAction(
  'PERSONAL_INFORMATION_UPDATE_SUCCESS'
);
export const PERSONAL_INFORMATION_UPDATE_FAIL = createAction(
  'PERSONAL_INFORMATION_UPDATE_FAIL'
);

export const PERSONAL_INFORMATION_DELETE_INIT = createAction(
  'PERSONAL_INFORMATION_DELETE_INIT'
);
export const PERSONAL_INFORMATION_DELETE_SUCCESS = createAction(
  'PERSONAL_INFORMATION_DELETE_SUCCESS'
);
export const PERSONAL_INFORMATION_DELETE_FAIL = createAction(
  'PERSONAL_INFORMATION_DELETE_FAIL'
);

export const PERSONAL_INFORMATION_CREATE_INIT = createAction(
  'PERSONAL_INFORMATION_CREATE_INIT'
);
export const PERSONAL_INFORMATION_CREATE_SUCCESS = createAction(
  'PERSONAL_INFORMATION_CREATE_SUCCESS'
);
export const PERSONAL_INFORMATION_CREATE_FAIL = createAction(
  'PERSONAL_INFORMATION_CREATE_FAIL'
);

export const PERSONAL_INFORMATION_CLEAN_UP = createAction(
  'PERSONAL_INFORMATION_CLEAN_UP'
);
