/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  NOTIFICATIONS_CLEAN_UP,
  NOTIFICATIONS_GET_NOTIFICATIONS_FAIL,
  NOTIFICATIONS_GET_NOTIFICATIONS_INIT,
  NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_UPDATE_NOTIFICATIONS_FAIL,
  NOTIFICATIONS_UPDATE_NOTIFICATIONS_INIT,
  NOTIFICATIONS_UPDATE_NOTIFICATIONS_SUCCESS,
} from 'state/actionCreators/notifications';

export const initialState = {
  loading: false,
  error: null,
  success: false,
  notifications: [],
};

const persistConfig = {
  key: 'notifications',
  storage,
  blacklist: ['loading', 'error', 'success'],
};

const notificationsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [NOTIFICATIONS_GET_NOTIFICATIONS_INIT]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS]: (state, { payload }) => {
      const { notifications } = payload;
      state.loading = false;
      state.error = null;
      state.notifications = notifications;
    },
    [NOTIFICATIONS_GET_NOTIFICATIONS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loading = false;
      state.error = error;
    },
    [NOTIFICATIONS_UPDATE_NOTIFICATIONS_INIT]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = true;
    },
    [NOTIFICATIONS_UPDATE_NOTIFICATIONS_SUCCESS]: (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    [NOTIFICATIONS_UPDATE_NOTIFICATIONS_FAIL]: (state, { payload }) => {
      const { error } = payload;
      state.loading = false;
      state.error = error;
    },
    [NOTIFICATIONS_CLEAN_UP]: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  })
);

export default notificationsReducer;
