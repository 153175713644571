import createScheduled from 'services/api/scheduled/collection/createScheduled';
import getAllScheduled from 'services/api/scheduled/collection/getAllScheduled';
import deleteScheduled from 'services/api/scheduled/detail/deleteScheduled';
import getScheduled from 'services/api/scheduled/detail/getScheduled';

import {
  SCHEDULED_CLEANUP,
  SCHEDULED_CREATE_TRANSACTION_FAIL,
  SCHEDULED_CREATE_TRANSACTION_INIT,
  SCHEDULED_CREATE_TRANSACTION_SUCCESS,
  SCHEDULED_DELETE_TRANSACTION_FAIL,
  SCHEDULED_DELETE_TRANSACTION_INIT,
  SCHEDULED_DELETE_TRANSACTION_SUCCESS,
  SCHEDULED_GET_ALL_TRANSACTIONS_FAIL,
  SCHEDULED_GET_ALL_TRANSACTIONS_INIT,
  SCHEDULED_GET_ALL_TRANSACTIONS_SUCCESS,
  SCHEDULED_GET_TRANSACTION_FAIL,
  SCHEDULED_GET_TRANSACTION_INIT,
  SCHEDULED_GET_TRANSACTION_SUCCESS,
} from 'state/actionCreators/scheduled';

export const getAllScheduledTransactions = () => async (dispatch, getState) => {
  dispatch(SCHEDULED_GET_ALL_TRANSACTIONS_INIT());

  const { token } = getState().auth;

  try {
    const data = await getAllScheduled(token);

    return dispatch(SCHEDULED_GET_ALL_TRANSACTIONS_SUCCESS({ data }));
  } catch (error) {
    return dispatch(
      SCHEDULED_GET_ALL_TRANSACTIONS_FAIL({
        error: error.response.data.message,
      })
    );
  }
};

export const getScheduledTransaction = (id) => async (dispatch, getState) => {
  dispatch(SCHEDULED_GET_TRANSACTION_INIT());

  const { token } = getState().auth;

  try {
    const data = await getScheduled(id, token);

    return dispatch(SCHEDULED_GET_TRANSACTION_SUCCESS({ data }));
  } catch (error) {
    return dispatch(
      SCHEDULED_GET_TRANSACTION_FAIL({ error: error.response.data.message })
    );
  }
};

export const removeScheduledTransaction = (id) => async (
  dispatch,
  getState
) => {
  dispatch(SCHEDULED_DELETE_TRANSACTION_INIT());

  const { token } = getState().auth;

  try {
    await deleteScheduled(id, token);

    return dispatch(SCHEDULED_DELETE_TRANSACTION_SUCCESS({ id }));
  } catch (error) {
    return dispatch(
      SCHEDULED_DELETE_TRANSACTION_FAIL({ error: error.response.data.message })
    );
  }
};

export const addScheduledTransaction = (data) => async (dispatch, getState) => {
  dispatch(SCHEDULED_CREATE_TRANSACTION_INIT());

  const { token } = getState().auth;

  try {
    await createScheduled(data, token);

    return dispatch(SCHEDULED_CREATE_TRANSACTION_SUCCESS());
  } catch (error) {
    return dispatch(
      SCHEDULED_CREATE_TRANSACTION_FAIL({ error: error.response.data.message })
    );
  }
};

export const cleanScheduledTransaction = () => SCHEDULED_CLEANUP();
