const users = [
  {
    id: '1',
    name: 'Frank',
    isAdmin: true,
  },
];

const fetchUsers = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(users), 1500);
  });

export default fetchUsers;
