import { createAction } from '@reduxjs/toolkit';

export const USERS_FETCH_USERS_INIT = createAction('USERS_FETCH_USERS_INIT');
export const USERS_FETCH_USERS_SUCCESS = createAction(
  'USERS_FETCH_USERS_SUCCESS'
);
export const USERS_FETCH_USERS_FAIL = createAction('USERS_FETCH_USERS_FAIL');

export const USERS_FETCH_USER_BY_ID_INIT = createAction(
  'USERS_FETCH_USER_BY_ID_INIT'
);
export const USERS_FETCH_USER_BY_ID_SUCCESS = createAction(
  'USERS_FETCH_USER_BY_ID_SUCCESS'
);
export const USERS_FETCH_USER_BY_ID_FAIL = createAction(
  'USERS_FETCH_USER_BY_ID_FAIL'
);

export const USERS_CLEAN_SIGNOUT = createAction('USERS_CLEAN_SIGNOUT');

export const FETCH_USER_LOGGED_INIT = createAction('FETCH_USER_LOGGED_INIT');
export const FETCH_USER_LOGGED_SUCCESS = createAction(
  'FETCH_USER_LOGGED_SUCCESS'
);
export const FETCH_USER_LOGGED_FAIL = createAction('FETCH_USER_LOGGED_FAIL');

export const UPDATE_USER_LOGGED_INIT = createAction('UPDATE_USER_LOGGED_INIT');
export const UPDATE_USER_LOGGED_SUCCESS = createAction(
  'UPDATE_USER_LOGGED_SUCCESS'
);
export const UPDATE_USER_LOGGED_FAIL = createAction('UPDATE_USER_LOGGED_FAIL');

export const DELETE_USER_LOGGED_INIT = createAction('DELETE_USER_LOGGED_INIT');
export const DELETE_USER_LOGGED_SUCCESS = createAction(
  'DELETE_USER_LOGGED_SUCCESS'
);
export const DELETE_USER_LOGGED_FAIL = createAction('DELETE_USER_LOGGED_FAIL');
export const CLEAN_STATE_DELETE_USER = createAction('CLEAN_STATE_DELETE');
