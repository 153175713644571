import { generalMessages } from 'utils/generalMessages';
import { MIN_BALANCE, SIZES } from 'utils/constant';
import { FaBalanceScale, FaWallet } from 'react-icons/fa';

const walletInputs = [
  {
    type: 'text',
    placeholder: generalMessages.walletName.defaultMessage,
    label: generalMessages.walletName.defaultMessage,
    size: SIZES.MEDIUM,
    icon: <FaWallet />,
    fieldName: generalMessages.walletName.fieldName,
  },
  {
    type: 'number',
    placeholder: generalMessages.initialBalance.defaultMessage,
    label: generalMessages.initialBalance.defaultMessage,
    size: SIZES.MEDIUM,
    icon: <FaBalanceScale />,
    fieldName: generalMessages.initialBalance.fieldName,
    min: MIN_BALANCE,
  },
];

export default walletInputs;
