export const scope = 'app.pages.login';

export const messages = {
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Iniciar sesión',
  },
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Bienvenido nuevamente',
  },
  mark: {
    id: `${scope}.mark`,
    defaultMessage: 'Marquemos la diferencia',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Regístrate',
  },
  dontHaveAccount: {
    id: `${scope}.dontHaveAccount`,
    defaultMessage: '¿No tienes una cuenta?',
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: '¿Olvidaste tu contraseña?',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reiniciala',
  },
};
