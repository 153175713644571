import { createAction } from '@reduxjs/toolkit';

export const EXPENSES_GET_ALL_CATEGORIES_INIT = createAction(
  'EXPENSES_GET_ALL_CATEGORIES_INIT'
);
export const EXPENSES_GET_ALL_CATEGORIES_SUCCESS = createAction(
  'EXPENSES_GET_ALL_CATEGORIES_SUCCESS'
);
export const EXPENSES_GET_ALL_CATEGORIES_FAIL = createAction(
  'EXPENSES_GET_ALL_CATEGORIES_FAIL'
);

export const EXPENSES_ADD_EXPENSE_INIT = createAction(
  'EXPENSES_ADD_EXPENSE_INIT'
);
export const EXPENSES_ADD_EXPENSE_SUCCESS = createAction(
  'EXPENSES_ADD_EXPENSE_SUCCESS'
);
export const EXPENSES_ADD_EXPENSE_FAIL = createAction(
  'EXPENSES_GET_ALL_CATEGORIES_FAIL'
);

export const EXPENSES_GET_ALL_TRANSACTIONS_INIT = createAction(
  'EXPENSES_GET_ALL_TRANSACTIONS_INIT'
);
export const EXPENSES_GET_ALL_TRANSACTIONS_SUCCESS = createAction(
  'EXPENSES_GET_ALL_TRANSACTIONS_SUCCESS'
);
export const EXPENSES_GET_ALL_TRANSACTIONS_FAIL = createAction(
  'EXPENSES_GET_ALL_TRANSACTIONS_FAIL'
);

export const EXPENSES_GET_USER_REPORTS_INIT = createAction(
  'EXPENSES_GET_USER_REPORTS_INIT'
);
export const EXPENSES_GET_USER_REPORTS_SUCCESS = createAction(
  'EXPENSES_GET_USER_REPORTS_SUCCESS'
);
export const EXPENSES_GET_USER_REPORTS_FAIL = createAction(
  'EXPENSES_GET_USER_REPORTS_FAIL'
);

export const EXPENSES_GET_MONTHLY_LIMITS_INIT = createAction(
  'EXPENSES_GET_MONTHLY_LIMITS_INIT'
);
export const EXPENSES_GET_MONTHLY_LIMITS_SUCCESS = createAction(
  'EXPENSES_GET_MONTHLY_LIMITS_SUCCESS'
);
export const EXPENSES_GET_MONTHLY_LIMITS_FAIL = createAction(
  'EXPENSES_GET_MONTHLY_LIMITS_FAIL'
);

export const EXPENSES_UPDATE_MONTHLY_LIMITS_INIT = createAction(
  'EXPENSES_UPDATE_MONTHLY_LIMITS_INIT'
);
export const EXPENSES_UPDATE_MONTHLY_LIMITS_SUCCESS = createAction(
  'EXPENSES_UPDATE_MONTHLY_LIMITS_SUCCESS'
);
export const EXPENSES_UPDATE_MONTHLY_LIMITS_FAIL = createAction(
  'EXPENSES_UPDATE_MONTHLY_LIMITS_FAIL'
);

export const EXPENSES_DELETE_MONTHLY_LIMITS_INIT = createAction(
  'EXPENSES_DELETE_MONTHLY_LIMITS_INIT'
);
export const EXPENSES_DELETE_MONTHLY_LIMITS_SUCCESS = createAction(
  'EXPENSES_DELETE_MONTHLY_LIMITS_SUCCESS'
);
export const EXPENSES_DELETE_MONTHLY_LIMITS_FAIL = createAction(
  'EXPENSES_DELETE_MONTHLY_LIMITS_FAIL'
);

export const EXPENSES_CREATE_MONTHLY_LIMITS_INIT = createAction(
  'EXPENSES_CREATE_MONTHLY_LIMITS_INIT'
);
export const EXPENSES_CREATE_MONTHLY_LIMITS_SUCCESS = createAction(
  'EXPENSES_CREATE_MONTHLY_LIMITS_SUCCESS'
);
export const EXPENSES_CREATE_MONTHLY_LIMITS_FAIL = createAction(
  'EXPENSES_CREATE_MONTHLY_LIMITS_FAIL'
);

export const EXPENSES_CLEAN_UP = createAction('EXPENSES_CLEAN_UP');
