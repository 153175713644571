import React from 'react';
import {
  BsClock,
  BsHouse,
  BsPieChartFill,
  BsWallet2,
  BsBank2,
} from 'react-icons/bs';

import Path from 'enums/path.enum';

const navItems = [
  {
    text: 'Inicio',
    icon: <BsHouse />,
    path: Path.Home,
  },
  {
    text: 'Programadas',
    icon: <BsClock />,
    path: Path.Scheduled,
  },
  {
    text: 'Reportes',
    icon: <BsPieChartFill />,
    path: Path.Reports,
  },
  {
    text: 'Billeteras',
    icon: <BsWallet2 />,
    path: Path.Wallets,
  },
  {
    text: 'Extracto',
    icon: <BsBank2 />,
    path: Path.BankStatement,
  },
];

export default navItems;
