import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FaPiggyBank } from 'react-icons/fa';
import { MdAccountCircle, MdLogout, MdEditNotifications } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import Path from 'enums/path.enum';
import { selectAuthData } from 'state/selectors/auth';
import StyledLink from 'components/Link';
import { COLORS } from 'resources/colors';
import Logo from 'images/Logo.svg';

import { signOut } from 'state/actions/auth';

import dispatchAction from 'datadog/utils';
import { LOGOUT_ACTION } from 'datadog/actions/Auth';

import { MEDIA } from 'resources/layout';
import Text from 'components/Text';
import Image from 'components/Image';
import FlexContainer from 'components/FlexContainer';
import { BsGraphUp } from 'react-icons/bs';
import Header from './styles/Header';
import SubItem from '../SubItem';
import ChildrenContainer from '../NavItem/styles/ChildrenContainer';

const StyledWebDiv = styled.div`
  display: none;

  ${MEDIA.tb`
    display: block;
  `}
`;

const { pathname } = window.location;

const NavBar = () => {
  const { isLogged } = useSelector(selectAuthData, shallowEqual);
  const { push } = useHistory();
  const dispatch = useDispatch();

  return (
    <Header isLogged={isLogged}>
      {!isLogged && (
        <FlexContainer
          middle="xs"
          gap={8}
          noWrap
          cursor="pointer"
          padding="0 0 0 17px"
          onClick={() => push(Path.Home)}
        >
          <Image src={Logo} alt="logo" />
          <Text color={COLORS.PrimaryBlue}>
            <ChildrenContainer> Ahorranza</ChildrenContainer>
          </Text>
        </FlexContainer>
      )}
      <nav>
        {isLogged ? (
          <SubItem
            items={[
              {
                path: Path.Profile,
                text: 'Perfil',
                icon: (
                  <MdAccountCircle
                    size={20}
                    color={
                      pathname === Path.Profile ? COLORS.Black : COLORS.GreyText
                    }
                  />
                ),
                onClick: () => push(Path.Profile),
              },
              {
                path: Path.MonthlyLimits,
                text: 'Límites mensuales',
                icon: (
                  <BsGraphUp
                    size={18}
                    color={
                      pathname === Path.MonthlyLimits
                        ? COLORS.Black
                        : COLORS.GreyText
                    }
                  />
                ),
                onClick: () => push(Path.MonthlyLimits),
              },
              {
                path: Path.PersonalInformation,
                text: 'Recomendaciones',
                icon: (
                  <FaPiggyBank
                    size={18}
                    color={
                      pathname === Path.PersonalInformation
                        ? COLORS.Black
                        : COLORS.GreyText
                    }
                  />
                ),
                onClick: () => push(Path.PersonalInformation),
              },
              {
                path: Path.Notifications,
                text: 'Notificaciones',
                icon: (
                  <MdEditNotifications
                    size={20}
                    color={
                      pathname === Path.Notifications
                        ? COLORS.Black
                        : COLORS.GreyText
                    }
                  />
                ),
                onClick: () => push(Path.Notifications),
              },
              {
                text: 'Log Out',
                icon: <MdLogout size={18} color={COLORS.GreyText} />,
                onClick: () => {
                  dispatch(signOut());
                  dispatchAction(LOGOUT_ACTION);
                },
              },
            ]}
          >
            <MdAccountCircle size="38" color={COLORS.AhorranzaBlue} />
          </SubItem>
        ) : (
          <StyledWebDiv>
            <StyledLink to={Path.Login} margin="0 10px 0 0 ">
              Iniciar Sesión
            </StyledLink>
            <StyledLink to={Path.Register}>Registrarse</StyledLink>
          </StyledWebDiv>
        )}
      </nav>
    </Header>
  );
};

export default NavBar;
