export const scope = 'app.general';

export const generalMessages = {
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
    fieldName: 'email',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Nombre completo',
    fieldName: 'name',
  },
  walletName: {
    id: `${scope}.walletName`,
    defaultMessage: 'Nombre billetera',
    fieldName: 'name',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Descripción',
    fieldName: 'description',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Titulo',
    fieldName: 'title',
  },
  initialBalance: {
    id: `${scope}.initialBalance`,
    defaultMessage: 'Saldo Inicial',
    fieldName: 'balance',
  },
  balance: {
    id: `${scope}.balance`,
    defaultMessage: 'Saldo',
    fieldName: 'balance',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Divisa',
    fieldName: 'currency',
  },
  sourceOfFunds: {
    id: `${scope}.sourceOfFunds`,
    defaultMessage: 'Proveniencia de fondos',
    fieldName: 'walletTypeId',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Teléfono (opcional)',
    fieldName: 'phone',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Contraseña',
    fieldName: 'password',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirmar contraseña',
    fieldName: 'confirmPassword',
  },
  wallet: {
    id: `${scope}.wallet`,
    defaultMessage: 'Billetera',
    fieldName: 'wallet',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Categoría',
    fieldName: 'category',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Fecha',
    fieldName: 'date',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Monto',
    fieldName: 'amount',
  },
  lapse: {
    id: `${scope}.lapse`,
    defaultMessage: 'Repetición del gasto',
    fieldName: 'repeat',
  },
  required: {
    id: `${scope}.required`,
    defaultMessage: 'Este campo es obligatorio',
  },
  greaterThan: {
    id: `${scope}.greaterThan`,
    defaultMessage: 'Este campo tiene que ser mayor a ',
  },
  invalidFormat: {
    id: `${scope}.invalidFormat`,
    defaultMessage: 'Este campo tiene un formato invalido',
  },
  passwordMatch: {
    id: `${scope}.passwordMatch`,
    defaultMessage: 'Las contraseñas deben coincidir',
  },
  minLength: {
    id: `${scope}.minLength`,
    defaultMessage: 'La contraseña debe tener al menos 8 caracteres',
  },
  oneLowerCase: {
    id: `${scope}.oneLowerCase`,
    defaultMessage: 'La contraseña debe tener al menos 1 letra minúscula',
  },
  oneUpperCase: {
    id: `${scope}.oneUpperCase`,
    defaultMessage: 'La contraseña debe tener al menos 1 letra mayúscula',
  },
  termsRequired: {
    id: `${scope}.termsRequired`,
    defaultMessage: 'Aceptar los términos y condiciones es obligatorio',
  },
  credentialErrors: {
    id: `${scope}.credentialErrors`,
    defaultMessage: 'Credenciales incorrectas, verifique los datos provistos.',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Estado',
  },
};
