import fetchUsersService from 'services/users/fetchUsers';
import fetchUserByIdService from 'services/users/fetchUserById';
import getUserLogged from 'services/api/getUser';
import updateUserLogged from 'services/api/updateUser';
import deleteUserLogged from 'services/api/deleteUser';

import {
  USERS_FETCH_USERS_INIT,
  USERS_FETCH_USERS_SUCCESS,
  USERS_FETCH_USERS_FAIL,
  USERS_FETCH_USER_BY_ID_INIT,
  USERS_FETCH_USER_BY_ID_SUCCESS,
  USERS_FETCH_USER_BY_ID_FAIL,
  USERS_CLEAN_SIGNOUT,
  FETCH_USER_LOGGED_INIT,
  FETCH_USER_LOGGED_SUCCESS,
  FETCH_USER_LOGGED_FAIL,
  UPDATE_USER_LOGGED_INIT,
  UPDATE_USER_LOGGED_SUCCESS,
  UPDATE_USER_LOGGED_FAIL,
  DELETE_USER_LOGGED_INIT,
  DELETE_USER_LOGGED_SUCCESS,
  DELETE_USER_LOGGED_FAIL,
} from 'state/actionCreators/users';

export const fetchUsers = () => async (dispatch) => {
  dispatch(USERS_FETCH_USERS_INIT());

  try {
    const users = await fetchUsersService();

    return dispatch(USERS_FETCH_USERS_SUCCESS({ users }));
  } catch (error) {
    return dispatch(
      USERS_FETCH_USERS_FAIL({ error: error.response.data.message })
    );
  }
};

export const fetchUserById = (userId) => async (dispatch) => {
  dispatch(USERS_FETCH_USER_BY_ID_INIT());

  try {
    const user = await fetchUserByIdService(userId);

    return dispatch(USERS_FETCH_USER_BY_ID_SUCCESS({ user }));
  } catch (error) {
    return dispatch(
      USERS_FETCH_USER_BY_ID_FAIL({ error: error.response.data.message })
    );
  }
};

export const cleanUserSignOut = () => USERS_CLEAN_SIGNOUT();

export const fetchUserLogged = (token) => async (dispatch) => {
  dispatch(FETCH_USER_LOGGED_INIT());

  try {
    const { data } = await getUserLogged(token);

    return dispatch(FETCH_USER_LOGGED_SUCCESS({ data }));
  } catch (error) {
    return dispatch(
      FETCH_USER_LOGGED_FAIL({ error: error.response.data.message })
    );
  }
};

export const updateUser = (user, token) => async (dispatch) => {
  dispatch(UPDATE_USER_LOGGED_INIT());

  try {
    const { data } = await updateUserLogged(user, token);
    return dispatch(UPDATE_USER_LOGGED_SUCCESS({ data }));
  } catch (error) {
    return dispatch(
      UPDATE_USER_LOGGED_FAIL({ error: error.response.data.message })
    );
  }
};

export const deleteUser = (token) => async (dispatch) => {
  dispatch(DELETE_USER_LOGGED_INIT());

  try {
    await deleteUserLogged(token);
    return dispatch(DELETE_USER_LOGGED_SUCCESS());
  } catch (error) {
    return dispatch(
      DELETE_USER_LOGGED_FAIL({ error: error.response.data.message })
    );
  }
};
