const getAllCategories = (categories) => {
  if (!categories) return [];

  return categories.flatMap(({ subCategories, ...category }) => [
    category,
    ...subCategories,
  ]);
};

export default getAllCategories;
