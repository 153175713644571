const badges = [
  {
    label: 'Dolar',
    value: 'USD',
  },
  {
    label: 'Pesos uruguayos',
    value: 'UYU',
  },
  {
    label: 'Pesos argentinos',
    value: 'ARS',
  },
  {
    label: 'Reales',
    value: 'BRL',
  },
  {
    label: 'Euro',
    value: 'EUR',
  },
];

export default badges;
