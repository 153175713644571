import styled from 'styled-components';
import { MEDIA } from 'resources/layout';

const FileDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;

  ${MEDIA.md`
    flex-direction: row;
    align-items: center;
  `}
`;

export default FileDescriptionContainer;
