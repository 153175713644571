import styled from 'styled-components';

const ChildrenContainer = styled.div`
  transition: opacity 0.5s ease-in-out;
  display: flex;
  align-items: center;
  ${({ isSmall }) => isSmall && `display: none;`}
`;

export default ChildrenContainer;
