/* eslint-disable import/prefer-default-export */
import Santander from 'images/santander.png';
import Itau from 'images/itau.png';
import Brou from 'images/brou.png';

export const banks = [
  {
    icon: Santander,
    alt: 'Banco Santander',
    value: 'santander',
  },
  {
    icon: Itau,
    alt: 'Banco Itaú',
    value: 'itau',
  },
  {
    icon: Brou,
    alt: 'Banco República',
    value: 'brou',
  },
];
