import styled from 'styled-components';

const Wrapper = styled.div`
  opacity: 0;
  top: 0;
  padding-top: 98px;
  visibility: hidden;
  overflow: visible;
  position: absolute;
  ${(isRightSide) => (isRightSide ? `right: 0;` : `left: 0;`)}
`;

export default Wrapper;
