import { createAction } from '@reduxjs/toolkit';

export const AUTH_LOGIN_INIT = createAction('AUTH_LOGIN_INIT');
export const AUTH_LOGIN_SUCCESS = createAction('AUTH_LOGIN_SUCCESS');
export const AUTH_LOGIN_FAIL = createAction('AUTH_LOGIN_FAIL');

export const AUTH_SIGNOUT_INIT = createAction('AUTH_SIGNOUT_INIT');
export const AUTH_SIGNOUT_SUCCESS = createAction('AUTH_SIGNOUT_SUCCESS');
export const AUTH_SIGNOUT_FAIL = createAction('AUTH_SIGNOUT_FAIL');

export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');
export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');
export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');

export const SIGN_UP_INIT = createAction('SIGN_UP_INIT');
export const SIGN_UP_SUCCESS = createAction('SIGN_UP_SUCCESS');
export const SIGN_UP_FAIL = createAction('SIGN_UP_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction(
  'AUTH_RESET_PASSWORD_INIT'
);
export const AUTH_RESET_PASSWORD_SUCCESS = createAction(
  'AUTH_RESET_PASSWORD_SUCCESS'
);
export const AUTH_RESET_PASSWORD_FAIL = createAction(
  'AUTH_RESET_PASSWORD_FAIL'
);

export const SAVE_TOKEN = createAction('SAVE_TOKEN');

export const CLEAN_STATE = createAction('CLEAN_STATE');
export const CLEAN_STATE_DELETE = createAction('CLEAN_STATE_DELETE');

export const AUTH_CLEAN_STATE = createAction('AUTH_CLEAN_STATE');
