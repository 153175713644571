import ForgotPasswordForm from 'components/Common/Forms/ForgotPasswordForm';
import Path from 'enums/path.enum';
import Container from 'pages/Login/styles/Container';
import { messages } from './messages';

const ResetPassword = () => (
  <Container>
    <ForgotPasswordForm
      title={messages.resetPassword.defaultMessage}
      returnPath={Path.Profile}
    />
  </Container>
);

export default ResetPassword;
