import styled from 'styled-components';

const Description = styled.p`
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
  width: 200px;
`;

export default Description;
