const getNotificationsBody = (values) => {
  const body = [
    {
      section: 'Límites por categoría',
      notifications: [
        {
          section: 'Límites por categoría',
          title: 'Al llegar al 50%',
          inactiveReason: 'Aún no configuraste ningún límite',
          route: 'limits',
          value: values['Al llegar al 50%'],
        },
        {
          section: 'Límites por categoría',
          title: 'Al llegar al 100%',
          inactiveReason: 'Aún no configuraste ningún límite',
          route: 'limits',
          value: values['Al llegar al 100%'],
        },
      ],
    },
    {
      section: 'Gastos',
      notifications: [
        {
          section: 'Gastos',
          title: 'Email con reporte mensual',
          inactiveReason: null,
          route: null,
          value: values['Email con reporte mensual'],
        },
        {
          section: 'Gastos',
          title: 'Oportunidades de ahorro al hacer una compra',
          inactiveReason: null,
          route: null,
          value: values['Oportunidades de ahorro al hacer una compra'],
        },
      ],
    },
  ];

  return body;
};

export default getNotificationsBody;
