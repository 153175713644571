import React from 'react';
import {
  AiFillApple,
  AiFillAndroid,
  AiFillQuestionCircle,
} from 'react-icons/ai';

import Path from 'enums/path.enum';

import FooterContainer from './styles/FooterContainer';
import FooterWrapper from './styles/FooterWrapper';
import SocialMedia from './styles/SocialMedia';
import SocialMediaWrapper from './styles/SocialMediaWrapper';
import SocialLogo from './styles/SocialLogo';
import WebsiteRights from './styles/WebsiteRights';
import SocialIcons from './styles/SocialIcons';
import SocialIconLink from './styles/SocialIconLink';

const Footer = () => (
  <FooterContainer>
    <FooterWrapper>
      <SocialMedia>
        <SocialMediaWrapper>
          <SocialLogo to={Path.Home}>Ahorranza</SocialLogo>
          <WebsiteRights>
            Ahorranza © {new Date().getFullYear()} Todos los derechos
            reservados.
          </WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href="https://testflight.apple.com/join/jZRx0qcO"
              target="_blank"
              aria-label="Apple"
            >
              <AiFillApple />
            </SocialIconLink>
            <SocialIconLink
              href="https://appdistribution.firebase.dev/i/317dc92b5c244bec"
              target="_blank"
              aria-label="Android"
            >
              <AiFillAndroid />
            </SocialIconLink>
            <SocialIconLink
              href="https://docs.google.com/forms/d/e/1FAIpQLSfcxl5ZYVi7FlP33e-Wz8mnJGpTVu6M9s4r8bqXcDQVbw83wg/viewform"
              target="_blank"
              aria-label="Form"
            >
              <AiFillQuestionCircle />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrapper>
      </SocialMedia>
    </FooterWrapper>
  </FooterContainer>
);

export default Footer;
